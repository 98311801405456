<template>
  <div
    class="text-left ml-3 mb-12"
    v-if="userSchedule && userSchedule.length > 0"
  >
    <div class="p-0">
      <p class="title">Mój harmonogram tygodnia</p>
      <div id="user-schedules">
        <schedule-item
          :scheduleDay="scheduleDay"
          :isAdminScheduleItem="false"
          v-for="scheduleDay in userSchedule"
          :key="scheduleDay.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleItem from "./ScheduleItem.vue";
export default {
  props: ["type"],
  name: "UserSchedule",
  components: { ScheduleItem },
  async mounted() {
    const token = window.localStorage.getItem("userToken");
    if (token) {
      await this.$store.dispatch("users/fetchUserSchedule", {
        type: this.type || "STANDARD",
      });
    } else {
      await this.$store.dispatch("users/resetSchedules");
    }
  },
  methods: {},

  computed: {
    userSchedule() {
      return this.$store.getters["users/getUserSchedule"];
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 500;
}
#user-schedules {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
@media (max-width: 825px) {
  #user-schedules {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-right: 0.75rem;
  }
}
</style>