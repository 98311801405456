<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline">{{
              `${updated ? "Edytuj" : "Dodaj"} kurs do warsztatów`
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    type="text"
                    rules="required"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="2048"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="link"
                    label="Link YouTube"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                    rules="link"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="timePackageId"
                    :items="workshops"
                    item-value="id"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Warsztaty"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!copiedTimePackageItemId">
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-file-input
                    v-model="file"
                    label="Zdjęcie kursu"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addPackageItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "AddWorkshopsItemModal",
  props: ["isVisible", "packageItemData"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },
  mounted() {
    this.$store.dispatch("timePackages/fetchAllTimePackages", {
      type: "WORKSHOPS",
    });
  },

  data: () => ({
    packageItemId: null,
    input: "",
    discountError: false,
    updated: false,
    name: "",
    description: "",
    link: "",
    file: null,
    timePackageId: "",
    imagePath: "",
    copiedTimePackageItemId: "",
  }),
  updated() {
    if (this.packageItemData) {
      const {
        id,
        link,
        name,
        description,
        imagePath,
        time_package,
        copy,
        copiedTimePackageItemId,
      } = this.packageItemData;
      this.packageItemId = id;
      this.updated = true;
      this.name = name;
      this.link = link;
      this.description = description;
      this.imagePath = imagePath;
      this.timePackageId = time_package.id;
      if (copy) {
        this.updated = null;
        this.copiedTimePackageItemId = copiedTimePackageItemId;
      }
    }
  },

  methods: {
    closeModal() {
      this.updated = false;
      this.$emit("closeModal");
      (this.packageItemId = null),
        (this.input = ""),
        (this.discountError = false),
        (this.updated = false),
        (this.name = ""),
        (this.description = ""),
        (this.link = ""),
        (this.file = null),
        (this.timePackageId = ""),
        (this.imagePath = ""),
        (this.copiedTimePackageItemId = "");
    },
    async addPackageItem() {
      let object = {
        name: this.name,
        description: this.description,
        link: this.link,
        file: this.file,
        movieCategoryId: this.movieCategoryId,
        timePackageId: this.timePackageId,
        imagePath: this.imagePath,
        copiedTimePackageItemId: this.copiedTimePackageItemId,
      };

      if (this.updated) {
        await this.$store
          .dispatch("timePackages/updateTimePackageItem", {
            packageItemId: this.packageItemId,
            data: object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      } else {
        await this.$store
          .dispatch("timePackages/addTimePackageItem", { data: object })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      }
    },
  },

  computed: {
    workshops() {
      return this.$store.getters["timePackages/getAllTimePackages"];
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
