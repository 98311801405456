<template>
  <div
    v-if="videoId"
    :id="resourceId ? `yt-player-${resourceId}` : videoId"
    class="yt-player"
    data-plyr-provider="youtube"
    :data-plyr-embed-id="videoId"
  />
</template>

<script>
import Plyr from "plyr";
export default {
  props: {
    videoId: String,
    resourceId: String,
  },
  mounted() {
    if (this.resourceId) {
      this.player = new Plyr(`#yt-player-${this.resourceId}`, {});
    } else {
      this.player = new Plyr(".yt-player", {});
    }
  },
  data() {
    return {
      player: null,
    };
  },
};
</script>