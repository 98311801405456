<template>
  <div
    class="subscription-container"
    v-if="selectedPackage && selectedPackage.id"
  >
    <div class="left-side w-50">
      <PurchaseSumUp
        :isCoursesTicket="true"
        :selectedPackage="selectedPackage"
        :selectedOrder="selectedPackage"
        :activeSubscription="null"
      >
      </PurchaseSumUp>
    </div>
    <div class="right-side w-50">
      <PaymentForm
        :profile="profile"
        :selectedOrder="selectedPackage"
        :isCoursesTicket="true"
      >
      </PaymentForm>
    </div>
  </div>

  <v-container class="container mt-10" fluid v-else>
    <v-row justify="center" align="baseline">
      <v-col cols="12" sm="6" md="4" lg="4" xl="3">
        <v-card shaped elevation="5" rounded>
          <v-toolbar
            class="rounded-t"
            style="background-color: #cc6666 !important"
            tile
          >
            <v-toolbar-title>Dokonaj płatności</v-toolbar-title>
          </v-toolbar>
          <v-container class="py-10 px-10 text-center">
            <div>
              <p>
                Zajęcia obecnie są nieaktywne. Spróbuj ponownie bliżej ich
                rozpoczęcia.
              </p>

              <a
                href="https://dagajoga.pl/index.php/zajecia-na-zywo/"
                class="ok-button btn-gradient"
                >OK</a
              >
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PaymentForm from "../components/forms/PaymentForm.vue";
import PurchaseSumUp from "../components/PurchaseSumUp.vue";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "CoursesTicketsPayment",

  components: {
    PaymentForm,
    PurchaseSumUp,
  },

  data: () => ({}),

  async created() {
    this.resetData();
    this.$store.dispatch("coursesTickets/fetchPackage", this.$route.params.id);
  },

  async mounted() {
    await this.$store.dispatch("auth/fetchUserProfile");
    await this.$store.dispatch(`auth/checkUserActiveSubscriptions`);
  },

  destroyed() {
    this.reset();
  },

  methods: {
    resetData() {
      this.$store.commit("payment/SET_INIT_VALUES");
      this.loading = false;
      this.email = "";
      this.email_confirmation = "";
      this.discountCode = "";
      this.newsletter = true;
      this.giftRecipientEmail = "";
      (this.name = ""),
        (this.companyName = ""),
        (this.companyNIP = ""),
        (this.address = "");
    },

    reset() {
      this.resetData();
      if (this.$refs.obs) {
        this.$refs.obs.reset();
      }
    },
    onSubmit() {
      if (
        (this.companyName && this.companyNIP && this.invoiceForCompany) ||
        (this.name && !this.invoiceForCompany)
      ) {
        this.loading = true;
        let object = {
          email: this.email,
          discountCode: this.discountCode.toLowerCase().trim(),
          name: this.name,
          phone: this.phone,
          packageId: this.$route.params.id,
          newsletter: this.newsletter,
          confirmEmail: this.email_confirmation,
          companyName: this.companyName,
          nip: this.companyNIP,
          address: this.address,
          invoiceForCompany: this.invoiceForCompany,
        };
        this.$store.dispatch("coursesTickets/createOrder", object).then(() => {
          this.loading = false;
          this.$refs.obs.reset();
        });
      } else {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              'Musisz wypełnić przynajmniej jedno z następujących: "Imię i Nazwisko" bądź "Nazwa firmy" i "Numer NIP"',
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        if (this.$refs.obs) {
          this.$refs.obs.reset();
        }
      }
    },
  },

  computed: {
    profile() {
      return this.$store.getters["auth/getProfile"];
    },
    transaction: function() {
      return this.$store.getters["payment/getTransInfo"];
    },
    selectedPackage() {
      return this.$store.getters["coursesTickets/getPackage"];
    },
  },
};
</script>

<style scoped>
.container {
}
.subscription-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  display: flex;
}
.left-side,
.right-side {
  width: 50%;
  padding: 3rem;
}
.right-side {
  background: rgb(102, 102, 102);
  background: linear-gradient(
    90deg,
    rgba(102, 102, 102, 1) 19%,
    rgba(69, 78, 91, 1) 83%
  );
}
@media screen and (max-width: 800px) {
  .subscription-container {
    flex-direction: column;
  }
  .left-side,
  .right-side {
    width: 100%;
  }
}
.ok-button {
  width: 150px;
  height: 2rem;
  text-decoration: none;
  color: #fff;
  display: block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ok-button:hover {
  transform: none;
}
</style>
