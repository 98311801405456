<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Kursy w subskrypcjach miesięcznych
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mt-1 mr-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak kursów w subskrypcjach miesięcznych
        </v-alert>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editPackage(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              v-on:click="(isDeleteModalVisible = true), (currentItem = item)"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.photo`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <img
              class="item-image"
              :id="item.imagePath"
              @click="editPackage(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
              alt="
              joga zdrowy,
              filmy jogi,
              techniki oddechowe,
              praktyka wzmacniająca,
              joga online,
              praktyka dla początkujących,
              szkoła online,
              prana vyavama,
              joga wzmacniająca,
              joga video,
              ćwiczenia online,
              uważna praktyka,
              anatomia jogi
              "
              loading="lazy"
            />
          </template>
        </v-tooltip>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="htmlToFormattedText(item.name)"
            />
          </template>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="htmlToFormattedText(item.description)"
            />
          </template>
        </v-tooltip>
      </template>
    </v-data-table>
    <AddTimePackageItemModal
      :isVisible="isModalVisible"
      :packageItemData="packageItemData"
      @closeModal="closeModal()"
      @updated="timePackageUpdated()"
      :type="'FOR-BEGINNERS'"
    />
    <BeforeDeleteModal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirm(currentItem.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddTimePackageItemModal from "../components/modals/AddTimePackageItemModal";
import ManagementNav from "../components/ManagementNav.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import { SERVER_URL } from "../axios";

export default {
  name: "SubscriptionsForBeginnersItems",
  components: {
    AddTimePackageItemModal,
    ManagementNav,
    BeforeDeleteModal,
  },

  async mounted() {
    await this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
      includeItemsWithSubcategory: true,
      forBeginners: true,
    });
    this.loadImages();
  },
  data: () => ({
    headers: [
      { text: "Nazwa kursu", value: "name" },
      { text: "Opis", value: "description" },
      { text: "Słowa kluczone (oddzielone przecinkiem)", value: "keywords" },
      { text: "Kategoria", value: "categoryName" },
      { text: "Podkategoria", value: "movieSubcategory.name" },
      { text: "Podkategoria nr 2", value: "movieSubcategoryL2.name" },
      { text: "Link YouTube", value: "link" },
      { text: "Link Demo YouTube", value: "demoLink" },
      { text: "Zdjęcie", value: "photo" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isDeleteModalVisible: false,
    packageItemData: null,
    currentItem: null,
  }),
  computed: {
    items() {
      return this.$store.getters["timePackages/getTimePackageItems"];
    },
  },
  methods: {
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    timePackageUpdated() {
      this.loadImages();
    },
    async loadImages() {
      this.items.forEach(async (item) => {
        this.loadImage(item);
      });
    },

    async loadImage(timePackageItem) {
      const element = document.getElementById(timePackageItem.imagePath);
      if (element) {
        if (timePackageItem.imagePath) {
          const extension = timePackageItem.imagePath?.split(".")[1];
          element.src = `${SERVER_URL}${
            timePackageItem.id
          }.${extension}?${new Date().getTime()}`;
        }
      }
    },

    editPackage(data) {
      this.packageItemData = data;
    },
    deletePackage(id) {
      this.$store.dispatch("timePackages/deleteTimePackageItem", {
        id,
        type: "FOR-BEGINNERS",
      });
    },

    confirm(id) {
      this.deletePackage(id);
    },

    closeModal() {
      this.isModalVisible = false;
      this.packageItemData = null;
      this.currentItem = null;
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageItemData = null;
      this.currentItem = null;
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
}
</style>
