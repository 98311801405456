import Vue from "vue";
import Vuetify from "vuetify/lib";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import pl from "vuetify/lib/locale/pl";

Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(fas);
library.add(faTelegram);

Vue.use(Vuetify, { iconfont: "faSvg" });

export default new Vuetify({
  icons: {
    // iconfont: 'faSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    checked: "fas fa-check-square",
    unchecked: "far fa-square",
  },
  lang: {
    locales: { pl },
    current: "pl",
  },
});
