<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Płatności
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon icon="search" slot="prepend-inner" class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak płatności
        </v-alert>
      </template>

      <template v-slot:[`item.timePackageExpirationTime`]="{ item }">
        <p
          :id="item.id"
          v-html="formatExpiryDate(item.timePackageExpirationTime)"
        />
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        <p :id="item.id" v-html="formatExpiryDate(item.createdAt)" />
      </template>

      <template v-slot:[`item.invoiceUrl`]="{ item }">
        <a
          :id="item.id"
          v-html="downloadText(item.invoiceUrl)"
          :href="getLink(item.invoiceUrl)"
          style="cursor: pointer; text-decoration: underline;"
          target="_blank"
          rel="noopener noreferrer"
        />
      </template>

      <template v-slot:[`item.invoiceUrl`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <a
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="downloadText(item.invoiceUrl)"
              :href="getLink(item.invoiceUrl)"
              style="cursor: pointer; text-decoration: underline;"
              target="_blank"
              rel="noopener noreferrer"
            />
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`item.packageItems`]="{ item }">
        <div v-for="link in item.packageItems" :key="link.id">
          {{ link.link }}
        </div>
      </template>

      <template v-slot:[`item.isActiveTimePackage`]="{ item }">
        <p :id="item.id" v-html="isActive(item.isActiveTimePackage)" />
      </template>

      <template v-slot:[`item.productType`]="{ item }">
        <p :id="item.id" v-html="getProductName(item)" />
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="getShortText(item.description)"
            />
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="getShortText(item.name)"
            />
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="formatDate(item.createdAt)"
            />
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="htmlToFormattedText(item.description)"
            />
          </template>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ManagementNav from "../components/ManagementNav.vue";
import moment from "moment";
export default {
  name: "BoughtProductsManagement",
  components: {
    ManagementNav,
  },
  created() {
    this.$store.dispatch("boughtProducts/fetchAll");
  },
  data: () => ({
    headers: [
      { text: "Produkt", value: "productType" },
      // { text: "Nazwa", value: "name" },
      // { text: "Opis", value: "description" },
      { text: "Email użytkownika", value: "userEmail" },
      // { text: "Aktywna subskrypcja", value: "isActiveTimePackage" },
      { text: "Faktura", value: "invoiceUrl" },
      { text: "Data utworzenia", value: "createdAt", width: "11%" },
      // { text: "Data wygaśnięcia", value: "timePackageExpirationTime", width: '11%' },
      // { text: "Liczba dni", value: "numberOfDays" },
      // { text: "Email odbiorcy vouchera", value: "giftRecipientEmail" },
      { text: "ZoomID", value: "zoomId" },
      { text: "Przedmioty", value: "packageItems" },
      { text: "Data zajęć", value: "date" },
      { text: "Cena", value: "price" },
    ],
    search: "",
    isModalVisible: false,
    giftCard: null,
    currentItem: null,
    isDeleteModalVisible: false,
  }),
  computed: {
    items() {
      return this.$store.getters["boughtProducts/getBoughtProducts"];
    },
  },
  methods: {
    formatDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        return formattedDate;
      }
      return null;
    },
    htmlToFormattedText(text) {
      const regex = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },

    getProductName(item) {
      if (item.productType === "TIME_PACKAGE") return "Subskrypcja";
      if (item.productType === "GIFT_CARD") return "Voucher";
      if (item.productType === "PACKAGE" && item.zoomId)
        return "Zajęcia na żywo ZOOM";
      if (item.productType === "PACKAGE") return "Pakiet";
      if (item.productType === "WORKSHOPS") return "Warsztaty";
    },

    isActive(isActiveTimePackage) {
      if (isActiveTimePackage != null) {
        const response = isActiveTimePackage ? "Tak" : "Nie";
        return response;
      }
      return null;
    },

    formatExpiryDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-DD-MM");
        return formattedDate;
      }
      return null;
    },

    getLink(invoiceUrl) {
      if (invoiceUrl) return invoiceUrl;
      return null;
    },

    downloadText(invoiceUrl) {
      if (invoiceUrl) return "Pobierz";
      return null;
    },

    getShortText(text) {
      if (text && text.length > 20) return text.substring(0, 19) + "...";
      return text;
    },
  },
};
</script>

<style></style>
