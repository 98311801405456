<template>
  <p class="product-price" :style="`${margin ? `margin: ${margin}` : ''}`">
    <span v-if="price && price !== '-'">
      <span
        v-if="price"
        class="number"
        :data-number="`${
          price.split('.')[1]
            ? price.split('.')[1].length < 2
              ? `0${price.split('.')[1]}`
              : price.split('.')[1].substring(0, 2)
            : '00'
        }`"
        >{{ `${price.split(".")[0]}${price.split(".")[1] ? "," : ""}` }}</span
      >
      <span class="currency">zł</span>
    </span>
    <span v-else>Nie wybrano</span>
  </p>
</template>

<script>
export default {
  props: ["price", "margin"],
  mounted() {},
  data() {
    return {
      text: "",
      timeoutId: "",
    };
  },
};
</script>

<style scoped>
.product-price {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 18px;
}
.number {
  position: relative;
}

.number::after {
  content: attr(
    data-number
  ); /* pobieramy wartość liczby z atrybutu data-number */
  position: absolute;
  top: -0.1em;
  right: -1.2em;
  font-size: 0.6em;
  font-weight: bold;
}
.currency {
  margin-left: 1.1rem;
  font-size: 0.8em;
}
</style>
