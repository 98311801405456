<template>
  <v-row justify="center">
    <v-dialog max-width="90%" :value="isVisible" @keydown.esc="closeModal">
      <div class="main-container">
        <div class="content">
          <span class="images" v-for="image in images" :key="image.id">
            <img :src="image.url" class="image" />
            <font-awesome-icon
              @click="
                (selectedImage = image), (isConfirmDeleteModalVisible = true)
              "
              class="removeImageIcon"
              icon="times"
              color="red"
            />
          </span>
        </div>
        <v-row style="width: 50%">
          <v-col cols="12" sm="12" style="padding-top: 0; padding-bottom: 0">
            <v-file-input
              multiple
              v-model="files"
              label="Dodaj zdjęcia"
            ></v-file-input>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            style="padding-top: 0; padding-bottom: 0"
            v-if="files"
          >
            <v-btn color="success" width="100%" @click="saveImages"
              >Zapisz</v-btn
            >
          </v-col>
        </v-row>
        <font-awesome-icon @click="closeModal" class="closeIcon" icon="times" />
        <before-delete-modal
          :isVisible="isConfirmDeleteModalVisible"
          @confirmDeletion="removeImage(selectedImage)"
          @closeModal="isConfirmDeleteModalVisible = false"
        />
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import BeforeDeleteModal from "./BeforeDeleteModal.vue";
export default {
  name: "ImagesPreviewModal",
  props: ["images", "isVisible"],
  components: { BeforeDeleteModal },

  mounted() {
    this.files = null;
  },
  data: () => ({
    files: null,
    isConfirmDeleteModalVisible: false,
    selectedImage: null,
  }),
  methods: {
    saveImages() {
      this.$emit("saveImages", this.files);
      this.files = null;
    },
    removeImage(image) {
      this.$emit("removeImage", image.id);
    },
    closeModal() {
      this.email = "";
      this.$emit("closeModal");
    },
  },
};
</script>
<style type="scss" scoped>
.main-container {
  background: #fff;
  padding: 5rem;
  position: relative;
  width: 100%;
}
.content {
  height: fit-content;
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  position: relative;
}
.content {
  position: relative;
}
.images {
  max-height: 200px;
  position: relative;
}
.images img {
  object-fit: cover;
  max-height: 200px;
}
.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 25px;
}
.removeImageIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 25px;
}
</style>
