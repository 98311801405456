import axios from "../axios";
import errorHandler from "../errorHandler";

const packages = {
  namespaced: true,

  state: () => ({
    packages: [],
    package: null,
  }),

  mutations: {
    SET_PACKAGES(state, packages) {
      state.packages = packages;
    },
    SET_PACKAGE(state, load) {
      state.package = load;
    },
  },

  actions: {
    async updatePackage({ dispatch }, object) {
      try {
        await axios.put(`management/package/${object.packageId}`, object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Edycja przebiegła pomyślnie!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchPackages");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async updatePackageStatus({ dispatch }, object) {
      try {
        await axios.put(`management/package/${object.packageId}/status`, {
          active: object.active,
        });
        await dispatch("fetchPackages");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addPackage({ dispatch }, object) {
      try {
        const response = await axios.post("management/package", object.data);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchPackages");
        return response.data
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchPackages({ commit }) {
      try {
        const response = await axios.get("management/package");
        commit("SET_PACKAGES", response.data);
      } catch (err) {
        console.log(err);
      }
    },
    async fetchPackage({ commit }, id) {
      try {
        //const response = await axios.get(`management/package/${id}`);
        const response = await axios.get(`management/package/${id}/price`);
        commit("SET_PACKAGE", response.data);
      } catch (err) {
        commit("SET_PACKAGE", null);
        console.log(err);
      }
    },
    async deletePackage({ dispatch }, id) {
      try {
        await axios.delete(`management/package/${id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        await dispatch("fetchPackages");
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    // async updatePackage({ dispatch }, data) {
    //   let object = {};
    //   try {
    //     await axios.put(`management/package/${data.id}`, object);
    //     dispatch(
    //       "snackbar/setSnackbar",
    //       {
    //         color: "success",
    //         icon: "check",
    //         message: "Pomyślnie edytowano!",
    //       },
    //       { root: true }
    //     );
    //     dispatch("snackbar/toggleSnackbar", true, { root: true });
    //     dispatch("fetchVehicles");
    //   } catch (error) {
    //     dispatch(
    //       "snackbar/setSnackbar",
    //       {
    //         color: "red",
    //         icon: "exclamation-triangle",
    //         message: errorHandler(
    //           error.response.data.message,
    //           error.response.data.code
    //         ),
    //       },
    //       { root: true }
    //     );
    //     dispatch("snackbar/toggleSnackbar", true, { root: true });
    //   }
    // },
  },

  getters: {
    getPackages: (state) => state.packages,
    getPackage: (state) => state.package,
  },
};
export default packages;
