<template>
  <div v-if="selectedSubCategory !== null" class="container">
    <return-btn
      :path="
        `kategoria/${selectedSubCategory.movieSubCategoryId}${
          isExtendedSubscription ? '?rozszerzona=1' : ''
        }`
      "
      blackColor
    ></return-btn>
    <div class="content">
      <img id="subcategory-view-image" loading="lazy" />
      <div class="description">
        <h1>{{ selectedSubCategory.name }}</h1>
        <p
          class="description-text"
          v-html="selectedSubCategory.description"
        ></p>
      </div>
    </div>
    <div class="courses">
      <Course
        v-for="timePackageItem in subcategoryItems"
        :timePackageItem="timePackageItem"
        :key="timePackageItem.id"
        :id="`course_${timePackageItem.id}`"
        :selectedSubCategoryId="selectedSubCategory.id"
        :isExtendedSubscription="isExtendedSubscription"
      >
      </Course>
    </div>
  </div>
</template>

<script>
import router from "../router";
import Course from "../components/Course.vue";
import ReturnBtn from "../components/ReturnBtn.vue";
import { SERVER_URL } from "../axios";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "SubcategoryL2View",
  data: () => ({ fetched: true, img: "", isExtendedSubscription: false }),
  components: { Course, ReturnBtn },
  props: {
    path: String,
  },

  async mounted() {
    const id = router.currentRoute.params.id;
    if (this.$route.query.rozszerzona && this.$route.query.rozszerzona == "1") {
      this.isExtendedSubscription = true;
    }
    await this.$store.dispatch(
      "movieSubCategories/getSubCategoryL2Details",
      id
    );
    if (this.selectedSubCategory && this.selectedSubCategory.imagePath) {
      this.loadImage(this.selectedSubCategory);
    } else {
      this.img = await this.$store.dispatch(
        "movieSubCategories/getSubCategoryL2ImageSrc",
        { subCategoryL2Id: router.currentRoute.params.id }
      );
    }
    if (!this.isExtendedSubscription) {
      await this.$store.dispatch(
        "timePackages/fetchAllTimePackageItemsBySubCategoryL2Id",
        id
      );
    } else {
      await this.$store.dispatch(
        "timePackages/fetchAllTimePackageItemsExtendedBySubCategoryL2Id",
        id
      );
    }
  },

  computed: {
    token() {
      return this.$store.getters["auth/getToken"];
    },
    subcategoryItems() {
      return this.$store.getters[
        "timePackages/getSubCategoryL2TimePackageItems"
      ];
    },
    selectedSubCategory() {
      return this.$store.getters[
        "movieSubCategories/getSelectedMovieSubCategoryL2"
      ];
    },
  },
  methods: {
    async loadImage(subCategory) {
      const element = document.getElementById("subcategory-view-image");
      if (element) {
        if (subCategory.imagePath) {
          const extension = subCategory.imagePath.split(".")[1];
          element.src = `${SERVER_URL}${
            subCategory.id
          }.${extension}?${new Date().getTime()}`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
}
.content {
  color: #000;
  margin: 5rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.content {
  h1 {
    font-size: 2rem;
    margin: 0 1rem;
  }
  p {
    font-size: 1rem;
    margin: 0 1rem;
  }
}
#subcategory-view-image {
  width: 60vh;
  height: 50vh;
}
.courses {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 4rem;
  justify-content: center;
}
.content img,
.content div {
  width: 50%;
}

@media (max-width: 600px) {
  .content img,
  .content div {
    width: 100%;
  }
}
</style>
