<template>
  <v-row justify="center">
    <v-dialog max-width="700px" :value="isVisible" @keydown.esc="closeModal">
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline" style="margin-left: 0.25rem"
              >Nagranie 24h</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    rules="required"
                    v-model="link"
                    label="Link do nagrania"
                    color="indigo"
                    outlined
                    dense
                    :disabled="activePackage ? true : false"
                  />
                </v-col>

                <v-col cols="12" v-if="!activePackage">
                  <b
                    ><p
                      style="
                        padding-top: 0;
                        margin-bottom: 0;
                        margin-left: 0.25rem;
                      "
                    >
                      Lista użytkowników oczekujących na nagranie
                    </p></b
                  >
                  <p
                    style="
                      margin-top: 0;
                      margin-bottom: 0;
                      margin-left: 0.25rem;
                      display: flex;
                      align-items: center;
                    "
                    v-for="user in waitingUsers"
                    :key="user.id"
                  >
                    {{ user.email }}
                    <v-btn
                      class="ml-1"
                      icon
                      color="red"
                      @click="removePackage24hMember(user.id)"
                    >
                      <font-awesome-icon icon="times" />
                    </v-btn>
                    <before-delete-modal
                      :isVisible="isDeleteModalVisible"
                      @closeModal="isDeleteModalVisible = false"
                      @confirmDeletion="confirmDeleteMember()"
                    />
                  </p>
                  <p
                    v-if="!waitingUsers || waitingUsers.length === 0"
                    style="
                      padding-top: 0;
                      padding-bottom: 0;
                      margin-left: 0.25rem;
                    "
                  >
                    Brak zapisanych osób
                  </p>
                </v-col>

                <v-col cols="12" sm="12" v-if="activePackage">
                  <b
                    ><p
                      style="
                        padding-top: 0;
                        margin-bottom: 0;
                        margin-left: 0.25rem;
                      "
                    >
                      Lista użytkowników, którzy otrzymali nagranie
                    </p></b
                  >
                  <p
                    style="
                      margin-top: 0;
                      margin-bottom: 0;
                      margin-left: 0.25rem;
                    "
                    v-for="user in activePackageUsers"
                    :key="user.id"
                  >
                    {{ user.email }}
                  </p>
                  <p
                    v-if="
                      !activePackageUsers || activePackageUsers.length === 0
                    "
                    style="padding-top: 0; padding-bottom: 0"
                  >
                    Brak
                  </p>
                </v-col>
              </v-row>

              <v-row v-if="!activePackage">
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    v-model="newMemberEmail"
                    label="Wprowadź email, który chcesz dodać do listy oczekujących"
                    color="indigo"
                    outlined
                    dense
                  />
                  <v-btn
                    style="padding: 0; margin-left: 0.25rem"
                    color="success"
                    text
                    @click="addPackage24hMember"
                  >
                    <font-awesome-icon icon="save" class="mr-1" />
                    {{ "Zapisz email" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="
                invalid || activePackage
                  ? true
                  : false || !waitingUsers || waitingUsers.length === 0
              "
              @click="addOrUpdatePackage24h"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              {{ "Wyślij link do nagrania" }}
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import BeforeDeleteModal from "./BeforeDeleteModal.vue";

export default {
  name: "RecordingModal",
  props: ["isVisible", "packageData"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    BeforeDeleteModal,
  },

  data: () => ({
    packageId: null,
    link: "",
    newMemberEmail: "",
    isDeleteModalVisible: false,
    selectedUserId: null,
  }),
  async updated() {
    if (this.packageData) {
      const { id } = this.packageData;
      this.packageId = id;
      await this.$store.dispatch(
        "packages24h/fetchUsersWaitingForPackage24h",
        id
      );
      await this.$store.dispatch(
        "packages24h/fetchActivePackage24hForPackage",
        id
      );

      await this.$store.dispatch("packages24h/fetchUsersForPackage24h", id);

      if (this.activePackage) {
        this.link = this.activePackage.link;
      } else {
        this.link = "";
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    async addOrUpdatePackage24h() {
      let isNew = !this.activePackage;
      await this.$store.dispatch("packages24h/addOrUpdatePackage24h", {
        packageId: this.packageId,
        link: this.link,
      });
      if (isNew) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "success",
          message: "Nagranie zostało wysłane",
        });
      }
      this.closeModal();
    },

    async addPackage24hMember() {
      if (!this.newMemberEmail) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "error",
          message:
            "Podaj adres email, który chcesz dodać do listy oczekujących użytkowników",
        });
        return;
      }
      const addingStatus = await this.$store.dispatch(
        "packages24h/addPackage24hMember",
        {
          packageId: this.packageId,
          email: this.newMemberEmail,
        }
      );

      if (addingStatus) {
        this.newMemberEmail = "";
        await this.$store.dispatch(
          "packages24h/fetchUsersWaitingForPackage24h",
          this.packageData.id
        );

        await this.$store.dispatch(
          "packages24h/fetchUsersForPackage24h",
          this.packageData.id
        );
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "success",
          message: "Użytkownik został dodany do listy oczekujących",
        });
      }
    },

    async confirmDeleteMember() {
      const removingStatus = await this.$store.dispatch(
        "packages24h/removePackage24hMember",
        {
          packageId: this.packageId,
          package24hMemberId: this.selectedUserId,
        }
      );
      if (removingStatus) {
        await this.$store.dispatch(
          "packages24h/fetchUsersWaitingForPackage24h",
          this.packageData.id
        );

        await this.$store.dispatch(
          "packages24h/fetchUsersForPackage24h",
          this.packageData.id
        );
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "success",
          message: "Użytkownik został usunięty z listy oczekujących",
        });
      } else {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "error",
          message: "Nie udało się usunąć użytkownika listy oczekujących",
        });
      }
    },

    async removePackage24hMember(userId) {
      this.selectedUserId = userId;
      this.isDeleteModalVisible = true;
    },
  },
  computed: {
    waitingUsers() {
      return this.$store.getters["packages24h/getWaitingUsers"];
    },
    activePackage() {
      return this.$store.getters["packages24h/getActivePackage"];
    },
    package24hUsers() {
      return this.$store.getters["packages24h/get"];
    },
    activePackageUsers() {
      return this.$store.getters["packages24h/getActivePackageUsers"];
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.error--text {
  font-size: 20px;
}
</style>
