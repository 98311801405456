<template>
  <div class="mb-6">
    <management-nav />
    <div class="my-6 ml-1">
      <h3 class="ml-3 mb-4 label-schedule">
        Platforma jogi online - Ogólna
        <v-btn
          class="ml-4"
          fab
          dark
          color="green"
          bottom
          x-small
          v-on:click="(isModalVisible = true), (selectedType = 'STANDARD')"
        >
          <font-awesome-icon icon="plus" />
        </v-btn>
      </h3>

      <admin-schedule key="STANDARD" :type="'STANDARD'" :withoutTitle="true" />
    </div>

    <div class="my-4 ml-1 mb-6">
      <h3 class="ml-3 mb-4 label-schedule">
        Platforma jogi online - Początek
        <v-btn
          class="ml-4"
          fab
          dark
          color="green"
          bottom
          x-small
          v-on:click="(isModalVisible = true), (selectedType = 'FOR-BEGINNERS')"
        >
          <font-awesome-icon icon="plus" />
        </v-btn>
      </h3>
      <admin-schedule
        key="FOR-BEGINNERS"
        :type="'FOR-BEGINNERS'"
        :withoutTitle="true"
      />
    </div>

    <add-schedule-day-modal
      :isVisible="isModalVisible"
      @closeModal="isModalVisible = false"
      @confirm="handleAddVideoToSchedule"
      :type="selectedType"
    />
  </div>
</template>

<script>
import AdminSchedule from "../components/AdminSchedule.vue";
import ManagementNav from "../components/ManagementNav.vue";
import AddScheduleDayModal from "../components/modals/AddScheduleDayModal.vue";
export default {
  name: "ScheduleManagement",
  components: {
    ManagementNav,
    AdminSchedule,
    AddScheduleDayModal,
  },
  async created() {
    await this.$store.dispatch("timePackages/fetchAllTimePackages");
  },
  data: () => ({
    isModalVisible: false,
    selectedType: "STANDARD",
  }),
  computed: {},
  watch: {},
  methods: {
    async handleAddVideoToSchedule(data) {
      const { day, timePackageItemId, type } = data;
      await this.$store.dispatch("users/addVideoToUserSchedule", {
        timePackageItemId,
        type,
        day,
      });
      this.$store.dispatch("snackbar/showSnackbar", {
        color: "success",
        message: "Zaktualizowano kurs w harmonogramie",
      });
    },
  },
};
</script>

<style scoped>
.label-schedule {
  display: flex;
  align-items: center;
}
</style>
