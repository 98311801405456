<template>
  <div>
    <div class="side-menu">
      <svg
        @click="drawer = !drawer"
        class="position"
        width="20"
        height="20"
        viewBox="0 0 35 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H35V4.16667H0V0ZM0 10.4167H35V14.5833H0V10.4167ZM0 20.8333H35V25H0V20.8333Z"
          fill="black"
        />
      </svg>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        left
        color="#454e5b"
        :width="widthModal"
        style="calc(100% - 48px); height: 100vh;"
      >
        <div class="close-section">
          <svg
            class="close-button"
            @click="hideSideMenu"
            width="15"
            height="15"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 25L0 22.5L10 12.5L0 2.5L2.5 0L12.5 10L22.5 0L25 2.5L15 12.5L25 22.5L22.5 25L12.5 15L2.5 25Z"
              fill="#656565"
            />
          </svg>
        </div>
        <div class="sidebar-details">
          <div class="user-dat" :style="`max-width:${widthModal - 10}px`">
            <v-dialog
              v-model="dialog"
              width="650"
              v-if="loggedIn || (token && token.id)"
            >
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-if="loggedIn || (token && token.id)"
                  v-bind="attrs"
                  v-on="on"
                  :src="imagePath"
                  alt="
                  prana vyavama,
                  kręgosłup,
                  praktyka dla początkujących,
                  joga online,
                  anatomia jogi.
                  pranajama oddech,
                  kurs jogi online,
                  uważna praktyka,
                  joga wzmacniająca,
                  filmy jogi,
                  joga stanie na rękach
                  "
                  loading="lazy"
                />
              </template>

              <v-card class="px-3 py-3">
                <img
                  class="modal-img"
                  :src="imagePath"
                  alt="
                  prana vyavama,
                  techniki oddechowe,
                  kręgosłup,
                  pranajama oddech,
                  uważna praktyka,
                  praktyka,
                  kurs jogi online,
                  joga wzmacniająca,
                  joga balanse
                  "
                  loading="lazy"
                />
              </v-card>
            </v-dialog>
            <div
              v-if="
                (loggedIn || (token && token.id)) && profile && profile.email
              "
            >
              <p class="welcome">Witaj,<br /></p>

              <p class="email">
                {{
                  (breakpointName === "lg" || breakpointName === "xl") &&
                  profile.email.length > 39
                    ? profile.email.substring(0, 39) + "..."
                    : breakpointName === "md" && profile.email.length > 32
                    ? profile.email.substring(0, 32) + "..."
                    : breakpointName === "xs" && profile.email.length > 30
                    ? profile.email.substring(0, 30) + "..."
                    : breakpointName === "sm" && profile.email.length > 30
                    ? profile.email.substring(0, 30) + "..."
                    : profile.email
                }}
              </p>
            </div>
          </div>

          <a href="https://dagajoga.pl">
            <div class="side-btn" @click="drawer = !drawer">
              <span>Strona Główna </span>
            </div></a
          >
          <div class="side-btn-no-hover unfoldable-position">
            <div
              @click="unfoldOffers = !unfoldOffers"
              :class="unfoldOffers ? 'unfolded' : ''"
              class="unfoldable-title"
            >
              <span class="side-btn">Oferta</span>
              <span class="submenu-toggle"
                ><svg
                  class="svg-inline--fa fa-caret-down fa-w10"
                  aria-hidden="true"
                  data-prefix="fas"
                  data-icon="caret-down"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                  ></path>
                </svg>
              </span>
            </div>
            <div v-if="unfoldOffers" class="unfolded-row">
              <div
                :class="unfoldPlatformsInner ? 'unfolded' : ''"
                class="sub-unfold"
              >
                <div
                  @click="unfoldPlatformsInner = !unfoldPlatformsInner"
                  class="unfold-title"
                >
                  <div class="">Platformy jogi online</div>
                  <span class="submenu-toggle"
                    ><svg
                      class="svg-inline--fa fa-caret-down fa-w10"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="caret-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                      ></path>
                    </svg>
                  </span>
                </div>

                <div v-if="unfoldPlatformsInner" class="sub-unfolded-part">
                  <div class="side-btn" @click="redirectCourses">
                    <span>Platforma jogi online - Ogólna</span>
                  </div>
                  <!-- <div class="side-btn" @click="redirectCoursesExtended">
                    <span>Platforma jogi online - Rozszerzona</span>
                  </div> -->
                  <div class="side-btn" @click="redirectCoursesForBeginners">
                    <span>Platforma jogi online - Początek</span>
                  </div>
                </div>
              </div>

              <a href="https://dagajoga.pl/index.php/zajecia-na-zywo/">
                <div class="side-btn" @click="drawer = !drawer">
                  <span style="margin-left: -3px">Zajęcia na żywo ZOOM</span>
                </div></a
              >

              <!-- <div class="side-btn" @click="moveToWorkshops">
                <span style="margin-left: -3px">Kursy online</span>
              </div> -->
              <a href="https://dagajoga.pl/index.php/aktualnosci/">
                <div class="side-btn" @click="drawer = !drawer">
                  <span style="margin-left: -3px"
                    >Wyjazdy z jogą i warsztaty/kursy</span
                  >
                </div></a
              >

              <a href="https://dagajoga.pl/index.php/zapros-mnie/">
                <div class="side-btn" @click="drawer = !drawer">
                  <span>Zaproś mnie / Konsultacje online</span>
                </div></a
              >
              <div :class="unfoldBons ? 'unfolded' : ''" class="sub-unfold">
                <div @click="unfoldBons = !unfoldBons" class="unfold-title">
                  <div class="">Bony podarunkowe</div>
                  <span class="submenu-toggle"
                    ><svg
                      class="svg-inline--fa fa-caret-down fa-w10"
                      aria-hidden="true"
                      data-prefix="fas"
                      data-icon="caret-down"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <path
                        fill="currentColor"
                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                      ></path>
                    </svg>
                  </span>
                </div>

                <div v-if="unfoldBons" class="sub-unfolded-part">
                  <div class="side-btn" @click="moveToGiftVouchers">
                    <span>Bony Podarunkowe</span>
                  </div>
                  <div class="side-btn" @click="moveToGiftVouchersForBeginners">
                    <span>Bony Podarunkowe Początek</span>
                  </div>
                </div>
              </div>

              <!-- <div class="side-btn" @click="moveToGiftVouchersForBeginners">
                <span>Bony Podarunkowe - DagaJoga Początek</span>
              </div> -->
            </div>
          </div>
          <a href="https://dagajoga.pl/index.php/o-studiu/">
            <div class="side-btn" @click="drawer = !drawer">
              <span>O mnie</span>
            </div></a
          >
          <div class="side-btn unfoldable-position">
            <div
              @click="unfoldPlatforms = !unfoldPlatforms"
              :class="unfoldPlatforms ? 'unfolded' : ''"
              class="unfoldable-title"
            >
              <span class="">Platformy jogi online</span>
              <span class="submenu-toggle"
                ><svg
                  class="svg-inline--fa fa-caret-down fa-w10"
                  aria-hidden="true"
                  data-prefix="fas"
                  data-icon="caret-down"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                  ></path>
                </svg>
              </span>
            </div>
            <div v-if="unfoldPlatforms" class="unfolded-row">
              <div class="side-btn" @click="redirectCourses">
                <span>Platforma jogi online - Ogólna</span>
              </div>
              <!-- <div class="side-btn" @click="redirectCoursesExtended">
                <span>Platforma jogi online - Rozszerzona</span>
              </div> -->

              <div class="side-btn ml-1" @click="redirectCoursesForBeginners">
                <span style="margin-left: -3px"
                  >Platforma jogi online - Początek</span
                >
              </div>
            </div>
          </div>

          <div v-if="token" class="side-btn" @click="moveToAccount">
            <span>Moje konto</span>
          </div>

          <div
            class="side-btn"
            @click="moveToSequencingWorkshop(sequencingWorkshop)"
            v-for="sequencingWorkshop in sequencingWorkshops"
            :key="sequencingWorkshop.id"
          >
            <span>{{ `${sequencingWorkshop.name}` }}</span>
          </div>

          <a href="https://dagajoga.pl/index.php/faq/" style="margin: 0">
            <div class="side-btn" @click="drawer = !drawer">
              <span>FAQ</span>
            </div></a
          >

          <a href="https://dagajoga.pl/index.php/blog/" style="margin: 0">
            <div class="side-btn" @click="drawer = !drawer">
              <span>Blog</span>
            </div></a
          >
          <a href="" style="margin: 0">
            <div class="side-btn" @click="drawer = !drawer">
              <span
                ><router-link class="side-btn" to="/sklep/produkty"
                  >Sklep</router-link
                ></span
              >
            </div></a
          >

          <a href="" style="margin: 0">
            <div class="side-btn" @click="drawer = !drawer">
              <span
                ><router-link class="side-btn" to="/sklep/koszyk"
                  >Koszyk</router-link
                ></span
              >
            </div></a
          >

          <div v-if="token" class="side-btn" @click="moveToAccount">
            <span>Moje konto</span>
          </div>

          <div
            class="side-btn"
            @click="redirectLogin(0)"
            v-if="!token || !token.id"
          >
            <span>Zaloguj się</span>
          </div>

          <div
            class="side-btn last"
            @click="redirectLogin(1)"
            v-if="!token || !token.id"
          >
            <span style="margin-left: -3px">Zarejestruj się</span>
          </div>
          <div
            class="side-btn last"
            @click="logout"
            v-if="loggedIn || (token && token.id)"
          >
            <span style="margin-left: -4px"> Wyloguj</span>
          </div>
        </div>
        <div class="icons">
          <ul class="social-list">
            <li class="social-icon">
              <a href="https://www.instagram.com/dagajoga/">
                <svg
                  class="svg-inline--fa fa-instagram fa-w-14"
                  aria-hidden="true"
                  data-prefix="fab"
                  data-icon="instagram"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </a>
            </li>
            <li class="social-icon">
              <a href="https://www.facebook.com/Dagajoga">
                <svg
                  class="svg-inline--fa fa-facebook fa-w-14"
                  aria-hidden="true"
                  data-prefix="fab"
                  data-icon="facebook"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M448 56.7v398.5c0 13.7-11.1 24.7-24.7 24.7H309.1V306.5h58.2l8.7-67.6h-67v-43.2c0-19.6 5.4-32.9 33.5-32.9h35.8v-60.5c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9h-58.4v67.6h58.4V480H24.7C11.1 480 0 468.9 0 455.3V56.7C0 43.1 11.1 32 24.7 32h398.5c13.7 0 24.8 11.1 24.8 24.7z"
                  ></path>
                </svg>
              </a>
            </li>
            <li class="social-icon">
              <a href="https://web.dagajoga.pl/newsletter">
                <svg
                  class="svg-inline--fa fa-newspaper fa-w-17"
                  aria-hidden="true"
                  data-prefix="fas"
                  data-icon="newspaper"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M552 64H88c-13.255 0-24 10.745-24 24v8H24c-13.255 0-24 10.745-24 24v272c0 30.928 25.072 56 56 56h472c26.51 0 48-21.49 48-48V88c0-13.255-10.745-24-24-24zM56 400a8 8 0 0 1-8-8V144h16v248a8 8 0 0 1-8 8zm236-16H140c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm208 0H348c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm-208-96H140c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm208 0H348c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm0-96H140c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h360c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12z"
                  ></path>
                </svg>
              </a>
            </li>
            <li class="social-icon">
              <a
                href="https://api.whatsapp.com/send/?phone=512053818&text&type=phone_number&app_absent=0"
              >
                <svg
                  class="svg-inline--fa fa-whatsapp fa-w-14"
                  aria-hidden="true"
                  data-prefix="fas"
                  data-icon="whatsapp"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import { API_URL } from "../axios";
import router from "../router";
export default {
  name: "SideMenu",
  data: () => ({
    loggedIn: false,
    isShown: false,
    drawer: false,
    dialog: false,
    dialogchangepic: false,
    file: null,
    imagePath: "",
    coursesElementExist: true,
    logged: window.localStorage.getItem("userToken") ? true : false,
    unfoldOffers: false,
    unfoldPlatforms: false,
    unfoldPlatformsInner: false,
    unfoldBons: false,
  }),
  watch: {
    token() {
      this.$store.dispatch("sequencingWorkshops/getOwnSequencingWorkshops");
    },
  },
  async created() {
    this.$store.dispatch("timePackages/fetchAllTimePackageItems");
    await this.$store.dispatch(`auth/fetchUserProfile`);
    if (this.profile && this.profile.id) {
      await this.$store.dispatch(`auth/fetchProfilePicture`, this.profile.id);
    }
    const token = window.localStorage.getItem("userToken");
    if (token) {
      this.loggedIn = true;
      this.imagePath = this.fetchProfileImage(this.profile.id);
      this.$store.dispatch("sequencingWorkshops/getOwnSequencingWorkshops");
    } else {
      this.loggedIn = false;
    }
    setTimeout(() => {
      const coursesElement = document.getElementById("courses");
      if (coursesElement) {
        this.coursesElementExist = true;
      } else {
        this.coursesElementExist = false;
      }
    }, 1000);
  },
  methods: {
    redirectCoursesExtended() {
      if (this.isCourses) {
        this.drawer = false;
      } else {
        this.$router.push({
          name: "CoursesExtended",
        });
        this.drawer = false;
      }
    },
    moveToSequencingWorkshop(sequencingWorkshop) {
      this.$router.push({
        name: "SequencingWorkshops",
        params: { id: sequencingWorkshop.id },
      });
    },
    hideSideMenu() {
      this.drawer = false;
    },
    moveToAccount() {
      if (this.loggedIn || (this.token && this.token.id)) {
        router.push({ path: "/konto" });
      } else {
        router.push({ path: "/logowanie" });
      }
    },
    moveToWorkshops() {
      this.hideSideMenu();
      if (this.$router.currentRoute.name !== "Workshops") {
        this.$router.push({ name: "Workshops" });
      }
    },
    moveToGiftVouchers() {
      if (this.$router.currentRoute.name !== "GiftVouchers") {
        this.$router.push({ name: "GiftVouchers" });
      }
    },

    moveToGiftVouchersForBeginners() {
      if (this.$router.currentRoute.name !== "GiftVouchersForBeginners") {
        this.$router.push({ name: "GiftVouchersForBeginners" });
      }
    },

    moveToGiftCardPage() {
      this.hideSideMenu();
      this.$router.push({ name: "GiftCardEnterCode" });
    },
    moveToAccountPage() {
      this.hideSideMenu();
      this.$router.push({ name: "UserAccount" });
    },
    changePassword() {
      this.hideSideMenu();
      this.$router.push({ name: "ResetPassword" });
    },
    redirectLogin(signup) {
      this.$router.push({
        name: "Auth",
        params: { name: "Courses", signup: signup },
      });
    },
    redirectCourses() {
      if (this.isCourses) {
        this.drawer = false;
      } else {
        this.$router.push({
          name: "Courses",
        });
        this.drawer = false;
      }
    },

    redirectCoursesForBeginners() {
      if (this.isCoursesForBeginners) {
        this.drawer = false;
      } else {
        this.$router.push({
          name: "CoursesForBeginners",
        });
        this.drawer = false;
      }
    },
    async logout() {
      window.localStorage.removeItem("userToken");
      window.localStorage.removeItem("token");
      this.$store.dispatch("auth/setTokenToNull");
      this.$router.push({ name: "Courses" });
      this.$store.dispatch("timePackages/fetchAllTimePackageItems");
      await this.$store.dispatch(`auth/fetchUserProfile`);
      if (this.profile && this.profile.id) {
        await this.$store.dispatch(`auth/fetchProfilePicture`, this.profile.id);
      }
      const token = window.localStorage.getItem("userToken");
      if (token) {
        this.loggedIn = true;
        this.imagePath = this.fetchProfileImage(this.profile.id);
      } else {
        this.loggedIn = false;
      }
    },
    fetchProfileImage(id) {
      return `${API_URL}/profile/${id}/image`;
    },

    async onUpload() {
      await this.$store.dispatch("auth/setProfilePicture", this.file);
      this.imagePath = this.fetchProfileImage(this.profile.id);
      this.imagePath = `${this.imagePath}?time=${new Date().getTime()}`;
      this.$emit("profileImageUpdated");
      this.dialogchangepic = false;
    },
  },

  computed: {
    sequencingWorkshops() {
      if (!this.token) {
        return [];
      } else {
        return this.$store.getters[
          "sequencingWorkshops/getSequencingWorkshops"
        ];
      }
    },
    profile() {
      return this.$store.getters["auth/getProfile"];
    },
    token() {
      return this.$store.getters["auth/getToken"];
    },
    widthModal() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 320;
        case "sm":
          return 320;
        case "md":
          return 320;
        case "lg":
          return 320;
        case "xl":
          return 320;
        default:
          return 320;
      }
    },
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
    profilePicture() {
      return this.$store.getters["auth/getProfilePicture"];
    },
    isCourses() {
      if (this.$route.name === "Courses") return true;
      return false;
    },
    isCoursesForBeginners() {
      if (this.$route.name === "CoursesForBeginners") return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@300;400;600;700&family=Raleway:wght@400;500&family=Roboto:wght@300;400;500;700&display=swap");
* {
  font-size: "Roboto", sans-serif;
}

.position {
  position: absolute;
  top: 75px;
  right: 5rem;
  cursor: pointer;
  color: #1d1616d8;
  z-index: 1;
  font-size: 2.4rem;
}

a {
  text-decoration: none;
}
.user-dat {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 100px;
    margin-top: -5px;
  }
}
.email {
}

h2 {
  margin-top: 30px;
}

.background {
  color: #fff;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  margin-top: 50px;
  padding: 15px 25px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.2rem;
}

/* SIDEBAR -----------------*/

.sidebar-details {
  padding: 12px 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  border-bottom: 1px solid rgb(226, 226, 226);
}
.sidebar-details {
  text-align: left;
}
.close-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgb(226, 226, 226);
}
.close-button {
  cursor: pointer;
  margin-right: 25px;
  margin-top: 5px;
}

.side-btn {
  cursor: pointer;
  padding: 14px 0px;
  text-align: left;
  width: 100%;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 700;
  font-family: Montserrat;
  letter-spacing: 1px;
  color: #333;
  span {
    padding: 0 15px;
  }
  &:hover {
    color: #b28a8a;
  }
}
.side-btn-no-hover {
  cursor: pointer;
  padding: 15px 0px;
  text-align: left;
  width: 100%;
  padding-bottom: 15px;
  transition: all 0.5s;
  font-family: Montserrat;
  letter-spacing: 1px;
  color: #333;
  font-size: 13px;
  font-weight: 700;

  span {
    padding: 0 15px;
  }
}
.unfoldable-position {
  display: flex;
  flex-direction: column;
  .unfoldable-title {
    display: flex;
    justify-content: space-between;
  }
}
.unfolded-row {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  background: rgba(0, 0, 0, 0.03) !important;
  width: 100%;
  padding: 0px 2px;
  margin-bottom: -15px !important;
  .side-btn {
    padding: 15px 0;
    cursor: pointer;
    font-weight: 800;
    font-size: 13px !important;
    &:hover {
      color: #000;
      background: rgba(0, 0, 0, 0.02);
    }
  }
}

.sub-unfold {
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  .unfold-title {
    padding-left: 13px;
    display: flex;
    justify-content: space-between;
  }
}
.sub-unfolded-part {
  margin-top: 20px;
  background: #f2f2f2;
  margin-bottom: -20px;
}
.image-modal {
  align-self: flex-start;
  width: 100%;
}
.last {
  border-bottom: none;
}
.sidebar-details h1 {
  margin-bottom: 30px;
  position: relative;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 27px;
}
.welcome {
  font-size: 1.3rem;
  font-weight: 600;
  color: #000;
}
.email {
  font-size: 0.8rem;
  color: #000;
}
a {
  text-decoration: none;
  width: 100%;
}
i {
  padding-right: 15px;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.inside-modal {
  color: #fff;
  margin-top: 0;
  font-size: 1rem;
  font-weight: 300;
}
svg {
  transition: 0.3s all;
}
.unfolded {
  svg {
    transform: rotate(180deg);
  }
}
.icons {
  padding: 1rem 0;
  padding-left: 150px;
  @media screen and (max-width: 1350px) {
    padding-left: 100px;
  }
  @media screen and (max-width: 1300px) {
    padding-left: 0px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    display: flex;
    list-style: none;
    li {
      margin: 0 0.5rem;
      svg {
        transition: 0.3s;
      }
    }
    li:hover svg {
      color: #b28a8a;
    }
  }
  svg {
    color: #000;
  }
}
@media screen and (max-width: 500px) {
  .position {
    right: 1.2rem;
  }

  // .user-dat {
  //   h1 {
  //     font-size: 1.2rem;
  //   }
  // }
  .welcome,
  .email {
    margin: 0;
  }
}
</style>
