<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-6 class="transparent-bg">
        <h1 class="text-center">Dziękujemy za rejestrację</h1>
        <blockquote class="blockquote text-center">
          Aby dokończyć proces aktywacji konta, kliknij w link aktywacyjny
          przesłany na podany przez Ciebie e-mail.
        </blockquote>
        <blockquote class="blockquote text-center">
          <a class="center" @click="redirect">
            <div class="btn-gradient">Zaloguj się</div>
          </a>
        </blockquote>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    title: "Dagajoga rejestracja - portal jogi online",
  },
  methods: {
    redirect() {
      this.$router.push({
        name: "Auth",
        params: { previousPage: "UserAccount" },
      });
    },
  },
};
</script>

<style scoped>
.transparent-bg {
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}

.btn-gradient {
  width: fit-content;
  padding: 15px 25px;
  border-radius: 30px;
  font-size: 1.4rem;
}
.center {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-top: 15px;
}
a {
  text-decoration: none;
}
</style>
