<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj warsztat z taryfami</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    type="text"
                    rules="required"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="2048"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="price"
                    label="Cena"
                    color="indigo"
                    outlined
                    dense
                    :onlyNumbers="true"
                    rules="required"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="priceWhenExtending"
                    label="Cena przy przedłużaniu"
                    color="indigo"
                    outlined
                    dense
                    :onlyNumbers="true"
                    rules="min:0"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="numberOfDays"
                    label="Liczba dni dostępu"
                    color="indigo"
                    outlined
                    dense
                    :onlyNumbers="true"
                    rules="min:1"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="maxNumberOfMembers"
                    label="Liczba uczestników"
                    color="indigo"
                    outlined
                    dense
                    :onlyNumbers="true"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="whatsapp"
                    label="WhatsApp"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="zoom"
                    label="Link Zoom"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-file-input v-model="file" label="Zdjęcie"></v-file-input>
                </v-col>
              </v-row>

              <div class="ml-1 mb-12 mt-8">
                <p>
                  <b>Zniżki</b>
                  <v-btn
                    @click="addNextDiscount"
                    class="ml-2"
                    fab
                    x-small
                    color="green"
                  >
                    <font-awesome-icon icon="plus" />
                  </v-btn>
                </p>
              </div>

              <v-row class="mb-12">
                <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                  <v-row
                    v-for="(discount, index) in discounts"
                    :key="`codeValue${index}`"
                  >
                    <v-col
                      cols="12"
                      sm="5"
                      style="padding-top: 0; padding-bottom: 0"
                    >
                      <VTextFieldWithValidation
                        type="number"
                        v-model.number="discount.value"
                        label="Wartość zniżki w procentach"
                        color="indigo"
                        outlined
                        dense
                        rules="min_value:0.01"
                        @newValueAfterChange="
                          handleNewDiscountValue($event, index)
                        "
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="5"
                      style="padding-top: 0; padding-bottom: 0"
                    >
                      <VTextFieldWithValidation
                        v-model="discount.code"
                        label="Kod zniżkowy"
                        color="indigo"
                        outlined
                        dense
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="2"
                      style="padding-top: 0; padding-bottom: 0"
                    >
                      <v-btn
                        @click="removeDiscount(discount, index)"
                        class="ml-2"
                        fab
                        x-small
                        color="red"
                      >
                        <font-awesome-icon icon="minus" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="isWorkshops"
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="maxNumberOfMembers"
                    label="Liczba uczestników"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12" v-if="discountError">
                  <small class="error--text"
                    >Zniżka nie może być większa od ceny</small
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addWorkshopItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "AddTariffWorkshopModal",
  props: ["isVisible", "workshopItemData"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },

  data: () => ({
    workshopId: null,
    input: "",
    discountError: false,
    updated: false,
    name: "",
    description: "",
    file: null,
    maxNumberOfMembers: 30,
    numberOfDays: 31,
    price: null,
    priceWhenExtending: null,
    whatsapp: "",
    zoom: "",
    discountCode: "",
    discount: 0,
    discounts: [],
    discountsToRemove: [],
  }),
  updated() {
    if (this.workshopItemData) {
      const {
        id,
        name,
        description,
        imagePath,
        maxNumberOfMembers,
        numberOfDays,
        price,
        whatsapp,
        zoom,
        priceWhenExtending,
      } = this.workshopItemData;
      this.workshopItemId = id;
      this.updated = this.workshopItemId ? true : false;
      this.name = name;
      this.description = description;
      this.imagePath = imagePath;
      this.maxNumberOfMembers = maxNumberOfMembers;
      this.numberOfDays = numberOfDays;
      this.price = price;
      this.whatsapp = whatsapp;
      this.zoom = zoom;
      this.priceWhenExtending = priceWhenExtending;
    }
  },

  watch: {
    workshopItemData() {
      if (this.workshopItemData) {
        this.workshopItemData.discountCodes.forEach((code) => {
          this.discounts.push({
            code: code.code,
            value: code.value,
            id: code.id,
          });
        });
      }
    },
  },

  async created() {},

  methods: {
    handleNewDiscountValue(value, index) {
      if (value && Number(value) > 1) {
        this.discounts[index].value = 0.99;
      }
    },
    addNextDiscount() {
      this.discounts.push({
        code: "",
        value: 0.0,
      });
    },
    removeDiscount(discount, index) {
      if (discount.id) {
        this.discountsToRemove.push(discount);
      }
      this.discounts.splice(index, 1);
    },

    async handleRemoveDiscountCodes() {
      if (this.discountsToRemove && this.discountsToRemove.length > 0) {
        for await (const discount of this.discountsToRemove) {
          await this.$store.dispatch("discountCode/deleteDiscountCode", {
            id: discount.id,
          });
        }
      }
    },

    async handleAddAndUpdateDiscountCodes(sequencingWorkshopId) {
      if (this.discounts) {
        for await (const discount of this.discounts) {
          const { id, code, value } = discount;
          if (!id) {
            await this.$store.dispatch("discountCode/addDiscountCode", {
              data: {
                sequencingWorkshopId,
                code: code,
                value,
              },
            });
          } else {
            await this.$store.dispatch("discountCode/updateDiscountCode", {
              discountCodeId: id,
              data: {
                sequencingWorkshopId,
                code: code,
                value,
              },
            });
          }
        }
      }
    },

    closeModal() {
      this.updated = false;
      this.name = "";
      this.description = "";
      this.file = null;
      this.maxNumberOfMembers = 30;
      this.numberOfDays = 31;
      this.price = null;
      this.priceWhenExtending = null;
      this.whatsapp = "";
      this.zoom = "";
      this.discounts = [];
      this.discountsToRemove = [];
      this.$emit("closeModal");
    },
    async addWorkshopItem() {
      const codesArray = this.discounts.map(function (item) {
        return item.code;
      });
      let moreThanOneSameCodes = codesArray.some(function (item, idx) {
        return codesArray.indexOf(item) != idx;
      });
      if (moreThanOneSameCodes) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Wszystkie kody zniżkowe muszą być unikalne",
        });
        return;
      }

      if (this.discount >= this.price) {
        this.discountError = true;
        setTimeout(() => {
          this.discountError = false;
        }, 2000);
        return;
      }

      let filesAreCorrect = true;

      if (this.file && !this.file.type.includes("image")) {
        filesAreCorrect = false;
      }

      if (!filesAreCorrect) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message:
            "Niepoprawny format pliku. Dozwolone formaty to: jpg, jpeg, png",
        });
        return;
      }

      let object = {
        name: this.name,
        description: this.description,
        file: this.file,
        maxNumberOfMembers: this.maxNumberOfMembers,
        numberOfDays: this.numberOfDays,
        price: this.price,
        priceWhenExtending: !this.priceWhenExtending
          ? 0
          : this.priceWhenExtending,
        type: "PRICE_TARIFF",
        whatsapp: this.whatsapp,
        zoom: this.zoom,
      };
      if (this.updated) {
        await this.handleRemoveDiscountCodes();
        await this.handleAddAndUpdateDiscountCodes(this.workshopItemId);
        await this.$store
          .dispatch("sequencingWorkshops/editSequencingWorkshop", {
            id: this.workshopItemId,
            data: object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      } else {
        await this.$store
          .dispatch("sequencingWorkshops/createSequencingWorkshop", {
            data: object,
          })
          .then(async (addedWorkshop) => {
            await this.handleAddAndUpdateDiscountCodes(addedWorkshop.id);
            this.closeModal();
            this.$emit("updated");
          });
      }
    },
  },

  computed: {},
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
