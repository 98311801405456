<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-3 class="transparent-bg">
        <h1 class="text-center">
          Dziękujemy za zapisanie się na warsztaty z sekwencjonowania
        </h1>
        <div class="text-box">
          <a :href="homepage">
            <button class="primary-button margin-auto">Strona główna</button>
          </a>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import config from "../config";
export default {
  metaInfo: {
    title: "Dagajoga warsztaty z sekwencjonowania - portal jogi online",
  },
  data: function () {
    return {
      homepage: config.HOMEPAGE,
    };
  },
};
</script>

<style scoped>
.transparent-bg {
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}
.text-box {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
a {
  text-decoration: none;
}
</style>
