<template>
  <div>
    <ReturnBtn
      :path="
        this.path
          ? this.path
          : `${
              selectedVideo && selectedVideo.forBeginners
                ? 'subskrypcja-podstawy'
                : 'subskrypcja'
            }`
      "
      blackColor
    ></ReturnBtn>

    <v-row class="card">
      <div class="switch">
        <div
          :class="{ selectedSwitch: selectedVersion === 'DEMO' }"
          class="demo btn-gradient"
          @click="changeVersion()"
          v-if="getDemoCourseYoutubeId() !== ''"
        >
          Demo
        </div>
        <div
          :class="{ selectedSwitch: selectedVersion === 'FULL' }"
          class="full-video btn-gradient"
        >
          Pełna wersja
        </div>
      </div>

      <v-col v-if="selectedVersion === 'FULL' && getCourseYoutubeId() !== ''">
        <div v-if="hasActiveSubscription && !notAllowedToWatch">
          <video-player :videoId="getCourseYoutubeId()" />
        </div>
        <div class="text-center no-access" v-else>
          <h3>
            Aby uzyskać dostęp do kursów, musisz posiadać konto w serwisie oraz
            mieć aktywną subskrypcję.
          </h3>
          <button class="background" @click="moveToAuth" v-if="!loggedIn">
            Zaloguj się
          </button>
          <button class="background" @click="moveToSubscriptions">
            Wykup dostęp
          </button>
        </div>
      </v-col>
      <p
        align="right"
        class="viewsCounter"
        v-if="selectedVideo && selectedVideo.viewsCounter"
      >
        wyświetlenia: {{ selectedVideo.viewsCounter }}
      </p>
      <p
        align="right"
        class="favorite-button"
        v-if="selectedVideo && loggedIn"
        @click="handleFavourites"
      >
        <font-awesome-icon
          icon="plus"
          class="favorite-icon"
          v-if="!selectedVideo.favorite"
        />
        <font-awesome-icon icon="minus" class="favorite-icon" v-else />
        {{
          selectedVideo.favorite ? "Usuń z ulubionych" : "Dodaj do ulubionych"
        }}
      </p>

      <add-video-to-schedule
        v-if="selectedVideo"
        :selectedVideo="selectedVideo"
      />

      <div class="text-under">
        <h3 class="background">
          {{ selectedVideo ? selectedVideo.name : course ? course.name : "" }}
        </h3>
        <p
          v-html="
            selectedVideo
              ? htmlToFormattedText(selectedVideo.description)
              : course
              ? htmlToFormattedText(course.description)
              : ''
          "
        ></p>
      </div>
    </v-row>
    <CommentSection
      v-if="selectedVideo"
      :id="selectedVideo ? selectedVideo.id : null"
    />
  </div>
</template>

<script>
import ReturnBtn from "../components/ReturnBtn.vue";
import VideoPlayer from "../components/VideoPlayer.vue";
import CommentSection from "../components/CommentSection.vue";
import AddVideoToSchedule from "../components/AddVideoToSchedule.vue";

export default {
  name: "Videos",

  components: {
    ReturnBtn,
    VideoPlayer,
    CommentSection,
    AddVideoToSchedule,
  },
  props: {
    course: Object,
  },

  data: () => ({
    playing: true,
    path: "",
    loggedIn: false,
    selectedVersion: "FULL",
    loading: false,
    notAllowedToWatch: false,
    courseFromUrl: null,
  }),

  async mounted() {
    if (this.$route.params.path) {
      this.path = this.$route.params.path;
    }

    const urlId = this.$router.currentRoute.query.id;
    if (urlId) {
      const id = String(urlId).split("_")[1];
      if (id) {
        const course = await this.$store.dispatch(
          "timePackages/getTimePackageItemById",
          id
        );
        if (course) {
          this.$store.dispatch("videos/setSelectedVideo", {
            video: course,
          });
          this.courseFromUrl = course;
        }
      }
    }

    await this.$store.dispatch(`auth/checkUserActiveSubscriptions`);

    if (this.hasActiveSubscription) {
      await this.$store.dispatch(`timePackages/fetchActiveSubscriptions`);
    } else {
      await this.$store.dispatch(`timePackages/setActiveSubscriptionsToNull`);
    }

    let includedInFreeSubscription = false;
    if (this.courseFromUrl) {
      if (this.course && this.course.includedInFreeSubscription) {
        includedInFreeSubscription = true;
      }
      if (this.selectedVideo && this.selectedVideo.includedInFreeSubscription) {
        includedInFreeSubscription = true;
      }
    } else if (this.course) {
      if (this.course.includedInFreeSubscription) {
        includedInFreeSubscription = true;
      }
      if (this.selectedVideo && this.selectedVideo.includedInFreeSubscription) {
        includedInFreeSubscription = true;
      }
    } else if (this.selectedVideo) {
      if (this.selectedVideo.includedInFreeSubscription) {
        includedInFreeSubscription = true;
      }
    }

    if (
      (!this.activeSubscriptions ||
        this.activeSubscriptions.length == 0 ||
        (this.activeSubscriptions.length < 2 &&
          this.activeSubscriptions[0].freeForRegistration)) &&
      !includedInFreeSubscription
    ) {
      //this.$router.push({ name: "VideosDemo" });
      this.notAllowedToWatch = true;
    }
    const token = window.localStorage.getItem("userToken");
    if (token) {
      this.loggedIn = true;
    }
  },

  methods: {
    async handleFavourites() {
      if (!this.loading) {
        try {
          if (!this.selectedVideo.favorite) {
            this.loading = true;
            const response = await this.$store.dispatch(
              "timePackages/addToFavorite",
              {
                timePackageItemId: this.selectedVideo.id,
                withoutFetching: true,
              }
            );
            this.selectedVideo.favorite = true;
            this.selectedVideo.favoriteItemId = response.id;
          } else {
            this.loading = true;
            await this.$store.dispatch("timePackages/deleteFromFavourites", {
              favoriteItemId: this.selectedVideo.favoriteItemId,
              withoutFetching: true,
            });
            this.selectedVideo.favorite = false;
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      }
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    changeVersion() {
      this.$router.push({
        name: "VideosDemo",
        query: {
          id: `${this.selectedVideo.name.replaceAll(" ", "-")}_${
            this.selectedVideo.id
          }`,
        },
        params: { path: this.path },
      });
    },
    getDemoCourseYoutubeId() {
      let courseLink;
      if (this.courseFromUrl) {
        courseLink = this.courseFromUrl.demoLink;
      } else if (this.selectedVideo) {
        courseLink = this.selectedVideo.demoLink;
      } else if (this.course) {
        courseLink = this.course.demoLink;
      } else {
        return "";
      }
      const splittedCourseLink = courseLink.split("/");
      let videoId = splittedCourseLink[splittedCourseLink.length - 1];
      if (videoId) {
        videoId = videoId.replace(/ /g, "");
      }
      return videoId;
    },
    getCourseYoutubeId() {
      let courseLink;
      if (this.courseFromUrl) {
        courseLink = this.courseFromUrl.demoLink;
      } else if (this.selectedVideo) {
        courseLink = this.selectedVideo.link;
      } else if (this.course) {
        courseLink = this.course.link;
      } else {
        return "";
      }

      const splittedCourseLink = courseLink.split("/");
      let videoId = splittedCourseLink[splittedCourseLink.length - 1];
      if (videoId) {
        videoId = videoId.replace(/ /g, "");
      }
      return videoId;
    },

    moveToAuth() {
      this.$router.push({ name: "Auth", params: { previousPage: "Videos" } });
    },
    moveToSubscriptions() {
      if (this.selectedVideo && this.selectedVideo.forBeginners) {
        this.$router.push({ name: "TimeSubscriptionsForBeginners" });
      } else {
        this.$router.push({ name: "TimeSubscriptions" });
      }
    },
  },

  computed: {
    selectedVideo() {
      return this.$store.getters["videos/getSelectedVideo"];
    },
    timePackageItems() {
      let courses = this.$store.getters["timePackages/getTimePackageItems"];
      return courses;
    },
    hasActiveSubscription() {
      if (this.selectedVideo && this.selectedVideo.forBeginners) {
        return this.$store.getters["auth/getHasActiveSubscriptionForBeginners"];
      } else {
        return this.$store.getters["auth/getHasActiveSubscription"];
      }
    },
    activeSubscriptions() {
      if (this.selectedVideo && this.selectedVideo.forBeginners) {
        return this.$store.getters[
          "timePackages/getActiveSubscriptionsForBeginners"
        ];
      } else {
        return this.$store.getters["timePackages/getActiveSubscriptions"];
      }
    },
  },
};
</script>

<style scoped>
.card {
  width: 70vw;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 40px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px;
  display: block;
  position: relative;
  padding-top: 4rem;
}
.text-under {
  padding: 20px 30px;
  border-top: 1px solid lightgray;
}

.text-under .background {
  padding: 5px 40px;
  font-size: 1.25rem;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.3rem;
  font-weight: 400;
}

.no-access .background {
  padding: 8px 22px;
  width: 150px;
  border-radius: 30px;
  font-size: 1rem;
  margin: 15px;
  color: #fff;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  border-radius: 5px;
  text-align: center;
  transition: all 0.3s ease;
  font-weight: 500;
}
.no-access {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding-top: 3rem;
  height: 100%;
  min-height: 300px;
  max-height: 600px;
  width: 80%;
  margin: 0 auto;
  background-image: url("../assets/background-video.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.switch {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
}
.switch div {
  padding: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  font-size: 15px;
  cursor: pointer;
}
.switch .btn-gradient {
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(233, 230, 230);
  color: black;
}
.switch .btn-gradient:hover {
  transform: none;
}
.selectedSwitch {
  background: rgb(179, 106, 103) !important;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%) !important;
  color: #fff !important;
}
.viewsCounter {
  color: grey;
  font-size: 0.8rem;
  margin-right: 0.8rem;
}
.favorite-button {
  color: rgb(78, 74, 74);
  font-size: 0.9rem;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: right;
  text-decoration: underline;
  cursor: pointer;
}
.favorite-icon {
  margin-right: 8px;
  font-size: 0.9rem;
  cursor: pointer;
}

/* @media (max-width: 1024px) {

} */

@media screen and (max-width: 700px) {
  .card {
    width: 90vw;
  }
}
@media screen and (max-width: 550px) {
  .no-access h3 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 450px) {
  .no-access .btn-gradient {
    font-size: 0.8;
    width: 160px;
  }
  .no-access h3 {
    font-size: 1rem;
  }
  .card {
    width: 100vw;
  }
  .text-under {
    font-size: 0.8rem;
    padding: 20px 5px;
  }
}
@media screen and (max-width: 375px) {
  .no-access h3 {
    font-size: 0.8rem;
  }
}
</style>
