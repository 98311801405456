<template>
  <div class="return-button" :class="{ blackColor: blackColor }">
    <svg
      @click="changeRoute"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-arrow-left z-index2"
      :class="{ blackColor: blackColor }"
      v-if="!redirect"
    >
      <line x1="19" y1="12" x2="5" y2="12"></line>
      <polyline points="12 19 5 12 12 5"></polyline>
    </svg>
    <h2 @click="changeRoute" v-if="!redirect" class="z-index2">Powrót</h2>
    <div
      class="redirect-link"
      :class="{ blackColor: blackColor, whiteColor: whiteColor }"
      v-if="redirect"
    >
      <font-awesome-icon
        icon="home"
        class="mr-2"
        :class="{ blackColor: blackColor, whiteColor: whiteColor }"
      />
      <a
        :href="redirect"
        :class="{ blackColor: blackColor, whiteColor: whiteColor }"
      >
        <h2
          :class="{ blackColor: blackColor, whiteColor: whiteColor }"
          class="z-index2"
        >
          Strona główna
        </h2>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReturnBtn",
  props: {
    path: String,
    blackColor: Boolean,
    redirect: String,
    whiteColor: Boolean,
  },

  data() {
    return {
      lastRouteFromLocalStorage: "",
    };
  },

  created() {
    const lastRouteFromLS = window.localStorage.getItem("dagajogaLastRoute");
    if (lastRouteFromLS) {
      this.lastRouteFromLocalStorage = lastRouteFromLS;
    }
  },
  methods: {
    changeRoute() {
      if (this.path) {
        const pathToSet = `/${this.path}`.replace("//", "/");
        this.$router.push(pathToSet);
      } else if (this.previousPathFromLocalStore) {
        const pathToSet = `/${this.previousPathFromLocalStore}`.replace(
          "//",
          "/"
        );
        this.$router.push(pathToSet);
      }
    },
  },

  computed: {
    selectedSubCategory() {
      const subCategory =
        this.$store.getters["movieSubCategories/getSelectedMovieSubCategory"];
      if (subCategory) {
        return subCategory.movieSubcategory;
      } else {
        return null;
      }
    },

    previousPathFromLocalStore() {
      return this.$store.getters["auth/getLastRoute"];
    },
  },
};
</script>

<style scoped>
.return-button {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  margin: 30px 15px;
  cursor: pointer;
  color: #fff;
  z-index: 198;
}
.z-index2 {
  z-index: 1;
}
svg {
  color: #fff;
  margin: 5px;
}
.blackColor {
  color: #000;
}
.whiteColor {
  color: #fff;
}
.redirect-link,
.redirect-link a,
.redirect-link h2 {
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  text-decoration: underline;
}
</style>
