<template>
  <div class="line" :style="`${width ? `width: ${width}` : ``}`"></div>
</template>

<script>
export default {
  props: ["width"],

  data() {
    return {};
  },
};
</script>

<style scoped>
.line {
  background: rgba(209, 172, 172, 0.5);
  height: 1px;
  width: 100%;
  margin: 1rem 0;
}
</style>
