import axios from "../axios";
const packages24h = {
  namespaced: true,

  state: () => ({
    packages24hForPackage: [],
    selectedPackage: null,
    waitingUsers: [],
    activePackage: null,
    activePackageUsers: [],
    newPackage24hMember: null,
  }),

  mutations: {
    SET_SELECTED_PACKAGE(state, package24h) {
      state.selectedPackage = package24h;
    },
    SET_WAITING_USERS(state, users) {
      state.waitingUsers = users;
    },
    SET_ACTIVE_PACKAGE(state, package24h) {
      state.activePackage = package24h;
    },
    SET_PACKAGES_24H_FOR_PACKAGE(state, packages24h) {
      state.packages24hForPackage = packages24h;
    },
    SET_ACTIVE_PACKAGE_USERS(state, users) {
      state.activePackageUsers = users;
    },
    SET_NEW_PACKAGE_24H_MEMBER(state, newMember) {
      state.newPackage24hMember = newMember;
    },
  },

  actions: {
    fetchPackage24h: async ({ commit }, package24hId) => {
      try {
        const url = `management/packages24h/${package24hId}`;
        const response = await axios.get(url);
        commit("SET_SELECTED_PACKAGE", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_SELECTED_PACKAGE", null);
      }
    },

    fetchActivePackage24hForPackage: async ({ commit }, packageId) => {
      try {
        const url = `management/packages/${packageId}/packages24h/active`;
        const response = await axios.get(url);
        commit("SET_ACTIVE_PACKAGE", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_ACTIVE_PACKAGE", null);
      }
    },

    fetchListOfPackages24hForPackage: async ({ commit }, packageId) => {
      try {
        const url = `management/packages/${packageId}/packages24h`;
        const response = await axios.get(url);
        commit("SET_PACKAGES_24H_FOR_PACKAGE", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_PACKAGES_24H_FOR_PACKAGE", []);
      }
    },

    fetchUsersWaitingForPackage24h: async ({ commit }, packageId) => {
      try {
        const url = `management/packages/${packageId}/packages24h/waiting-users`;
        const response = await axios.get(url);
        commit("SET_WAITING_USERS", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_WAITING_USERS", []);
      }
    },

    addOrUpdatePackage24h: async ({ commit }, data) => {
      try {
        const { packageId, link } = data;
        const url = `management/packages/${packageId}/packages24h`;
        const response = await axios.put(url, { link });
        commit("SET_ACTIVE_PACKAGE", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_ACTIVE_PACKAGE", null);
      }
    },

    addPackage24hMember: async ({ commit }, data) => {
      try {
        const { packageId, email } = data;
        const url = `management/packages/${packageId}/packages24h/members`;
        const response = await axios.post(url, { email });

        commit("SET_NEW_PACKAGE_24H_MEMBER", response.data);
        return true;
      } catch (error) {
        console.log(error);
        commit("SET_NEW_PACKAGE_24H_MEMBER", null);
        return false;
      }
    },

    removePackage24hMember: async ({ commit }, data) => {
      try {
        const { packageId, package24hMemberId } = data;
        const url = `management/packages/${packageId}/packages24h/members/${package24hMemberId}`;
        const response = await axios.delete(url);
        commit("SET_NEW_PACKAGE_24H_MEMBER", response.data);
        return true;
      } catch (error) {
        console.log(error);
        commit("SET_NEW_PACKAGE_24H_MEMBER", null);
        return false;
      }
    },

    fetchUsersForPackage24h: async ({ commit }, packageId) => {
      try {
        const url = `management/packages/${packageId}/packages24h/active/users`;
        const response = await axios.get(url);
        commit("SET_ACTIVE_PACKAGE_USERS", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_ACTIVE_PACKAGE_USERS", []);
      }
    },
  },

  getters: {
    getSelectedPackage: (state) => state.selectedPackage,
    getWaitingUsers: (state) => state.waitingUsers,
    getActivePackage: (state) => state.activePackage,
    getPackages24hForPackage: (state) => state.packages24hForPackage,
    getActivePackageUsers: (state) => state.activePackageUsers,
  },
};

export default packages24h;
