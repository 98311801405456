<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Dostępne vouchery
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon icon="search" slot="prepend-inner" class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0"> Brak voucherów </v-alert>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editGiftCard(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              v-on:click="(isDeleteModalVisible = true), (currentItem = item)"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="htmlToFormattedText(item.description)"
            />
          </template>
        </v-tooltip>
      </template>
    </v-data-table>
    <AddGiftCardModal
      :isVisible="isModalVisible"
      :giftCard="giftCard"
      @closeModal="closeModal()"
      :type="'FOR-BEGINNERS'"
    />
    <BeforeDeleteModal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirm(currentItem.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddGiftCardModal from "../components/modals/AddGiftCardModal.vue";
import ManagementNav from "../components/ManagementNav.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";

export default {
  name: "GiftCardsForBeginnersManagement",
  components: {
    AddGiftCardModal,
    ManagementNav,
    BeforeDeleteModal,
  },
  created() {
    this.$store.dispatch("giftCard/fetchGiftCards", {
      type: "FOR-BEGINNERS",
    });
  },
  data: () => ({
    headers: [
      { text: "Nazwa", value: "name" },
      { text: "Zniżka", value: "discount" },
      { text: "Kod zniżkowy", value: "discountCode" },
      { text: "Opis", value: "description" },
      { text: "Cena", value: "price" },
      { text: "Ilość dni", value: "numberOfDays" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isDeleteModalVisible: false,
    giftCard: null,
    currentItem: null,
  }),
  computed: {
    items() {
      return this.$store.getters["giftCard/getAllGiftCards"];
    },
  },
  methods: {
    htmlToFormattedText(text) {
      const regex = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    editGiftCard(data) {
      this.giftCard = data;
    },
    deleteGiftCard(id) {
      this.$store.dispatch("giftCard/deleteGiftCard", {
        id,
        type: "FOR-BEGINNERS",
      });
    },
    confirm(id) {
      this.deleteGiftCard(id);
    },
    closeModal() {
      this.isModalVisible = false;
      this.giftCard = null;
      this.currentItem = null;
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.giftCard = null;
      this.currentItem = null;
    },
  },
};
</script>

<style></style>
