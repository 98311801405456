<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline">
              {{ label }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <VTextFieldWithValidation
                    type="text"
                    v-model="email"
                    label="E-mail"
                    rules="required"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn color="success" text :disabled="invalid" @click="save">
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";

export default {
  name: "AddEmailModal",
  props: ["isVisible", "label"],
  emits: ["confirm"],
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
  },

  mounted() {
    this.email = "";
  },
  data: () => ({
    email: "",
  }),
  methods: {
    save() {
      this.$emit("confirm", this.email);
      this.email = "";
    },
    closeModal() {
      this.email = "";
      this.$emit("closeModal");
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
