<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Platforma ogólna - link specjalny
      <v-spacer></v-spacer>
    </v-card-title>

    <v-row class="ml-1">
      <v-col cols="12">
        <v-row
          class="pb-0"
          v-for="(timePackageLink, index) in packagesLinksForGeneral"
          :key="`codeValue${index}`"
        >
          <v-col cols="12" sm="5">
            <v-text-field-with-validation
              v-model="timePackageLink.link"
              label="Link"
              color="indigo"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="5">
            <v-text-field-with-validation
              v-model="timePackageLink.name"
              label="Podpis"
              color="indigo"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-title>
      Platforma początek - link specjalny
      <v-spacer></v-spacer>
    </v-card-title>

    <v-row class="ml-1">
      <v-col cols="12">
        <v-row
          class="pb-0"
          v-for="(timePackageLink, index) in packagesLinksForBeginners"
          :key="`codeValue${index}`"
        >
          <v-col cols="12" sm="5">
            <v-text-field-with-validation
              v-model="timePackageLink.link"
              label="Link"
              color="indigo"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="5">
            <v-text-field-with-validation
              v-model="timePackageLink.name"
              label="Podpis"
              color="indigo"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-btn class="ml-4" style="width: 100%" @click="saveLinks" color="green"
      >Zapisz</v-btn
    >
  </v-card>
</template>

<script>
import VTextFieldWithValidation from "../components/inputs/VTextFieldWithValidation.vue";
import ManagementNav from "../components/ManagementNav.vue";
export default {
  name: "TimePackagesLinksManagement",
  components: {
    ManagementNav,
    VTextFieldWithValidation,
  },
  async created() {
    await this.$store.dispatch("timePackagesLinks/fetchTimePackagesLinks");
    this.packagesLinksForBeginners = this.timePackagesLinksForBeginners.map(
      (link) => {
        return {
          id: link.id,
          link: link.link,
          name: link.name,
        };
      }
    );
    this.packagesLinksForGeneral = this.timePackagesLinksForGeneral.map(
      (link) => {
        return {
          id: link.id,
          link: link.link,
          name: link.name,
        };
      }
    );
  },
  data: () => ({
    packagesLinksForBeginners: [],
    packagesLinksForGeneral: [],
  }),
  computed: {
    timePackagesLinksForBeginners() {
      return this.$store.getters[
        "timePackagesLinks/getTimePackagesLinksForBeginner"
      ];
    },
    timePackagesLinksForGeneral() {
      return this.$store.getters[
        "timePackagesLinks/getTimePackagesLinksForGeneral"
      ];
    },
  },

  methods: {
    async saveLinks() {
      for await (const packageLink of [
        ...this.packagesLinksForBeginners,
        ...this.packagesLinksForGeneral,
      ]) {
        const { id, link, name } = packageLink;

        await this.$store.dispatch("timePackagesLinks/updateTimePackageLink", {
          id,
          name,
          link,
        });
      }

      this.$store.dispatch("snackbar/showSnackbar", {
        color: "success",
        message: "Pomyślnie zaktuazlizowano linki",
      });
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
  cursor: pointer;
  object-fit: cover;
}
.image-container {
  position: relative;
}
.slider-prev,
.slider-next {
  position: absolute;
  top: 40%;
  font-weight: lighter;
  color: #000;
  background: rgb(255, 255, 255, 0.75);
  cursor: pointer;
  border-radius: 100px;
  padding: 0 5px;
  font-size: 20px;
}
.slider-prev {
  left: 1.3rem;
}
.slider-next {
  right: 1.3rem;
}
.delete-image-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
