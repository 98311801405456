<template>
  <div class="container">
    <div class="image-container">
      <img src="../../assets/shop-header.svg" />
      <p>Sklep</p>
    </div>

    <shop-products-list />
  </div>
</template>

<script>
import ShopProductsList from "../../components/shop/ShopProductsList.vue";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "ShopProducts",
  data: () => ({}),
  components: { ShopProductsList },
  props: {
    path: String,
  },

  async mounted() {},

  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  text-align: center;
}

.image-container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.image-container p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;

  width: 192px;
  height: 62px;

  font-weight: 700;
  font-size: 40px;
  line-height: 195.5%;

  /* or 78px */
  letter-spacing: 0.04em;

  color: #ffffff;
}

.container {
  min-height: 100vh;
}

@media (max-width: 768px) {
  .container {
    margin-top: 0;
  }
  .image-container p {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
