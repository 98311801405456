import axios from "../axios";

const theoryPracticeWorkshopsLessons = {
  namespaced: true,

  state: () => ({
    theoryPracticeWorkshopsLessonsList: [],
    lesson: null,
  }),

  mutations: {
    SET_THEORY_PRACTICE_WORKSHOPS_LESSONS(state, lessons) {
      state.theoryPracticeWorkshopsLessonsList = lessons;
    },
    SET_THEORY_PRACTICE_WORKSHOP_LESSON(state, lesson) {
      state.lesson = lesson;
    },
  },

  actions: {
    async fetchTheoryPracticeWorkshopsLesson({ commit }, id) {
      const url = `theoryPracticeWorkshopsLessons/${id}`;

      try {
        const response = await axios.get(url);
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", response.data);
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", null);
        console.log(err);
      }
    },

    async fetchTheoryPracticeWorkshopsLessons({ commit }, data) {
      const withNotActive = data?.withNotActive;
      const url = withNotActive
        ? `theoryPracticeWorkshopsLessons/withNotActive`
        : `theoryPracticeWorkshopsLessons`;

      const params = {};
      if (data.theoryPracticeWorkshopId) {
        params.theoryPracticeWorkshopId = data.theoryPracticeWorkshopId;
      }
      try {
        const response = await axios.get(url, { params });
        response.data.forEach((lesson) => {
          delete lesson.theories;
        });
        commit("SET_THEORY_PRACTICE_WORKSHOPS_LESSONS", response.data);
      } catch (err) {
        console.log(err);
        commit("SET_THEORY_PRACTICE_WORKSHOPS_LESSONS", []);
      }
    },

    async markTheoryPracticeWorkshopLessonAsActive({ commit, dispatch }, data) {
      try {
        const response = await axios.put(
          `theoryPracticeWorkshopsLessons/${data.id}/active`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessons", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", null);
        console.log(err);
      }
    },

    async markTheoryPracticeWorkshopLessonAsNotActive(
      { commit, dispatch },
      data
    ) {
      try {
        const response = await axios.put(
          `theoryPracticeWorkshopsLessons/${data.id}/notActive`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessons", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", null);
        console.log(err);
      }
    },

    async createTheoryPracticeWorkshopLesson({ commit, dispatch }, object) {
      const { data } = object;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.post(
          `theoryPracticeWorkshopsLessons`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessons", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", null);
        console.log(err);
      }
    },

    async editTheoryPracticeWorkshopLesson({ commit, dispatch }, object) {
      const { data, id } = object;
      const formData = new FormData();
      for (let key in data) {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }

      try {
        const response = await axios.put(
          `theoryPracticeWorkshopsLessons/${id}`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", response.data);
        await dispatch("fetchTheoryPracticeWorkshopsLessons", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", null);
        console.log(err);
      }
    },

    async deleteTheoryPracticeWorkshopLesson({ commit, dispatch }, data) {
      try {
        await axios.delete(`theoryPracticeWorkshopsLessons/${data.id}`);
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", true);
        await dispatch("fetchTheoryPracticeWorkshopsLessons", {
          withNotActive: true,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_LESSON", false);
        console.log(err);
      }
    },
  },

  getters: {
    getTheoryPracticeWorkshopsLessons: (state) =>
      state.theoryPracticeWorkshopsLessonsList,
    getTheoryPracticeWorkshopLesson: (state) => state.lesson,
  },
};
export default theoryPracticeWorkshopsLessons;
