import axios from "../axios";

const specialOffers = {
  namespaced: true,

  state: () => ({
    specialOffers: [],
    specialOffer: null,
    specialOfferDeleted: null,
    specialOffersItems: [],
    specialOffersItem: null,
    specialOfferItemDeleted: null,
    specialOfferIsPaid: false,
    newMemberAdded: false,
    specialOfferIsNotExpired: false,
    members: [],
  }),

  mutations: {
    SET_SPECIAL_OFFERS(state, specialOffers) {
      state.specialOffers = specialOffers;
    },
    SET_SPECIAL_OFFER(state, specialOffer) {
      state.specialOffer = specialOffer;
    },
    SET_SPECIAL_OFFER_DELETED(state, status) {
      state.specialOfferDeleted = status;
    },
    SET_SPECIAL_OFFERS_ITEMS(state, specialOffersItems) {
      state.specialOffersItems = specialOffersItems;
    },
    SET_SPECIAL_OFFER_ITEM(state, specialOfferItem) {
      state.specialOfferItem = specialOfferItem;
    },
    SET_SPECIAL_OFFER_ITEM_DELETED(state, status) {
      state.specialOfferItemDeleted = status;
    },
    SET_SPECIAL_OFFER_IS_PAID(state, status) {
      state.specialOfferIsPaid = status;
    },
    SET_SPECIAL_OFFER_IS_NOT_EXPIRED(state, status) {
      state.specialOfferIsNotExpired = status;
    },
    SET_NEW_MEMBER_ADDED(state, status) {
      state.newMemberAdded = status;
    },
    SET_MEMBERS(state, members) {
      state.members = members;
    },
  },

  actions: {
    async fetchSpecialOffer({ commit }, id) {
      try {
        const response = await axios.get(`specialOffers/${id}`);
        commit("SET_SPECIAL_OFFER", response.data);
      } catch (err) {
        commit("SET_SPECIAL_OFFER", null);
        console.log(err);
      }
    },
    async fetchSpecialOfferWithNotActive({ commit }, payload) {
      try {
        let params = {};
        if (payload) {
          const { type } = payload;
          if (type) {
            params.type = type;
          }
        }

        const response = await axios.get(`specialOffers/withNotActive`, {
          params,
        });
        commit("SET_SPECIAL_OFFERS", response.data);
      } catch (err) {
        commit("SET_SPECIAL_OFFERS", null);
        console.log(err);
      }
    },

    async createSpecialOffer({ commit }, object) {
      const { data } = object;

      try {
        const response = await axios.post(`specialOffers`, data);
        commit("SET_SPECIAL_OFFER", response.data);
        return response.data;
      } catch (err) {
        commit("SET_SPECIAL_OFFER", null);
        console.log(err);
      }
    },

    async editSpecialOffer({ commit }, object) {
      const { data, id } = object;

      try {
        const response = await axios.put(`specialOffers/${id}`, data);
        commit("SET_SPECIAL_OFFER", response.data);
        return response.data;
      } catch (err) {
        commit("SET_SPECIAL_OFFER", null);
        console.log(err);
      }
    },

    async editSpecialOfferEndDate({ commit }, object) {
      const { endDate, id } = object;

      try {
        const response = await axios.put(`specialOffers/${id}/endDate`, {
          endDate,
        });
        commit("SET_SPECIAL_OFFER", response.data);
      } catch (err) {
        commit("SET_SPECIAL_OFFER", null);
        console.log(err);
      }
    },

    async deleteSpecialOffer({ commit, dispatch }, data) {
      try {
        await axios.delete(`specialOffers/${data.id}`);
        commit("SET_SPECIAL_OFFER_DELETED", true);
        await dispatch("fetchSpecialOfferWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_SPECIAL_OFFER_DELETED", false);
        console.log(err);
      }
    },

    async deleteSpecialOfferItem({ commit, dispatch }, data) {
      try {
        await axios.delete(
          `specialOffers/${data.specialOfferId}/items/${data.id}`
        );
        commit("SET_SPECIAL_OFFER_ITEM_DELETED", true);
        await dispatch("fetchAllOffersItems", { type: data.type });
      } catch (err) {
        commit("SET_SPECIAL_OFFER_ITEM_DELETED", false);
        console.log(err);
      }
    },

    async markSpecialOfferAsActive({ commit, dispatch }, data) {
      try {
        const response = await axios.put(`specialOffers/${data.id}/active`);
        commit("SET_SPECIAL_OFFER", response.data);
        await dispatch("fetchSpecialOfferWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_SPECIAL_OFFER", null);
        console.log(err);
      }
    },

    async markSpecialOfferAsNotActive({ commit, dispatch }, data) {
      try {
        const response = await axios.put(`specialOffers/${data.id}/notActive`);
        commit("SET_SPECIAL_OFFER", response.data);
        await dispatch("fetchSpecialOfferWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_SPECIAL_OFFER", null);
        console.log(err);
      }
    },

    async fetchOffersItemsByOfferId({ commit }, id) {
      try {
        const response = await axios.get(`specialOffers/${id}/items`);
        commit("SET_SPECIAL_OFFERS_ITEMS", response.data);
      } catch (err) {
        commit("SET_SPECIAL_OFFERS_ITEMS", null);
        console.log(err);
      }
    },

    async fetchAllOffersItems({ commit }, payload) {
      try {
        let params = {};
        if (payload) {
          const { type } = payload;
          if (type) {
            params.type = type;
          }
        }
        const response = await axios.get(`specialOffers/items`, {
          params,
        });
        commit("SET_SPECIAL_OFFERS_ITEMS", response.data);
      } catch (err) {
        commit("SET_SPECIAL_OFFERS_ITEMS", null);
        console.log(err);
      }
    },

    async addSpecialOfferItem({ commit, dispatch }, object) {
      const { data, type } = object;
      const specialOfferId = data.specialOfferId;
      delete data.specialOfferId;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.post(
          `specialOffers/${specialOfferId}/items`,
          formData
        );
        commit("SET_SPECIAL_OFFER_ITEM", response.data);
        await dispatch("fetchAllOffersItems", { type });
      } catch (err) {
        commit("SET_SPECIAL_OFFER_ITEM", null);
        console.log(err);
      }
    },

    async updateSpecialOfferItem({ commit, dispatch }, object) {
      const { data, id, type } = object;
      const specialOfferId = data.specialOfferId;
      //delete data.specialOfferId
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      try {
        const response = await axios.put(
          `specialOffers/${specialOfferId}/items/${id}`,
          formData
        );
        commit("SET_SPECIAL_OFFER_ITEM", response.data);
        await dispatch("fetchAllOffersItems", { type });
      } catch (err) {
        commit("SET_SPECIAL_OFFER_ITEM", null);
        console.log(err);
      }
    },

    async fetchSpecialOfferItem({ commit }, id) {
      try {
        const response = await axios.get(`specialOffers/items/${id}`);
        commit("SET_SPECIAL_OFFER_ITEM", response.data);
        return response.data;
      } catch (err) {
        commit("SET_SPECIAL_OFFER_ITEM", null);
        console.log(err);
      }
    },

    async setSpecialOfferItemToNull({ commit }) {
      await commit("SET_SPECIAL_OFFER_ITEM", null);
    },

    async checkUserPaidForOffer({ commit, dispatch }, id) {
      try {
        const response = await axios.get(
          `specialOffers/${id}/checkUserPaidForOffer`
        );

        if (response && response.data) {
          commit("SET_SPECIAL_OFFER_IS_PAID", true);
          return true;
        } else {
          commit("SET_SPECIAL_OFFER_IS_PAID", false);
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Musisz być zalogowany oraz posiadać wykupiony dostęp do oferty specjalnej",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });

          return false;
        }
      } catch (err) {
        commit("SET_SPECIAL_OFFER_IS_PAID", false);
        console.log(err);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Musisz być zalogowany oraz posiadać wykupiony dostęp do oferty specjalnej",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return false;
      }
    },

    async checkSpecialOfferIsNotExpired({ commit, dispatch }, id) {
      try {
        const response = await axios.get(
          `specialOffers/${id}/checkSpecialOfferIsNotExpired`
        );

        if (response && response.data) {
          commit("SET_SPECIAL_OFFER_IS_NOT_EXPIRED", true);
          return true;
        } else {
          commit("SET_SPECIAL_OFFER_IS_NOT_EXPIRED", false);
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message: "Czas dostępu do oferty specjalnej minął",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });

          return false;
        }
      } catch (err) {
        commit("SET_SPECIAL_OFFER_IS_NOT_EXPIRED", false);
        console.log(err);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Czas dostępu do oferty specjalnej minął",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return false;
      }
    },

    async adminAddUserToSpecialOffer({ commit, dispatch }, data) {
      const { id, email, type } = data;

      try {
        await axios.post(`specialOffers/${id}/members/add`, { email });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Użytkownik został dodany do oferty specjalnej!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        commit("SET_NEW_MEMBER_ADDED", true);
        await dispatch("fetchSpecialOfferWithNotActive", { type });
        return true;
      } catch (error) {
        let errorMessage = "Spróbuj ponownie";
        if (
          error.response.data &&
          error.response.data.message &&
          error.response.data.message === "User must be registered"
        ) {
          errorMessage =
            "Użytkownik musi się najpierw zarejestrować, aby można było dodać go do oferty specjalnej";
        }
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorMessage,
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        console.log(error);
        commit("SET_NEW_MEMBER_ADDED", false);
        return false;
      }
    },

    async getOwnSpecialOffers({ commit }) {
      try {
        const url = `specialOffers/my`;
        const response = await axios.get(url);
        commit("SET_SPECIAL_OFFERS", response.data);
      } catch (error) {
        commit("SET_SPECIAL_OFFERS", []);
        console.log(error);
      }
    },

    async editSpecialOfferMemberEndDate({ commit }, data) {
      try {
        const response = await axios.put(
          `specialOffers/members/${data.memberId}/endDate`,
          {
            endDate: data.endDate,
          }
        );
        commit("SET_SPECIAL_OFFER", response.data);
      } catch (err) {
        commit("SET_SPECIAL_OFFER", null);
        console.log(err);
      }
    },
    async fetchSpecialOfferMembers({ commit }, id) {
      try {
        console.log({ id });
        const response = await axios.get(`specialOffers/${id}/members`);
        commit("SET_MEMBERS", response.data);
      } catch (err) {
        commit("SET_MEMBERS", []);
        console.log(err);
      }
    },
  },

  getters: {
    getSpecialOffers: (state) => state.specialOffers,
    getSpecialOffer: (state) => state.specialOffer,
    getSpecialOffersItems: (state) => state.specialOffersItems,
    getSpecialOfferItem: (state) => state.specialOfferItem,
    getSpecialOfferMembers: (state) => state.members,
  },
};
export default specialOffers;
