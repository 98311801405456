<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Lista uczestników </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="
                withoutExpirationDate
                  ? headersWithoutExpirationDate
                  : headersWithExpirationDate
              "
              :items="membersList"
              :items-per-page="30"
              class="elevation-1"
              :footer-props="{ 'items-per-page-options': [30, -1] }"
              :item-class="itemRowBackground"
            >
              <template v-slot:[`item.isPaid`]="{ item }">
                <template>
                  <p>
                    {{ item.isPaid ? "Tak" : "Nie" }}
                  </p>
                </template>
              </template>
              <template v-slot:[`item.expirationTime`]="{ item }">
                <template>
                  <p>
                    {{ formatDate(item.expirationTime) }}
                    <v-btn icon color="indigo" @click="editEndDate(item)">
                      <font-awesome-icon icon="edit" />
                    </v-btn>
                  </p>
                </template>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                <template>
                  <v-btn
                    icon
                    color="red"
                    @click="
                      (isDeleteModalVisible = true), (selectedMember = item)
                    "
                  >
                    <font-awesome-icon icon="times" />
                  </v-btn>
                </template>
              </template>
            </v-data-table>
            <before-delete-modal
              :isVisible="isDeleteModalVisible"
              @closeModal="closeDeleteModal()"
              @confirmDeletion="confirmDelete()"
            />
            <select-date-modal
              :isVisible="isSelectDateModalVisible"
              :currentDate="currentDate"
              @closeModal="closeSelectDateModal()"
              @dateSelected="handleEndDate"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text v-on:click="closeModal">
            <font-awesome-icon icon="times" class="mr-1" />
            Zamknij
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BeforeDeleteModal from "./BeforeDeleteModal.vue";
import axios from "../../axios";
import moment from "moment";
import SelectDateModal from "./SelectDateModal.vue";
export default {
  name: "SequencingWorkshopMembersModal",
  props: ["isVisible", "members", "withoutExpirationDate"],
  emits: ["memberDeleted", "memberEndDateUpdated"],
  components: { BeforeDeleteModal, SelectDateModal },

  data: () => ({
    selectedMember: null,
    isDeleteModalVisible: false,
    headersWithExpirationDate: [
      { text: "Email", value: "email" },
      { text: "Opłacono?", value: "isPaid" },
      { text: "Aktywne do", value: "expirationTime" },
      { text: "Usuwanie", value: "delete" },
    ],
    headersWithoutExpirationDate: [
      { text: "Email", value: "email" },
      { text: "Opłacono?", value: "isPaid" },
      { text: "Usuwanie", value: "delete" },
    ],
    deletedMembersIds: [],
    isSelectDateModalVisible: false,
    currentDate: null,
    updatedMembersDates: [],
  }),
  computed: {
    membersList() {
      let membersResponse = this.members;
      if (this.members) {
        membersResponse = this.members.filter(
          (m) => !this.deletedMembersIds.includes(m.id)
        );
      }
      if (this.updatedMembersDates.length > 0) {
        membersResponse = membersResponse.map((member) => {
          const updatedMember = this.updatedMembersDates.find(
            (m) => m.id === member.id
          );
          if (updatedMember) {
            member.expirationTime = updatedMember.expirationTime;
          }
          return member;
        });
      }
      return membersResponse;
    },
  },
  watch: {
    members() {
      this.deletedMembersIds = [];
    },
  },
  methods: {
    async handleEndDate(e, date) {
      await this.$store.dispatch(
        "sequencingWorkshops/editSequencingWorkshopMemberEndDate",
        {
          memberId: this.selectedMember?.id,
          endDate: moment(date).endOf("day").toDate(),
        }
      );
      if (
        this.updatedMembersDates.find((m) => m.id === this.selectedMember.id)
      ) {
        this.updatedMembersDates = this.updatedMembersDates.map((m) => {
          if (m.id === this.selectedMember.id) {
            m.expirationTime = moment(date).endOf("day").toDate();
          }
          return m;
        });
      } else {
        this.updatedMembersDates.push({
          id: this.selectedMember.id,
          expirationTime: moment(date).endOf("day").toDate(),
        });
      }
      this.$emit("memberEndDateUpdated");
      this.closeSelectDateModal();
      this.$store.dispatch("snackbar/showSnackbar", {
        color: "success",
        message: "Data wygaśnięcia została pomyślnie zaktualizowana",
      });
    },

    closeSelectDateModal() {
      this.isSelectDateModalVisible = false;
      this.currentDate = moment().format("YYYY-MM-DD");
    },
    editEndDate(member) {
      this.selectedMember = member;
      const endDate = member.expirationTime;
      this.isSelectDateModalVisible = true;
      this.currentDate = endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    },

    itemRowBackground: function (item) {
      return moment(item.expirationTime).isBefore(moment())
        ? "backgroundRed"
        : "";
    },
    formatDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-MM-DD HH:mm");
        return formattedDate;
      }
      return "";
    },
    async confirmDelete() {
      const { sequencingWorkshopId, id } = this.selectedMember;
      try {
        await axios.delete(
          `sequencingWorkshops/${sequencingWorkshopId}/members/${id}`
        );
        this.$emit("memberDeleted");
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "success",
          message: "Użytkownik został pomyślnie usunięty z warsztatów",
        });
        this.deletedMembersIds.push(id);
      } catch (e) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Nie udało się usunąć użytkownikaz warsztatów",
        });
      }
      this.closeDeleteModal();
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .backgroundRed,
::v-deep .backgroundRed:hover {
  background: rgb(236, 201, 201) !important;
}
</style>
