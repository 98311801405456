<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj voucher</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="number"
                    rules="required"
                    v-model.number="price"
                    label="Cena"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="number"
                    rules="required"
                    v-model.number="numberOfDays"
                    label="Liczba dni subskrypcji"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="number"
                    v-model.number="discount"
                    label="Wartość zniżki"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="discountCode"
                    label="Kod zniżkowy"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addGiftCard"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "AddGiftCardModal",
  props: ["isVisible", "giftCard", "type"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },

  data: () => ({
    giftCardId: null,
    price: 0,
    input: "",
    checkbox: false,
    discountError: false,
    updated: false,
    numberOfDays: 0,
    name: "",
    description: "",
    discount: 0,
    discountCode: "",
  }),
  updated() {
    if (this.giftCard) {
      const {
        id,
        price,
        numberOfDays,
        name,
        description,
        discount,
        discountCode,
      } = this.giftCard;
      this.giftCardId = id;
      this.price = price ? price : 0;
      this.updated = true;
      this.numberOfDays = numberOfDays;
      this.name = name;
      this.description = description;
      this.discount = discount ? discount : 0;
      this.discountCode = discountCode ? discountCode : "";
    }
  },
  methods: {
    closeModal() {
      this.price = 0;
      this.updated = false;
      this.description = "";
      this.numberOfDays = 0;
      this.name = "";
      this.giftCardId = null;
      this.discount = 0;
      this.discountCode = "";
      this.$emit("closeModal");
    },
    async addGiftCard() {
      if (this.discount >= this.price) {
        this.discountError = true;
        setTimeout(() => {
          this.discountError = false;
        }, 2000);
      } else {
        let object = {
          price: this.price,
          name: this.name,
          description: this.description,
          numberOfDays: this.numberOfDays,
        };

        if (this.discount !== 0 && this.discountCode !== "") {
          object.discount = this.discount;
          object.discountCode = this.discountCode;
        } else {
          object.discount = null;
          object.discountCode = null;
        }

        if (this.updated) {
          await this.$store
            .dispatch("giftCard/updateGiftCard", {
              giftCardId: this.giftCardId,
              data: object,
              type: this.type,
            })
            .then(() => {
              this.closeModal();
            });
        } else {
          await this.$store
            .dispatch("giftCard/addGiftCard", { data: object, type: this.type })
            .then(() => {
              this.closeModal();
            });
        }
      }
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
