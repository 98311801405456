<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ label }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" class="">
                <div class="line-content">
                  <div class="icon">
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.957 16.5141C11.957 15.3058 12.437 14.1469 13.2915 13.2925C14.1459 12.438 15.3048 11.958 16.5132 11.958H31.3176C31.9159 11.958 32.5084 12.0759 33.0611 12.3048C33.6139 12.5338 34.1162 12.8694 34.5392 13.2925C34.9623 13.7155 35.2979 14.2178 35.5269 14.7706C35.7559 15.3234 35.8737 15.9158 35.8737 16.5141V31.3185C35.8737 31.9169 35.7559 32.5093 35.5269 33.0621C35.2979 33.6149 34.9623 34.1171 34.5392 34.5402C34.1162 34.9633 33.6139 35.2989 33.0611 35.5279C32.5084 35.7568 31.9159 35.8747 31.3176 35.8747H16.5132C15.9148 35.8747 15.3224 35.7568 14.7696 35.5279C14.2168 35.2989 13.7146 34.9633 13.2915 34.5402C12.8684 34.1171 12.5328 33.6149 12.3038 33.0621C12.0749 32.5093 11.957 31.9169 11.957 31.3185V16.5141Z"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.85383 28.5924C6.32937 28.2944 5.89315 27.8629 5.5895 27.3417C5.28584 26.8205 5.12558 26.2282 5.125 25.625V8.54167C5.125 6.6625 6.6625 5.125 8.54167 5.125H25.625C26.9063 5.125 27.6033 5.78271 28.1875 6.83333"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div>
                    Skopiuj swój unikalny link, za pomocą którego Twoi znajomi
                    będą mogli zarejestrować się na platformie DagaJoga.
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" class="">
                <div class="line-content">
                  <div class="icon">
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.0013 5H5.0013C3.1513 5 1.66797 6.48333 1.66797 8.33333V28.3333C1.66797 29.2174 2.01916 30.0652 2.64428 30.6904C3.2694 31.3155 4.11725 31.6667 5.0013 31.6667H13.3346V35H26.668V31.6667H35.0013C36.8346 31.6667 38.318 30.1667 38.318 28.3333L38.3346 8.33333C38.3346 7.44928 37.9834 6.60143 37.3583 5.97631C36.7332 5.35119 35.8854 5 35.0013 5ZM35.0013 28.3333H5.0013V8.33333H35.0013V28.3333ZM26.668 18.3333L15.0013 25V11.6667L26.668 18.3333Z"
                        fill="black"
                      />
                    </svg>
                  </div>

                  <div>
                    Zaproszona osoba musi wykupić subskrypcję dowolnej Platformy
                    DagaJoga (Ogólna lub Początek).
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" class="">
                <div class="line-content">
                  <div class="icon">
                    <svg
                      width="41"
                      height="41"
                      viewBox="0 0 41 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.38131 25.3244C7.44998 27.506 8.26875 30.0524 10.2383 31.3784C14.0682 33.9665 18.2125 36.0477 22.5683 37.5702C24.8045 38.3562 27.3124 37.4805 28.4769 35.416C31.6265 29.84 34.4044 24.0588 36.7925 18.1105C37.036 17.5169 37.0884 16.8608 36.9426 16.2341C36.2837 13.4573 34.1863 5.52438 31.3864 4.11955C28.5864 2.71472 21.0475 5.80606 18.4541 6.95084C17.8704 7.21121 17.3831 7.64887 17.0602 8.20258C13.7937 13.7094 10.8944 19.4292 8.38131 25.3244Z"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.21633 16.6864C1.6399 18.4467 1.60689 21.103 3.05287 22.9733C5.86155 26.6193 9.12948 29.8873 12.7755 32.6959C14.6459 34.1419 17.3021 34.1089 19.0624 32.5325C23.8209 28.276 28.2923 23.7091 32.4471 18.8617C32.8666 18.3796 33.1249 17.7784 33.1859 17.1422C33.4436 14.3194 33.9764 6.18734 31.7682 3.98065C29.5599 1.77396 21.4294 2.3052 18.6066 2.56453C17.9704 2.62548 17.3693 2.88376 16.8871 3.30324C12.0398 7.45763 7.47284 11.9285 3.21633 16.6864Z"
                        fill="white"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M26.3615 7.0293C26.9868 7.0293 27.5864 7.27768 28.0285 7.71981C28.4707 8.16195 28.7191 8.76161 28.7191 9.38687C28.7191 10.0121 28.4707 10.6118 28.0285 11.0539C27.5864 11.4961 26.9868 11.7445 26.3615 11.7445C25.7362 11.7445 25.1366 11.4961 24.6944 11.0539C24.2523 10.6118 24.0039 10.0121 24.0039 9.38687C24.0039 8.76161 24.2523 8.16195 24.6944 7.71981C25.1366 7.27768 25.7362 7.0293 26.3615 7.0293Z"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.5275 18.6085C20.5621 17.9766 20.7381 16.8246 19.7794 15.9476M19.7794 15.9476C19.4136 15.6297 18.9876 15.3886 18.5267 15.2387C16.5511 14.5424 14.1259 16.8717 15.8422 19.0045C16.7648 20.1519 17.4768 20.504 17.4076 21.8053C17.3605 22.7201 16.4615 23.6773 15.2764 24.0419C14.2469 24.3594 13.1106 23.9397 12.3923 23.1366C11.5137 22.1558 11.6033 21.2317 11.5954 20.8293M19.7794 15.9476L20.8607 14.8662M12.4677 23.2592L11.4414 24.2855"
                        stroke="black"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div>
                    Za każdą osobę otrzymasz 10 zł rabatu na zakup dowolnej
                    subskrypcji Platformy. Rabaty sumują się!
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" class="">
                <b>Twój link:</b>
                {{ refLink }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text v-on:click="copyLink">
            <font-awesome-icon icon="copy" class="mr-1" />
            Kopiuj link
          </v-btn>
          <v-btn color="warning" text v-on:click="closeModal">
            <font-awesome-icon icon="times" class="mr-1" />
            Zamknij
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { FRONT_URL } from "../../axios";
export default {
  name: "RefLinksInfoModal",
  props: ["isVisible", "label", "userId"],

  data: () => ({}),
  methods: {
    closeModal() {
      this.email = "";
      this.$emit("closeModal");
    },
    copyLink() {
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(this.refLink);
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "success",
          message: "Skopiowano link do schowka",
        });
      } else {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "error",
          message: "Przeglądarka nie obsługuje kopiowania do schowka",
        });
      }

      return;
    },
  },
  computed: {
    refLink() {
      return `${FRONT_URL}/rejestracja?ref=${this.userId}`;
    },
  },
};
</script>
<style type="scss" scoped>
.headline {
  margin-bottom: 2rem;
}
.line-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 1rem;
}
.icon {
  min-width: 50px;
  width: 50px;
}
</style>
