<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Filmy przypisane do produktów w sklepie
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon icon="search" slot="prepend-inner" class="mt-1 mr-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak filmów
        </v-alert>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{}">
            <v-btn
              icon
              color="green"
              @click="editShopProductVideo(item)"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{}">
            <v-btn
              icon
              color="red"
              v-on:click="(isDeleteModalVisible = true), (currentItem = item)"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.photo`]="{ item }">
        <template>
          <img
            class="item-image"
            :id="item.imagePath"
            @click="editShopProductVideo(item)"
            v-on:click="isModalVisible = true"
            alt="
              joga zdrowy,
              filmy jogi,
              techniki oddechowe,
              praktyka wzmacniająca,
              joga online,
              praktyka dla początkujących,
              szkoła online,
              prana vyavama,
              joga wzmacniająca,
              joga video,
              ćwiczenia online,
              uważna praktyka,
              anatomia jogi
              "
            loading="lazy"
          />
        </template>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <template>
          <p :id="item.id" v-html="htmlToFormattedText(item.name)" />
        </template>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p :id="item.id" v-html="htmlToFormattedText(item.description)" />
        </template>
      </template>
    </v-data-table>
    <add-shop-product-video-modal
      :shopProductVideoData="shopProductVideoData"
      @closeModal="closeModal()"
      @updated="shopProductVideoUpdated"
      :isVisible="isModalVisible"
    />
    <BeforeDeleteModal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirm(currentItem.id, currentItem.shop_product.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import ManagementNav from "../components/ManagementNav.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import { SERVER_URL } from "../axios";
import AddShopProductVideoModal from "../components/modals/AddShopProductVideoModal.vue";

export default {
  name: "ShopProductsVideosManagement",
  components: {
    ManagementNav,
    BeforeDeleteModal,
    AddShopProductVideoModal,
  },

  async mounted() {
    await this.$store.dispatch("shopProducts/fetchAllShopProductsVideos");
    this.loadImages();
  },
  data: () => ({
    headers: [
      { text: "Nazwa", value: "name" },
      { text: "Opis", value: "description" },
      { text: "Link do video", value: "link" },
      { text: "Produkt", value: "shop_product.name" },
      { text: "Zdjęcie", value: "photo" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isDeleteModalVisible: false,
    shopProductVideoData: null,
    currentItem: null,
  }),
  computed: {
    items() {
      return this.$store.getters["shopProducts/getShopProductVideos"];
    },
  },

  watch: {
    search() {
      setTimeout(() => {
        this.loadImages();
      }, 10);
    },
  },
  methods: {
    htmlToFormattedText(text) {
      const regex = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    async shopProductVideoUpdated() {
      await this.$store.dispatch("shopProducts/fetchAllShopProductsVideos");
      this.loadImages();
    },
    async loadImages() {
      this.items.forEach(async (item) => {
        this.loadImage(item);
      });
    },

    async loadImage(shopProductVideo) {
      const element = document.getElementById(shopProductVideo.imagePath);
      if (element) {
        if (shopProductVideo.imagePath) {
          const extension = shopProductVideo.imagePath.split(".")[1];
          element.src = `${SERVER_URL}shopProducts/${
            shopProductVideo.id
          }.${extension}?${new Date().getTime()}`;
        }
      }
    },

    editShopProductVideo(data) {
      this.shopProductVideoData = data;
    },
    deleteShopProductVideo(shopProductVideoId, shopProductId) {
      this.$store.dispatch("shopProducts/deleteShopProductVideo", {
        shopProductVideoId,
        shopProductId,
      });
    },

    confirm(shopProductVideoId, shopProductId) {
      this.deleteShopProductVideo(shopProductVideoId, shopProductId);
    },

    closeModal() {
      this.isModalVisible = false;
      this.shopProductVideoData = null;
      this.currentItem = null;
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.shopProductVideoData = null;
      this.currentItem = null;
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
  object-fit: cover;
}
</style>
