<template>
  <div class="auth-container">
    <div
      class="left-side"
      v-if="
        currentBreakpointName && currentBreakpointName !== 'xs' && !isMobile
      "
    >
      <div class="logo_text">
        <img
          class="logo"
          src="../assets/dagajoga-logo.png"
          alt="
          uważna praktyka,
          pranajama oddech,
          Nauli,
          kręgosłup,
          subskrypcja DagaJoga
          szkoła online,
          filmy jogi,
          joga zdrowy,
          joga video,
          prana vyavama,
          praktyka
          "
          loading="lazy"
        />
      </div>
    </div>
    <div class="right-side" v-if="!forgotPass">
      <h1>Witam</h1>
      <div class="register-buttons">
        <a href="#" class="login" @click="clickOnLog"
          ><span class="logbtn" :class="{ active: isLogin }">Zaloguj się</span>
        </a>
        <span class="or">albo</span>
        <a href="#" class="register" @click="clickOnReg"
          ><span class="logbtn" :class="{ active: !isLogin }"
            >Zarejestruj się</span
          ></a
        >
      </div>

      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" autocomplete="none">
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <div class="flexit">
              <label for="email">E-mail</label>
              <input
                v-model.trim="formData.email"
                type="text"
                autocomplete="none"
                placeholder="Wpisz swój adres email"
                autofocus
              />
              <h5 v-if="errors[0]">Niepoprawny e-mail</h5>
            </div>
          </ValidationProvider>

          <ValidationProvider
            name="password"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="flexit">
              <label for="haslo">Hasło</label>
              <input
                v-model="formData.password"
                type="password"
                autocomplete="none"
                placeholder="Wpisz hasło"
              />
              <h5 v-if="errors[0]">To pole nie może być puste!</h5>
            </div>
          </ValidationProvider>

          <ValidationProvider
            v-if="!isLogin"
            name="confirm"
            rules="required|confirmed:password"
            v-slot="{ errors }"
          >
            <div class="flexit">
              <label for="confirm">Powtórz hasło</label>
              <input
                v-model="formData.confirm"
                type="password"
                autocomplete="none"
                placeholder="Powtórz hasło"
              />
              <h5 v-if="errors[0]">Niezgodność haseł</h5>
            </div>
          </ValidationProvider>
          <div class="buttons">
            <button type="submit" v-if="isLogin" class="background">
              Zaloguj się
            </button>

            <button type="submit" v-else class="background">
              Zarejestruj się
            </button>

            <p
              class="remind-password"
              v-if="isLogin"
              @click="forgotPass = true"
            >
              Przypomnij hasło
            </p>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <div class="right-side w-50" v-else>
      <h1>Zmień hasło</h1>
      <i class="fas fa-arrow-left fa-2x" @click="forgotPass = false"></i>

      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" autocomplete="none">
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <div class="flexit">
              <label for="email">E-mail</label>
              <input
                v-model="formData.email"
                type="text"
                autocomplete="none"
                placeholder="Wpisz swój adres email"
              />
              <h5 v-if="errors[0] && formData.email !== ''">
                Niepoprawny e-mail
              </h5>
            </div>
          </ValidationProvider>
          <button type="submit" v-if="isLogin" class="btn-background">
            Zresetuj hasło
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable no-useless-escape */
  metaInfo: {
    title: "Dagajoga logowanie - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "Auth",
  components: {},

  data() {
    return {
      isLogin: true,
      isMobile: false,
      forgotPass: false,
      formData: {
        email: "",
        password: "",
        confirm: "",
      },
    };
  },

  created() {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      this.isMobile = true;
    }

    if (
      (this.$route.params.signup && this.$route.params.signup === 1) ||
      this.$route.path === "/rejestracja"
    ) {
      this.isLogin = false;
    } else {
      this.$route.path === "/logowanie";
      this.isLogin = true;
    }
    const token = window.localStorage.getItem("userToken");
    if (token) {
      this.$router.push({ name: "Courses" });
    }
  },
  methods: {
    clickOnLog() {
      this.$router.push({ path: "/logowanie", params: { signup: 0 } });
      this.isLogin = true;
      this.formData.email = "";
      this.formData.confirm = "";
      this.formData.password = "";
    },
    clickOnReg() {
      this.$router.push({ path: "/rejestracja", params: { signup: 1 } });
      this.isLogin = false;
      this.formData.email = "";
      this.formData.confirm = "";
      this.formData.password = "";
    },
    async onSubmit() {
      if (this.forgotPass) {
        const email = this.formData.email;
        await this.$store.dispatch("auth/sendEmailToChangePassword", { email });
        this.formData.email = "";
        return;
      }
      if (this.isLogin) {
        const login = this.formData.email;
        const password = this.formData.password;

        this.$store.dispatch("auth/loginUser", { login, password }).then(() => {
          this.formData.password = "";
        });
      } else {
        const email = this.formData.email;
        const password = this.formData.password;
        const confirmPassword = this.formData.confirm;

        this.$store
          .dispatch("auth/registerUser", {
            email,
            password,
            confirmPassword,
            refUserId: this.$route.query.ref,
          })
          .then(() => {
            // this.formData.email = "";
            this.formData.password = "";
            this.formData.confirm = "";
          });
      }
    },
  },
  watch: {
    currentBreakpointName(breakpoint) {
      this.$store.dispatch("app/setScreenBreakpoint", breakpoint);
    },
    $route() {
      if (
        (this.$route.params.signup && this.$route.params.signup === 1) ||
        this.$route.path === "/rejestracja"
      ) {
        this.isLogin = false;
      } else {
        this.$route.path === "/logowanie";
        this.isLogin = true;
      }
    },
  },

  computed: {
    breakpointName() {
      return this.$store.getters["app/getScreenBreakpoint"];
    },
    currentBreakpointName() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "xs";
        case "sm":
          return "sm";
        case "md":
          return "md";
        case "lg":
          return "lg";
        case "xl":
          return "xl";
        default:
          return "lg";
      }
    },
  },
};
</script>

<style scoped>
/* CONTAINERS */
.auth-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  display: flex;
  overflow: hidden;
}
.left-side,
.right-side {
  width: 50%;
}
.right-side {
  background: #454e5b;
  display: flex;
  flex-direction: column;
  padding: 70px;
  color: white;
  padding-left: 100px;
}
.left-side {
  margin-bottom: 100px;
}
/* LEFT SIDE IMAGES SIZING */
.logo_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
}
.text {
  width: 50%;
  height: 100%;
}
.logo {
  width: 500px;
}
img {
  width: 200px;
}
.buttons {
  display: flex;
  position: relative;
  align-items: center;
}
.remind-password {
  opacity: 0.8;
  border-bottom: 1px solid #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 30px;
  margin-left: 2rem;
}
.remind-password:hover {
  opacity: 1;
}
/* HEADER */
h1 {
  font-size: 2.8rem;
  font-weight: 500;
}

/* FORM DISPLAY */
form {
  display: flex;
  flex-direction: column;
}
/* TOGGLE BUTTONS POSITIONING */
.register-buttons {
  display: flex;
  margin: 20px 0;
  padding: 20px 0;
  margin-bottom: 30px;
}
/* TOGGLE BUTTONS STYLING AND HOVERS */
.logbtn {
  color: white;
  font-size: 1.1rem;
  opacity: 0.7;
  transition: 0.5s all ease;
}
.logbtn:hover {
  opacity: 1;
}

.active {
  padding: 10px 0;
  font-weight: 500;
  opacity: 1;
  position: relative;
}
.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  height: 2px;
  border-radius: 10px;
}
a {
  text-decoration: none;
}
i {
  padding: 20px 0;
  cursor: pointer;
}

.or {
  padding: 0 40px;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* INPUT BRACKETS STYLING */
input {
  border-bottom: 2px solid #fff;
  color: white;
  width: 80%;
  margin-bottom: 30px;
  padding: 10px 0;
}
label {
  font-weight: 500;
  font-size: 1.1rem;
}
/* LOG IN/ REGISTER BUTTON STYLING */
.background {
  width: fit-content;
  padding: 8px 32px;
  border-radius: 30px;
  margin-top: 15px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
}

.btn-background {
  color: #fff;
  background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
  width: 100%;
  padding: 5px 0;
  border-radius: 10px;
  font-size: 1.2rem;
}

.flexit {
  display: flex;
  flex-direction: column;
}
h5 {
  background: rgb(179, 106, 103);
  background: linear-gradient(
    90deg,
    rgba(179, 106, 103, 1) 0%,
    rgba(223, 44, 38, 1) 32%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  margin-top: -25px;
  margin-bottom: 15px;
}
/* MEDIA QUERIES */

@media screen and (max-width: 1300px) {
  p {
    left: 55%;
  }
}

@media screen and (max-width: 950px) {
  .logo {
    width: 300px;
  }
  input {
    width: 100%;
  }
  .or {
    padding: 0 20px;
  }
  .right-side {
    padding: 70px 20px;
  }
}

@media screen and (max-width: 760px) {
  a {
    font-size: 0.9rem;
  }
  p {
    left: 65%;
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 615px) {
  .left-side,
  .right-side {
    width: 100%;
    min-height: 100vh;
  }
  .auth-container {
    flex-direction: column;
  }
  .left-side {
    margin-top: 200px;
  }

  .text-container {
    margin-top: 0px;
  }
  p {
    left: 40%;
    font-size: 1rem;
  }
}
@media screen and (max-width: 450px) {
  p {
    left: 55%;
  }
}
@media screen and (max-width: 350px) {
  p {
    left: 5%;
    top: 150%;
  }
}

@media screen and (max-width: 260px) {
  a {
    font-size: 0.7rem;
  }
  .left-side {
    margin-top: 200px;
  }
}
</style>
