<template>
  <v-row justify="center">
    <v-dialog max-width="700px" :value="isVisible" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Lista uczestników </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headers"
              :items="orders"
              :items-per-page="30"
              class="elevation-1"
              :footer-props="{ 'items-per-page-options': [30, -1] }"
            >
              <template v-slot:[`item.expirationTime`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      v-html="formatExpiryDate(item.expiration_time)"
                    />
                  </template>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text v-on:click="closeModal">
            <font-awesome-icon icon="times" class="mr-1" />
            Zamknij
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "TimePackageOrdersModal",
  props: ["isVisible", "orders"],

  components: {},

  data: () => ({
    headers: [
      { text: "Imię i nazwisko", value: "name" },
      { text: "Email", value: "email" },
      { text: "Data ważności", value: "expirationTime" },
    ],
  }),
  methods: {
    formatExpiryDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-DD-MM HH:mm:ss");
        return formattedDate;
      }
      return null;
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
