<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Produkty w sklepie
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon icon="search" slot="prepend-inner" class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak
        </v-alert>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <p style="text-align: center">{{ item.active ? "Tak" : "Nie" }}</p>
        <v-btn
          style="font-size: 12px; width: 100px"
          @click="markStatusAsActiveOrInactive(item)"
        >
          {{ item.active ? "Dezaktywuj" : "Aktywuj" }}
        </v-btn>
      </template>

      <template v-slot:[`item.members`]="{ item }">
        <v-btn @click="showMembers(item)">
          Pokaż ({{ item.members.length }})
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editShopProduct(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              v-on:click="(isDeleteModalVisible = true), (currentItem = item)"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              @click="copyShopProduct(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="copy" />
            </v-btn>
          </template>
          <span>Kopiuj</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p
            style="max-height: 200px; min-width: 300px; overflow: scroll"
            :id="item.id"
            v-html="htmlToFormattedText(item.description)"
          />
        </template>
      </template>

      <template v-slot:[`item.attachment`]="{ item }">
        <template v-if="item.type === 'PDF'">
          <template
            v-if="
              item.shop_products_attachments &&
                item.shop_products_attachments.length > 0 &&
                item.shop_products_attachments[0].path
            "
          >
            <span
              style="text-decoration: underline; color: #1976d2; cursor: pointer"
              @click="moveToAttachment(item.shop_products_attachments[0])"
              >Załącznik</span
            >
            <v-btn icon color="red" @click="deleteAttachment(item)">
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              @click="addAtachmentForShopProduct(item)"
              class="my-2 mx-2 submit-button"
            >
              Dodaj załącznik
            </v-btn>
          </template>
          <input
            :id="`attachments_input_${item.id}`"
            type="file"
            accept=".pdf"
            style="display: none"
            @change="handleNewAttachment($event, item)"
          />
        </template>

        <template v-else>
          Załączniki są dostępne tylko dla typu PDF
        </template>
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <template>
          <div
            class="image-container"
            v-if="productsVisibleImage && productsVisibleImage[item.id]"
          >
            <button class="slider-prev" @click="prevImage(item)">
              &#10094;
            </button>
            <button class="slider-next" @click="nextImage(item)">
              &#10095;
            </button>
            <v-btn
              icon
              color="red"
              @click="deleteImage(item)"
              class="delete-image-icon"
            >
              <font-awesome-icon icon="times" />
            </v-btn>

            <img
              v-if="productsVisibleImage && productsVisibleImage[item.id]"
              class="item-image"
              :id="`mainImage_${item.id}`"
              @click="addImages(item)"
              alt=""
              loading="lazy"
            />
          </div>
          <div v-else>
            <v-btn @click="addImages(item)" class="my-2 mx-2 submit-button">
              Dodaj zdjęcia
            </v-btn>
          </div>
          <input
            :id="`images_input_${item.id}`"
            type="file"
            multiple
            style="display: none"
            @change="handleNewImages($event, item)"
            accept="image/*"
          />
        </template>
      </template>
    </v-data-table>
    <add-shop-product-modal
      :isVisible="isModalVisible"
      :productData="shopProductData"
      @closeModal="closeModal()"
      @updated="refreshShopProducts()"
    />
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="deleteShopProduct(currentItem.id)"
    />

    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import ManagementNav from "../components/ManagementNav.vue";
import { SERVER_URL } from "../axios";
import AddShopProductModal from "../components/modals/AddShopProductModal.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
export default {
  name: "ShopProductsManagement",
  components: {
    ManagementNav,
    AddShopProductModal,
    BeforeDeleteModal,
  },
  async created() {
    await this.$store.dispatch("shopProducts/fetchAllProducts");
    this.loadImages();
  },
  data: () => ({
    headers: [
      { text: "Tytuł", value: "name" },
      { text: "Podtytuł", value: "subtitle" },
      { text: "Opis", value: "description" },
      { text: "Cena", value: "price" },
      { text: "Typ", value: "type" },
      { text: "Liczba sztuk", value: "stockAmount" },
      { text: "Link do filmów", value: "linkForVideos" },
      { text: "Zdjęcia", value: "image" },
      { text: "Załącznik", value: "attachment" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isShopProductMembersModalVisible: false,
    shopProductData: null,
    shopProductMembers: [],
    currentDate: null,
    isSelectDateModalVisible: false,
    productToEdit: "",
    productsVisibleImage: {},
    isDeleteModalVisible: false,
    currentItem: null,
  }),
  computed: {
    items() {
      const products = this.$store.getters["shopProducts/getShopProducts"];
      products.forEach((product) => {
        this.productsVisibleImage[product.id] =
          product.shop_products_images &&
          Array.isArray(product.shop_products_images)
            ? product.shop_products_images.find((p) => p.isMain) ||
              product.shop_products_images[0]
            : null;
      });
      return products;
    },
  },

  watch: {
    productsVisibleImage(productsVisibleImages) {
      for (const [key, value] of Object.entries(productsVisibleImages)) {
        this.setImageElement(value, productsVisibleImages[key]);
      }
    },
  },
  methods: {
    addAtachmentForShopProduct(product) {
      this.productToEdit = product.id;
      const selectAttachmentInput = document.getElementById(
        `attachments_input_${product.id}`
      );
      selectAttachmentInput.click();
    },
    async deleteAttachment(product) {
      await this.$store.dispatch(
        "shopProducts/removeAttachmentFromShopProduct",
        {
          shopProductId: product.id,
        }
      );
      await this.$store.dispatch("shopProducts/fetchAllProducts");
    },
    async handleNewAttachment(event, product) {
      const selectedFile = event.target.files;
      await this.$store.dispatch("shopProducts/addAttachmentToShopProduct", {
        file: selectedFile,
        shopProductId: product.id,
      });
      await this.$store.dispatch("shopProducts/fetchAllProducts");
    },
    async refreshShopProducts() {
      await this.loadImages();
    },
    addImages(product) {
      this.productToEdit = product.id;
      const selectImagesInput = document.getElementById(
        `images_input_${product.id}`
      );
      selectImagesInput.click();
    },

    async handleNewImages(event, product) {
      let filesAreCorrect = true;
      const selectedFiles = event.target.files;
      let filesArray = selectedFiles || [];
      if (
        typeof filesArray === "object" &&
        selectedFiles &&
        selectedFiles.item
      ) {
        filesArray = [];
        for (let i = 0; i < selectedFiles.length; i++) {
          let file = selectedFiles.item(i);
          filesArray.push(file);
        }
      }

      filesArray.forEach((file) => {
        if (!file.type.includes("image")) {
          filesAreCorrect = false;
        }
      });
      if (!filesAreCorrect) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message:
            "Niepoprawny format pliku. Dozwolone formaty to: jpg, jpeg, png",
        });
        return;
      }
      await this.$store.dispatch("shopProducts/updateShopProduct", {
        id: product.id,
        data: { files: selectedFiles },
      });
      await this.loadImages();
    },
    async deleteImage(product) {
      const currentViewImage = this.productsVisibleImage[product.id];
      await this.$store.dispatch("shopProducts/removeShopProductImage", {
        shopProductImageId: currentViewImage.id,
      });
      await this.loadImages();
    },
    prevImage(product) {
      const currentViewImage = this.productsVisibleImage[product.id];
      const currentImageIndex = product.shop_products_images.findIndex(
        (image) => image.id === currentViewImage.id
      );
      if (product.shop_products_images[currentImageIndex - 1]) {
        this.productsVisibleImage[product.id] =
          product.shop_products_images[currentImageIndex - 1];
      } else {
        this.productsVisibleImage[product.id] =
          product.shop_products_images[product.shop_products_images.length - 1];
      }
      this.setImageElement(this.productsVisibleImage[product.id], product);
    },
    nextImage(product) {
      const currentViewImage = this.productsVisibleImage[product.id];
      const currentImageIndex = product.shop_products_images.findIndex(
        (image) => image.id === currentViewImage.id
      );
      if (product.shop_products_images[currentImageIndex + 1]) {
        this.productsVisibleImage[product.id] =
          product.shop_products_images[currentImageIndex + 1];
      } else {
        this.productsVisibleImage[product.id] = product.shop_products_images[0];
      }
      this.setImageElement(this.productsVisibleImage[product.id], product);
    },

    setImageElement(image, product) {
      const element = document.getElementById(`mainImage_${product.id}`);
      if (element && image) {
        const splittedPath = image.path.split("/");
        const fileName = splittedPath[splittedPath.length - 1];
        element.src = `${SERVER_URL}shopProducts/${fileName}?${new Date().toTimeString()}`;
      }
    },

    async loadImages() {
      this.items.forEach(async (item) => {
        this.loadImage(item);
      });
    },

    async loadImage(product) {
      const element = document.getElementById(`mainImage_${product.id}`);
      if (element) {
        if (
          product &&
          product.shop_products_images &&
          Array.isArray(product.shop_products_images)
        ) {
          const mainImage = product.shop_products_images.find(
            (image) => image.isMain
          );
          const firstImage = product.shop_products_images[0];

          if ((element && mainImage) || firstImage) {
            const splittedPath = mainImage
              ? mainImage.path.split("/")
              : firstImage.path.split("/");
            const fileName = splittedPath[splittedPath.length - 1];
            element.src = `${SERVER_URL}shopProducts/${fileName}?${new Date().getTime()}`;
          }
        }
      }
    },

    moveToAttachment(attachment) {
      if (attachment && attachment.path) {
        const splittedPath = attachment.path.split("/");
        const fileName = splittedPath[splittedPath.length - 1];
        const url = `${SERVER_URL}shopProducts/${fileName}`;
        window.open(url, "_blank");
        return url;
      } else {
        return "";
      }
    },

    copyShopProduct(data) {
      this.shopProductData = { ...data };
      this.shopProductData.copy = true;
      this.shopProductData.id = null;
    },
    showMembers(item) {
      this.shopProductMembers = item.members;
      this.isShopProductMembersModalVisible = true;
    },
    htmlToFormattedText(text) {
      const regex = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    editShopProduct(data) {
      this.shopProductData = data;
    },
    async deleteShopProduct(shopProductId) {
      await this.$store.dispatch("shopProducts/removeShopProduct", {
        shopProductId,
      });
    },

    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.currentItem = null;
    },

    closeModal() {
      this.isModalVisible = false;
      this.shopProductData = null;
    },
    closeShopProductMembersModal() {
      this.isShopProductMembersModalVisible = false;
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
  cursor: pointer;
  object-fit: cover;
}
.image-container {
  position: relative;
}
.slider-prev,
.slider-next {
  position: absolute;
  top: 40%;
  font-weight: lighter;
  color: #000;
  background: rgb(255, 255, 255, 0.75);
  cursor: pointer;
  border-radius: 100px;
  padding: 0 5px;
  font-size: 20px;
}
.slider-prev {
  left: 1.3rem;
}
.slider-next {
  right: 1.3rem;
}
.delete-image-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
