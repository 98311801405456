import axios from "../axios";
const comments = {
  namespaced: true,

  state: () => ({
    comments: [],
    subComments: [],
  }),
  mutations: {
    SET_COMMENTS(state, comments) {
      state.comments = comments;
    },
  },
  actions: {
    async addComment({ dispatch }, object) {
      try {
        const obj = {
          text: object.comment,
        };
        const response = await axios.post(`comments/${object.id}`, obj);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return response.data.comment;
      } catch (error) {
        console.log(error.response);
        let message = error?.response?.data?.errors[0];
        if (
          error?.response?.data?.errors[0] === "text is not allowed to be empty"
        ) {
          message = "Komentarz nie może być pusty";
        }
        dispatch(
          "snackbar/showSnackbar",
          { color: "red", message },
          { root: true }
        );
      }
    },

    async addCommentForLesson({ dispatch }, object) {
      try {
        const obj = {
          text: object.comment,
          isTheory: object.isTheory,
        };
        const response = await axios.post(
          `theoryPracticeWorkshopsLessons/${object.id}/comments`,
          obj
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return response.data.comment;
      } catch (error) {
        console.log(error.response);
        let message = error?.response?.data?.errors[0];
        if (
          error?.response?.data?.errors[0] === "text is not allowed to be empty"
        ) {
          message = "Komentarz nie może być pusty";
        }
        dispatch(
          "snackbar/showSnackbar",
          { color: "red", message },
          { root: true }
        );
      }
    },

    async addCommentForSpecialOfferItem({ dispatch }, object) {
      try {
        const obj = {
          text: object.comment,
          isTheory: object.isTheory,
          isSpecialOfferItem: object.isSpecialOfferItem,
        };
        const response = await axios.post(
          `specialOffers/items/${object.id}/comments`,
          obj
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return response.data.comment;
      } catch (error) {
        console.log(error.response);
        let message = error?.response?.data?.errors[0];
        if (
          error?.response?.data?.errors[0] === "text is not allowed to be empty"
        ) {
          message = "Komentarz nie może być pusty";
        }
        dispatch(
          "snackbar/showSnackbar",
          { color: "red", message },
          { root: true }
        );
      }
    },

    async addSubComment({ dispatch }, object) {
      try {
        const response = await axios.post(`comments/${object.id}/subComments`, {
          text: object.text,
        });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return response.data.subComment;
      } catch (error) {
        let message = error?.response?.data?.errors[0];
        if (
          error?.response?.data?.errors[0] === "text is not allowed to be empty"
        ) {
          message = "Komentarz nie może być pusty";
        }
        dispatch(
          "snackbar/showSnackbar",
          { color: "red", message },
          { root: true }
        );
        return false;
      }
    },

    async addCommentImage({ dispatch }, data) {
      try {
        const formData = new FormData();
        const filesArray = [...data.files];
        filesArray.forEach((file) => {
          formData.append("files", file);
        });

        await axios({
          method: "POST",
          url: `comments/${data.commentID}/image`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
          },
          { root: true }
        );
        console.log(error.response);
      }
    },
    async fetchAllByTimePackageID({ commit }, id) {
      try {
        const response = await axios.get(`comments/timePackageItems/${id}`);
        commit("SET_COMMENTS", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchAllByLessonId({ commit }, data) {
      const { id, userId, isTheory } = data;
      const params = { isTheory };
      if (userId) {
        params.userId = userId;
      }
      try {
        const response = await axios.get(
          `theoryPracticeWorkshopsLessons/${id}/comments`,
          { params }
        );
        commit("SET_COMMENTS", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchAllBySpecialOfferItemId({ commit }, data) {
      const { id, userId, isTheory, isSpecialOfferItem } = data;
      const params = { isTheory, isSpecialOfferItem };
      if (userId) {
        params.userId = userId;
      }
      try {
        const response = await axios.get(`specialOffers/items/${id}/comments`, {
          params,
        });
        commit("SET_COMMENTS", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCommentSubImages({ dispatch }, id) {
      try {
        const response = await axios.get(`comments/${id}/image`);
        if (response.data !== "") {
          return response.request.responseURL;
        } else return null;
      } catch (error) {
        dispatch();
        console.log(error);
      }
    },
    async fetchSubcommentsForComments({ dispatch }, data) {
      try {
        const response = await axios.get(`comments/${data.id}/subComments`);
        return response.data;
      } catch (error) {
        dispatch();
        console.log(error);
      }
    },
    async deleteComment({ dispatch }, object) {
      try {
        const { id } = object;
        const response = await axios.delete(`comments/${id}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto komentarz!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return response.data.comment;
      } catch (error) {
        console.log(error.response);
        let message = error?.response?.data?.errors[0];
        dispatch(
          "snackbar/showSnackbar",
          { color: "red", message },
          { root: true }
        );
      }
    },
    async deleteSubComment({ dispatch }, object) {
      try {
        const { subCommentId } = object;
        const response = await axios.delete(
          `comments/subComments/${subCommentId}`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto komentarz!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return response.data.comment;
      } catch (error) {
        console.log(error.response);
        let message = error?.response?.data?.errors[0];
        dispatch(
          "snackbar/showSnackbar",
          { color: "red", message },
          { root: true }
        );
      }
    },
  },
  getters: {
    getComments: (state) => state.comments,
  },
};
export default comments;
