<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline">Produkt w sklepie</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Tytuł"
                    color="indigo"
                    outlined
                    dense
                    maxLength="30"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="subtitle"
                    label="Podtytuł"
                    color="indigo"
                    outlined
                    dense
                    maxLength="30"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="type"
                    label="Typ"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                    placeholder="STANDARD/PDF"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="price"
                    label="Cena"
                    color="indigo"
                    outlined
                    dense
                    :onlyNumbers="true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="stockAmount"
                    label="Liczba sztuk"
                    color="indigo"
                    outlined
                    dense
                    :onlyNumbers="true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <!-- <VTextAreaWithValidation
                    type="text"
                    rules="required"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="8024"
                  /> -->

                  <html-editor
                    @contentchanged="handleHtmlEditorContentForCategory($event)"
                    :startContent="
                      productData && productData.description
                        ? productData.description
                        : ''
                    "
                    :maxLength="8000"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-file-input
                    multiple
                    v-model="files"
                    label="Zdjęcie"
                    accept="image/*"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addProductItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
//import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";
import HtmlEditor from "../HtmlEditor.vue";

export default {
  name: "AddShopProductModal",
  props: ["isVisible", "productData"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    // VTextAreaWithValidation,
    HtmlEditor,
  },

  data: () => ({
    productId: null,
    input: "",
    discountError: false,
    updated: false,
    name: "",
    subtitle: "",
    type: "STANDARD",
    description: "",
    files: null,
    stockAmount: 10,
    price: null,
    availableTypes: ["STANDARD", "PDF"],
  }),
  updated() {
    if (this.productData) {
      const { id, name, subtitle, type, description, stockAmount, price } =
        this.productData;
      this.productItemId = id;
      this.updated = this.productItemId ? true : false;
      this.name = name;
      this.subtitle = subtitle;
      this.type = type;
      this.description = description;
      this.stockAmount = stockAmount;
      this.price = price;
    }
  },

  async created() {},

  methods: {
    handleHtmlEditorContentForCategory(content) {
      this.description = content;
    },
    closeModal() {
      this.updated = false;
      this.name = "";
      this.subtitle = "";
      this.type = "STANDARD";
      this.description = "";
      this.files = null;
      this.stockAmount = 10;
      this.price = null;
      this.$emit("closeModal");
    },
    async addProductItem() {
      let filesAreCorrect = true;
      const selectedFiles = this.files;
      if (selectedFiles && Array.isArray(selectedFiles)) {
        selectedFiles.forEach((file) => {
          if (!file.type.includes("image")) {
            filesAreCorrect = false;
          }
        });
      }

      if (!filesAreCorrect) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message:
            "Niepoprawny format pliku. Dozwolone formaty to: jpg, jpeg, png",
        });
        return;
      }

      if (this.type) {
        this.type = this.type.toUpperCase();
      }

      if (this.description && this.description.length > 8000) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "error",
            icon: "check",
            message: "Maksymalna liczba znaków w opisie to 8000",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }

      if (!this.type || !this.availableTypes.includes(this.type)) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "error",
          message: `Nieprawidłowy typ produktu. Dostępne typy produktu to ${this.availableTypes.join(
            "/"
          )}`,
        });
        return;
      }

      let object = {
        name: this.name,
        subtitle: this.subtitle,
        description: this.description,
        type: this.type,
        files: this.files,
        stockAmount: this.stockAmount,
        price: this.price,
      };
      if (this.updated) {
        await this.$store
          .dispatch("shopProducts/updateShopProduct", {
            id: this.productItemId,
            data: object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      } else {
        await this.$store
          .dispatch("shopProducts/addShopProduct", {
            data: object,
          })
          .then(() => {
            this.closeModal();
            this.$emit("updated");
          });
      }
    },
  },

  computed: {},
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
