import axios from "../axios";
import router from "../router";
import * as Sentry from "@sentry/vue";
const initValues = {
  p24_merchant_id: "",
  p24_pos_id: "",
  p24_session_id: "",
  p24_amount: "",
  p24_currency: "",
  p24_description: "",
  p24_email: "",
  p24_country: "",
  p24_url_return: "",
  p24_url_status: "",
  p24_api_version: "",
  p24_sign: "",
};

const payment = {
  namespaced: true,
  state: () => ({
    transaction_message: null,
    transaction_error: null,
    transaction_info: initValues,
    transaction_show: false,
    create_order: false,
  }),

  mutations: {
    CREATE_ORDER(state, success) {
      state.create_order = success;
    },
    SET_TRANS_INFO(state, response) {
      const { message, error, data, show } = response;
      state.transaction_message = message;
      state.transaction_error = error;
      state.transaction_info = data;
      state.transaction_show = show;
    },
    SET_INIT_VALUES(state) {
      state.transaction_message = null;
      state.transaction_error = null;
      state.transaction_info = initValues;
      state.transaction_show = false;
    },
  },

  actions: {
    async createOrder({ commit, dispatch }, data) {
      const {
        phone,
        name,
        email,
        discountCode,
        packageId,
        newsletter,
        companyName,
        nip,
        address,
        invoiceForCompany,
        recording,
      } = data;
      return axios
        .post(`management/order/package/${packageId}`, {
          name,
          email,
          phone,
          email_confirmation: email,
          userDiscountCode: discountCode,
          newsletter,
          address,
          companyName,
          nip,
          invoiceForCompany,
          recording,
        })
        .then(function(response) {
          const { data } = response;
          if (recording) {
            dispatch("startTransactionForPackage24h", data.id);
          } else {
            dispatch("startTransaction", data.id);
          }

          commit("CREATE_ORDER", true);
        })
        .catch((error) => {
          console.log(error);
          commit("CREATE_ORDER", false);
          Sentry.captureException(error);
        });
    },
    async createTimePackageOrder({ commit, dispatch }, object) {
      const {
        email,
        confirmEmail,
        phone,
        newsletter,
        name,
        timePackageId,
        companyName,
        nip,
        address,
        invoiceForCompany,
        discountCode,
        isExtendedSubscription,
        useRefLinksAmount,
      } = object;

      let url = isExtendedSubscription
        ? `order/timePackagesExtended/${timePackageId}`
        : `order/timePackages/${timePackageId}`;
      return axios
        .post(url, {
          email,
          email_confirmation: confirmEmail,
          phone,
          newsletter,
          name,
          companyName,
          nip,
          address,
          invoiceForCompany,
          userDiscountCode: discountCode,
          useRefLinksAmount,
        })
        .then(function(response) {
          if (response && response.data && response.data.id) {
            dispatch("startTimePackageTransaction", response.data.id);
            commit("CREATE_ORDER", true);
          } else {
            commit("CREATE_ORDER", false);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          commit("CREATE_ORDER", false);

          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              console.log(error);
              if (error === "Confirm email does not match") {
                dispatch(
                  "snackbar/setSnackbar",
                  {
                    color: "red",
                    icon: "exclamation-triangle",
                    message: "Adresy email nie mogą się różnić",
                  },
                  { root: true }
                );
                dispatch("snackbar/toggleSnackbar", true, { root: true });
              } else {
                dispatch(
                  "snackbar/setSnackbar",
                  {
                    color: "red",
                    icon: "exclamation-triangle",
                    message:
                      "Nie udało się rozpocząć płatności do zamówienia. Spróbuj ponownie.",
                  },
                  { root: true }
                );
                dispatch("snackbar/toggleSnackbar", true, { root: true });
                Sentry.captureException(error);
                throw new Error(error);
              }
            });
          } else if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message === "Invalid discount code"
          ) {
            dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message: "Niepoprawny kod rabatowy",
              },
              { root: true }
            );
            dispatch("snackbar/toggleSnackbar", true, { root: true });
          } else if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message === "No places available"
          ) {
            dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message:
                  "Na te warsztaty zapisała się już maksymalna liczba użytkowników",
              },
              { root: true }
            );
            dispatch("snackbar/toggleSnackbar", true, { root: true });
          } else {
            dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message:
                  "Nie udało się rozpocząć płatności do zamówienia. Spróbuj ponownie.",
              },
              { root: true }
            );
            dispatch("snackbar/toggleSnackbar", true, { root: true });
            Sentry.captureException(error);
          }
        });
    },

    async createUserGiftCardOrder({ dispatch }, object) {
      const {
        email,
        confirmEmail,
        newsletter,
        giftCardId,
        giftRecipientEmail,
        name,
        companyName,
        nip,
        address,
        invoiceForCompany,
        discountCode,
      } = object;

      return axios
        .post(`giftCards/order/${giftCardId}`, {
          email,
          email_confirmation: confirmEmail,
          newsletter,
          giftRecipientEmail,
          name,
          companyName,
          nip,
          address,
          invoiceForCompany,
          userDiscountCode: discountCode,
        })
        .then(function(response) {
          dispatch("startGiftCardTransaction", response.data.id);
        })
        .catch((error) => {
          if (error.response && error.response.data.errors) {
            error.response.data.errors.forEach((error) => {
              if (error === "Confirm email does not match") {
                dispatch(
                  "snackbar/setSnackbar",
                  {
                    color: "red",
                    icon: "exclamation-triangle",
                    message: "Adresy email nie mogą się różnić",
                  },
                  { root: true }
                );
                dispatch("snackbar/toggleSnackbar", true, { root: true });
              } else {
                Sentry.captureException(error);
              }
            });
          } else {
            Sentry.captureException(error);
          }
        });
    },

    async startGiftCardTransaction({ commit }, userGiftCardId) {
      return axios
        .post(`userGiftCards/start-transaction/${userGiftCardId}`)
        .then(function(response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch((error) => {
          console.log(error);
          commit("SET_TRANS_INFO", {
            data: null,
            error: true,
            message: error.response,
            show: false,
          });
          Sentry.captureException(error);
        });
    },

    async startTimePackageTransaction({ commit }, orderId) {
      return axios
        .post(`start-time-package-transaction/${orderId}`)
        .then(function(response) {
          const { data } = response;
          //data.p24_url_return="https://web.dagajoga.pl/user/status-platnosci"
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch((error) => {
          console.log(error);
          commit("SET_TRANS_INFO", {
            data: null,
            error: true,
            message: error.response,
            show: false,
          });
          Sentry.captureException(error);
        });
    },

    async handleStartedTimePackageTransaction(_, timePackageOrderId) {
      return await axios.post(
        `started-time-package-transaction/${timePackageOrderId}`
      );
    },

    async startTransaction({ commit }, orderId) {
      return axios
        .post(`management/start-transaction/${orderId}`)
        .then(function(response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch(function(error) {
          commit("SET_TRANS_INFO", {
            data: null,
            error: true,
            message: error.response,
            show: false,
          });
          Sentry.captureException(error);
        });
    },

    async startTransactionForPackage24h({ commit }, orderId) {
      return axios
        .post(`management/start-transaction-package-24h/${orderId}`)
        .then(function(response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch(function(error) {
          commit("SET_TRANS_INFO", {
            data: null,
            error: true,
            message: error.response,
            show: false,
          });
          Sentry.captureException(error);
        });
    },

    // async createShopOrder({ commit }, data) {
    //   const { shopProductId, amount, addressToSend, email, invoiceFullName, nip, invoiceAddress, bucket } = data.bucket
    //   const url = `shopProducts/buy`
    //   await axios.post(url)
    // },

    async startTransactionForSequencingOrder({ commit, dispatch }, data) {
      const { orderId, type } = data;
      return axios
        .post(`sequencingWorkshops/start-transaction/${orderId}`)
        .then(function(response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch(function(error) {
          if (
            error?.response?.data?.message ===
            "Not found member for this workshop with given email"
          ) {
            dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message:
                  "Aby dokonać płatności najpierw musisz zapisać się na kurs",
              },
              { root: true }
            );
            dispatch("snackbar/toggleSnackbar", true, { root: true });
            if (type && type === "PRICE_TARIFF") {
              router.push({
                name: "TariffWorkshopSignup",
                query: { id: router.currentRoute.params.id },
              });
            } else {
              router.push({
                name: "SequencingWorkshopSignup",
                query: { id: router.currentRoute.params.id },
              });
            }
          } else {
            commit("SET_TRANS_INFO", {
              data: null,
              error: true,
              message: error.response,
              show: false,
            });
            Sentry.captureException(error);
          }
        });
    },

    async startTransactionForSpecialOffer({ commit }, data) {
      const { orderId } = data;
      return axios
        .post(`specialOffers/start-transaction/${orderId}`)
        .then(function(response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch(function(error) {
          commit("SET_TRANS_INFO", {
            data: null,
            error: true,
            message: error.response,
            show: false,
          });
          Sentry.captureException(error);
        });
    },

    async startTransactionForTheoryPracticeWorkshopOrder(
      { commit, dispatch },
      data
    ) {
      const { orderId } = data;
      return axios
        .post(`theoryPracticeWorkshops/start-transaction/${orderId}`)
        .then(function(response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch(function(error) {
          if (
            error?.response?.data?.message ===
            "Not found member for this workshop with given email"
          ) {
            dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message:
                  "Aby dokonać płatności najpierw musisz zapisać się na kurs",
              },
              { root: true }
            );
            dispatch("snackbar/toggleSnackbar", true, { root: true });
            router.push({
              name: "TheoryPracticeWorkshopSignup",
              query: { id: router.currentRoute.params.id },
            });
          } else {
            commit("SET_TRANS_INFO", {
              data: null,
              error: true,
              message: error.response,
              show: false,
            });
            Sentry.captureException(error);
          }
        });
    },

    async createSequencingWorkshopOrder({ commit, dispatch }, data) {
      const {
        phone,
        name,
        email,
        sequencingWorkshopId,
        newsletter,
        companyName,
        nip,
        address,
        invoiceForCompany,
        type,
        discountCode,
        isExtension,
        extensionCode,
      } = data;
      return axios
        .post(`sequencingWorkshops/${sequencingWorkshopId}/order`, {
          name,
          email,
          phone,
          email_confirmation: email,
          newsletter,
          address,
          companyName,
          nip,
          invoiceForCompany,
          userDiscountCode: discountCode,
          isExtension,
          extensionCode,
        })
        .then(function(response) {
          const { data } = response;
          dispatch("startTransactionForSequencingOrder", {
            orderId: data.id,
            type,
          });
          commit("CREATE_ORDER", true);
        })
        .catch((error) => {
          if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message === "Invalid discount code"
          ) {
            dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message: "Niepoprawny kod rabatowy",
              },
              { root: true }
            );
            dispatch("snackbar/toggleSnackbar", true, { root: true });
          } else {
            console.log(error);
            commit("CREATE_ORDER", false);
            Sentry.captureException(error);
          }
        });
    },

    async createSpecialOfferOrder({ commit, dispatch }, data) {
      const {
        phone,
        name,
        email,
        specialOfferId,
        newsletter,
        companyName,
        nip,
        address,
        invoiceForCompany,
        type,
        discountCode,
      } = data;
      return axios
        .post(`specialOffers/${specialOfferId}/order`, {
          name,
          email,
          phone,
          email_confirmation: email,
          newsletter,
          address,
          companyName,
          nip,
          invoiceForCompany,
          userDiscountCode: discountCode,
        })
        .then(function(response) {
          const { data } = response;
          dispatch("startTransactionForSpecialOffer", {
            orderId: data.id,
            type,
          });
          commit("CREATE_ORDER", true);
        })
        .catch((error) => {
          if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message === "Invalid discount code"
          ) {
            dispatch(
              "snackbar/setSnackbar",
              {
                color: "red",
                icon: "exclamation-triangle",
                message: "Niepoprawny kod rabatowy",
              },
              { root: true }
            );
            dispatch("snackbar/toggleSnackbar", true, { root: true });
          } else {
            console.log(error);
            commit("CREATE_ORDER", false);
            Sentry.captureException(error);
          }
        });
    },

    async createTheoryPracticeWorkshopOrder({ commit, dispatch }, data) {
      const {
        phone,
        name,
        email,
        theoryPracticeWorkshopId,
        newsletter,
        companyName,
        nip,
        address,
        invoiceForCompany,
      } = data;
      return axios
        .post(`theoryPracticeWorkshops/${theoryPracticeWorkshopId}/order`, {
          name,
          email,
          phone,
          email_confirmation: email,
          newsletter,
          address,
          companyName,
          nip,
          invoiceForCompany,
        })
        .then(function(response) {
          const { data } = response;
          dispatch("startTransactionForTheoryPracticeWorkshopOrder", {
            orderId: data.id,
          });
          commit("CREATE_ORDER", true);
        })
        .catch((error) => {
          console.log(error);
          commit("CREATE_ORDER", false);
          Sentry.captureException(error);
        });
    },

    async createShopProductsOrderAndStartPayment({ commit }, object) {
      return axios
        .post(`shopProducts/buy`, object)
        .then(function(response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
          return true;
        })
        .catch((error) => {
          console.log(error);
          commit("SET_TRANS_INFO", {
            data: null,
            error: true,
            message: error.response.data,
            show: false,
          });
          //Sentry.captureException(error);
          return false;
        });
    },

    async startTransactionForCoursesTicket({ commit }, orderId) {
      return axios
        .post(`courseTickets/start-transaction/${orderId}`)
        .then(function(response) {
          const { data } = response;
          commit("SET_TRANS_INFO", {
            data,
            error: false,
            message: null,
            show: true,
          });
        })
        .catch(function(error) {
          commit("SET_TRANS_INFO", {
            data: null,
            error: true,
            message: error.response,
            show: false,
          });
          Sentry.captureException(error);
        });
    },
  },

  getters: {
    getTransInfo: (state) => {
      return {
        message: state.transaction_message,
        error: state.transaction_error,
        data: state.transaction_info,
        show: state.transaction_show,
      };
    },
  },
};
export default payment;
