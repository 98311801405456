<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Anatomia w praktyce - praktyka do lekcji
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0"> Brak lekcji </v-alert>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <p style="text-align: center">{{ item.active ? "Tak" : "Nie" }}</p>
        <v-btn
          style="font-size: 12px; width: 100px"
          @click="markStatusAsActiveOrInactive(item)"
        >
          {{ item.active ? "Dezaktywuj" : "Aktywuj" }}
        </v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editLesson(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="key++, (isModalVisible = true)"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              @click="(isDeleteModalVisible = true), (packageData = item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              @click="copyPractice(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="copy" />
            </v-btn>
          </template>
          <span>Kopiuj</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p
            style="max-height: 200px; min-width: 300px; overflow: hidden"
            :id="item.id"
            v-html="htmlToFormattedText(item.description)"
          />
        </template>
      </template>
      <template v-slot:[`item.lesson`]="{ item }">
        <p>
          {{
            `${item.theoryPracticeWorkshopsLesson.name} - ${item.theoryPracticeWorkshopsLesson.theoryPracticeWorkshop.name}`
          }}
        </p></template
      >

      <template v-slot:[`item.photo`]="{ item }">
        <template>
          <img
            class="item-image"
            :id="`image_${item.id}`"
            @click="editLesson(item)"
            v-on:click="isModalVisible = true"
            alt=""
            loading="lazy"
          />
        </template>
      </template>
    </v-data-table>
    <add-theory-practice-workshop-lesson-practice-modal
      :isVisible="isModalVisible"
      :lessonItemData="lessonData"
      @closeModal="closeModal()"
      :keyHandle="key"
    />
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirmDelete(packageData.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      @click="key++, (isModalVisible = true)"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import ManagementNav from "../components/ManagementNav.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import AddTheoryPracticeWorkshopLessonPracticeModal from "../components/modals/AddTheoryPracticeWorkshopLessonPracticeModal.vue";
export default {
  name: "TheoryPracticeWorkshopsLessonsPracticesManagement",
  components: {
    ManagementNav,
    BeforeDeleteModal,
    AddTheoryPracticeWorkshopLessonPracticeModal,
  },
  async created() {
    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessonsPractices/fetchTheoryPracticeWorkshopsLessonsPractices"
    );
    await this.$store.dispatch(
      "theoryPracticeWorkshopsLessons/fetchTheoryPracticeWorkshopsLessons",
      { withNotActive: true }
    );
  },
  data: () => ({
    headers: [
      { text: "Tytuł", value: "name" },
      { text: "Opis", value: "description" },
      { text: "Lekcja", value: "lesson" },
      { text: "Link Youtube", value: "link" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isWorkshopMembersModalVisible: false,
    lessonData: null,
    workshopMembers: [],
    currentDate: null,
    isSelectDateModalVisible: false,
    workshopsIdToEdit: "",
    isDeleteModalVisible: false,
    key: 1,
  }),
  computed: {
    items() {
      return this.$store.getters[
        "theoryPracticeWorkshopsLessonsPractices/getTheoryPracticeWorkshopsLessonsPractices"
      ];
    },
  },
  methods: {
    confirmDelete(id) {
      this.deletePackage(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageData = null;
    },

    copyPractice(data) {
      const { id, ...rest } = data;
      this.lessonData = rest;
      this.lessonData.id = null;
      this.lessonData.copy = true;
      return id;
    },
    showMembers(item) {
      this.workshopMembers = item.members;
      this.isWorkshopMembersModalVisible = true;
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    editLesson(data) {
      this.lessonData = data;
    },
    async deletePackage(id) {
      await this.$store.dispatch(
        "theoryPracticeWorkshopsLessonsPractices/deleteTheoryPracticeWorkshopLessonPractice",
        { id }
      );
    },
    closeModal() {
      this.isModalVisible = false;
      this.lessonData = null;
    },
    closeWorkshopMembersModal() {
      this.isWorkshopMembersModalVisible = false;
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
  object-fit: cover;
}
</style>
