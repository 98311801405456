<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Dostępne warsztaty z taryfami
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak warsztatów z taryfami
        </v-alert>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <p style="text-align: center">{{ item.active ? "Tak" : "Nie" }}</p>
        <v-btn
          style="font-size: 12px; width: 100px"
          @click="markStatusAsActiveOrInactive(item)"
        >
          {{ item.active ? "Dezaktywuj" : "Aktywuj" }}
        </v-btn>
      </template>

      <template v-slot:[`item.members`]="{ item }">
        <div class="d-flex flex-column">
          <v-btn @click="showMembers(item)">
            Pokaż ({{ item.members.length }})
          </v-btn>

          <v-btn @click="showAddEmailModal(item.id)" class="mt-4">
            Dodaj
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editWorkshop(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              @click="(isDeleteModalVisible = true), (packageData = item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              @click="copyWorkshop(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="copy" />
            </v-btn>
          </template>
          <span>Kopiuj</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p
            style="max-height: 200px; min-width: 300px; overflow: scroll"
            :id="item.id"
            v-html="htmlToFormattedText(item.description)"
          />
        </template>
      </template>

      <template v-slot:[`item.photo`]="{ item }">
        <template>
          <img
            class="item-image"
            :id="`sequencingWorkshopImage_${item.id}`"
            @click="editWorkshop(item)"
            v-on:click="isModalVisible = true"
            alt="
              joga zdrowy,
              filmy jogi,
              techniki oddechowe,
              praktyka wzmacniająca,
              joga online,
              praktyka dla początkujących,
              szkoła online,
              prana vyavama,
              joga wzmacniająca,
              joga video,
              ćwiczenia online,
              uważna praktyka,
              anatomia jogi
              "
            loading="lazy"
          />
        </template>
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        <template v-if="item.discountCodes && item.discountCodes.length > 0">
          <p v-for="discountCode in item.discountCodes" :key="discountCode.id">
            <b>{{ discountCode.code }}</b> ({{
              `${(discountCode.value * 100).toFixed()}%`
            }})
          </p>
        </template>
        <template v-else> Brak </template>
      </template>
    </v-data-table>
    <AddTariffWorkshopModal
      :isVisible="isModalVisible"
      :workshopItemData="workshopData"
      @closeModal="closeModal()"
      @updated="refreshWorkshops()"
    />
    <SequencingWorkshopMembersModal
      :isVisible="isWorkshopMembersModalVisible"
      :members="workshopMembers"
      @closeModal="closeWorkshopMembersModal()"
      @memberDeleted="refreshWorkshops()"
    />
    <AddEmailModal
      :label="'Dodaj użytkownika do warsztatów'"
      @confirm="addMemberToSequencingWorkshop"
      @closeModal="closeAddEmailModal()"
      :isVisible="addEmailModalVisible"
    />
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirmDelete(packageData.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddTariffWorkshopModal from "../components/modals/AddTariffWorkshopModal";
import ManagementNav from "../components/ManagementNav.vue";
import SequencingWorkshopMembersModal from "../components/modals/SequencingWorkshopMembersModal";
import { SERVER_URL } from "../axios";
import AddEmailModal from "../components/modals/AddEmailModal.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
export default {
  name: "WorkshopsManagement",
  components: {
    AddTariffWorkshopModal,
    ManagementNav,
    SequencingWorkshopMembersModal,
    AddEmailModal,
    BeforeDeleteModal,
  },
  async created() {
    await this.$store.dispatch(
      "sequencingWorkshops/fetchSequencingWorkshopWithNotActive",
      { type: "PRICE_TARIFF" }
    );
    this.loadImages();
  },
  data: () => ({
    headers: [
      { text: "Aktywny?", value: "active" },
      { text: "Nazwa warsztatu", value: "name" },
      { text: "Zniżki", value: "discount" },
      { text: "Liczba dni dostępu", value: "numberOfDays" },
      { text: "WhatsApp", value: "whatsapp" },
      { text: "Zoom", value: "zoom" },
      { text: "Link do płatności", value: "paymentLink" },
      { text: "Link do zapisów", value: "signupLink" },
      { text: "Link do kursów", value: "coursesLink" },
      { text: "Lista uczestników", value: "members" },
      { text: "Opis warsztatu", value: "description" },
      { text: "Cena", value: "price" },
      { text: "Cena przy przedłużaniu", value: "priceWhenExtending" },
      { text: "Zdjęcie", value: "photo" },
      { text: "Maksymalna liczba uczestników", value: "maxNumberOfMembers" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isWorkshopMembersModalVisible: false,
    workshopData: null,
    workshopMembers: [],
    currentDate: null,
    isSelectDateModalVisible: false,
    workshopsIdToEdit: "",
    addEmailModalVisible: false,
    isDeleteModalVisible: false,
  }),
  computed: {
    items() {
      return this.$store.getters["sequencingWorkshops/getSequencingWorkshops"];
    },
  },
  methods: {
    confirmDelete(id) {
      this.deletePackage(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageData = null;
    },
    showAddEmailModal(id) {
      this.workshopsIdToEdit = id;
      this.addEmailModalVisible = true;
    },
    async addMemberToSequencingWorkshop(email) {
      const response = await this.$store.dispatch(
        "sequencingWorkshops/adminAddUserToSequencingWorkshop",
        {
          id: this.workshopsIdToEdit,
          email,
          type: "PRICE_TARIFF",
        }
      );
      if (response) {
        this.closeAddEmailModal();
      }
    },
    async loadImages() {
      this.items.forEach(async (item) => {
        this.loadImage(item);
      });
    },

    async loadImage(item) {
      const element = document.getElementById(
        `sequencingWorkshopImage_${item.id}`
      );
      if (element) {
        if (item.imagePath) {
          const extension = item.imagePath?.split(".")[1];

          element.src = `${SERVER_URL}sequencingWorkshop_${
            item.id
          }.${extension}?d=${new Date().getTime()}`;
        }
      }
    },

    async markStatusAsActiveOrInactive(item) {
      if (!item.active) {
        await this.$store.dispatch(
          "sequencingWorkshops/markSequencingWorkshopAsActive",
          { id: item.id, type: "PRICE_TARIFF" }
        );
      } else {
        await this.$store.dispatch(
          "sequencingWorkshops/markSequencingWorkshopAsNotActive",
          { id: item.id, type: "PRICE_TARIFF" }
        );
      }
    },

    async refreshWorkshops() {
      await this.$store.dispatch(
        "sequencingWorkshops/fetchSequencingWorkshopWithNotActive",
        { type: "PRICE_TARIFF" }
      );
      this.loadImages();
    },
    copyWorkshop(data) {
      this.workshopData = data;
      this.workshopData.id = null;
      this.workshopData.copy = true;
    },
    showMembers(item) {
      this.workshopMembers = item.members;
      this.isWorkshopMembersModalVisible = true;
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    editWorkshop(data) {
      this.workshopData = data;
    },
    async deletePackage(id) {
      await this.$store.dispatch(
        "sequencingWorkshops/deleteSequencingWorkshop",
        { id, type: "PRICE_TARIFF" }
      );
    },
    closeModal() {
      this.isModalVisible = false;
      this.workshopData = null;
    },
    closeWorkshopMembersModal() {
      this.isWorkshopMembersModalVisible = false;
    },
    closeAddEmailModal() {
      this.addEmailModalVisible = false;
      this.workshopsIdToEdit = "";
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
}
</style>
