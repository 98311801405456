<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj kurs w subskrypcji czasowej </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    rules="required"
                    v-model="name"
                    label="Nazwa"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    type="text"
                    rules="required"
                    v-model="description"
                    label="Opis"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="keywords"
                    label="Słowa kluczowe"
                    color="indigo"
                    outlined
                    dense
                    maxLength="2048"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="link"
                    rules="required|link"
                    label="Link YouTube"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="demoLink"
                    label="Link Demo YouTube"
                    color="indigo"
                    outlined
                    dense
                    maxLength="1024"
                    rules="link"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-file-input
                    v-model="file"
                    label="Zdjęcie kursu"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-select
                    v-model="movieCategoryId"
                    :items="['Brak', ...movieCategories]"
                    item-value="value"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Kategoria"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-select
                    v-model="movieSubCategoryId"
                    :items="['Brak', ...allMovieSubCategories]"
                    item-value="value"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Podkategoria"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <v-select
                    v-model="movieSubCategoryL2Id"
                    :items="['Brak', ...allMovieSubCategoriesL2]"
                    item-value="value"
                    item-text="name"
                    outlined
                    color="indigo"
                    dense
                    label="Podkategoria nr 2"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addPackageItem"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "AddTimePackageItemModal",
  props: ["isVisible", "packageItemData", "type", "isExtendedPackage"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },

  data: () => ({
    packageItemId: null,
    input: "",
    discountError: false,
    updated: false,
    name: "",
    description: "",
    link: "",
    file: null,
    demoLink: "",
    movieCategoryId: null,
    movieSubCategoryId: null,
    movieSubCategoryL2Id: null,
    keywords: "",
  }),
  updated() {
    if (this.packageItemData) {
      const {
        id,
        link,
        name,
        description,
        imagePath,
        demoLink,
        movieCategoryId,
        movieSubcategory,
        movieSubcategoryL2,
        keywords,
      } = this.packageItemData;
      this.packageItemId = id;
      this.updated = true;
      this.name = name;
      this.link = link;
      this.description = description;
      this.imagePath = imagePath;
      this.demoLink = demoLink;
      this.movieCategoryId = movieCategoryId;
      this.movieSubCategoryId = movieSubcategory ? movieSubcategory.id : null;
      this.movieSubCategoryL2Id = movieSubcategoryL2
        ? movieSubcategoryL2.id
        : null;
      this.keywords = keywords;
    }
  },

  async created() {
    if (this.type) {
      await this.$store.dispatch("movieCategories/fetchAll", {
        type: this.type,
      });

      await this.$store.dispatch("movieSubCategories/fetchAll", {
        forBeginners: this.type === "FOR-BEGINNERS" ? true : false,
        forExtendedSubscription: this.type === "EXTENDED" ? true : false,
      });
      await this.$store.dispatch("movieSubCategories/fetchAllSubcategoriesL2", {
        forBeginners: this.type === "FOR-BEGINNERS" ? true : false,
        forExtendedSubscription: this.type === "EXTENDED" ? true : false,
      });
    } else {
      await this.$store.dispatch("movieCategories/fetchAll");
      await this.$store.dispatch("movieSubCategories/fetchAll");
      await this.$store.dispatch("movieSubCategories/fetchAllSubcategoriesL2");
    }
  },

  methods: {
    closeModal() {
      this.updated = false;
      this.name = "";
      this.description = "";
      this.link = "";
      this.file = null;
      this.demoLink = "";
      this.movieCategoryId = null;
      this.movieSubCategoryId = null;
      this.movieSubCategoryL2Id = null;
      this.keywords = "";
      this.$emit("closeModal");
    },
    async addPackageItem() {
      let object = {
        name: this.name,
        description: this.description,
        link: this.link,
        file: this.file,
        demoLink: this.demoLink,
        movieCategoryId: this.movieCategoryId,
        movieSubCategoryId: this.movieSubCategoryId,
        type: this.type,
        movieSubCategoryL2Id: this.movieSubCategoryL2Id,
        keywords: this.keywords,
      };
      if (!this.isExtendedPackage) {
        if (this.updated) {
          await this.$store
            .dispatch("timePackages/updateTimePackageItem", {
              packageItemId: this.packageItemId,
              type: this.type,
              data: object,
            })
            .then(() => {
              this.closeModal();
              this.$emit("updated");
            });
        } else {
          await this.$store
            .dispatch("timePackages/addTimePackageItem", {
              data: object,
              type: this.type,
            })
            .then(() => {
              this.closeModal();
              this.$emit("updated");
            });
        }
      } else {
        if (this.updated) {
          await this.$store
            .dispatch("timePackages/updateTimePackageItemExtended", {
              packageItemId: this.packageItemId,
              type: this.type,
              data: object,
            })
            .then(() => {
              this.closeModal();
              this.$emit("updated");
            });
        } else {
          await this.$store
            .dispatch("timePackages/addTimePackageItemExtended", {
              data: object,
              type: this.type,
            })
            .then(() => {
              this.closeModal();
              this.$emit("updated");
            });
        }
      }
    },
  },

  computed: {
    movieCategories() {
      let categories =
        this.$store.getters["movieCategories/getMovieCategories"];
      let items = categories.map((category) => {
        return {
          name: category.categoryName,
          value: category.id,
        };
      });

      return items;
    },

    allMovieSubCategories() {
      let movieCategories =
        this.$store.getters["movieSubCategories/getMovieSubCategories"];
      let items = movieCategories.map((c) => {
        return {
          name: c.name,
          value: c.id,
        };
      });
      return items;
    },

    allMovieSubCategoriesL2() {
      let movieCategories =
        this.$store.getters["movieSubCategories/getMovieSubCategoriesL2"];
      let items = movieCategories.map((c) => {
        return {
          name: c.name,
          value: c.id,
        };
      });
      return items;
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
