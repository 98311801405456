// KEYWORDS
// kurs jogi online, ćwiczenia online, joga video, filmy jogi
// joga, jogaonline, praktyka wzmacniająca, techniki oddechowe,
// Nauli, joga wzmacniająca, pranajama, oddech, uważna praktyka,
// joga balanse, joga stanie na rękach, joga zdrowy, prana vyavama,
// kręgosłup, praktyka, praktyka dla początkujących, szkoła online,
// subskrypcja DagaJoga, anatomia jogi.

export const isProduction = false;
export const SENTRY_DSN =
  "https://b914dcafab5b421a9ab811334e07d843@o1092060.ingest.sentry.io/6119214";
