<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Lista uczestników </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headersWithoutExpirationDate"
              :items="membersList"
              :items-per-page="30"
              class="elevation-1"
              :footer-props="{ 'items-per-page-options': [30, -1] }"
            >
              <template v-slot:[`item.isPaid`]="{ item }">
                <template>
                  <p>
                    {{ item.isPaid ? "Tak" : "Nie" }}
                  </p>
                </template>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                <template>
                  <v-btn
                    icon
                    color="red"
                    @click="
                      (isDeleteModalVisible = true), (selectedMember = item)
                    "
                  >
                    <font-awesome-icon icon="times" />
                  </v-btn>
                </template>
              </template>
            </v-data-table>
            <before-delete-modal
              :isVisible="isDeleteModalVisible"
              @closeModal="closeDeleteModal()"
              @confirmDeletion="confirmDelete()"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text v-on:click="closeModal">
            <font-awesome-icon icon="times" class="mr-1" />
            Zamknij
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BeforeDeleteModal from "./BeforeDeleteModal.vue";
import axios from "../../axios";
import moment from "moment";
export default {
  name: "SpecialOfferMembersModal",
  props: ["isVisible", "members"],
  emits: ["memberDeleted", "memberEndDateUpdated"],
  components: { BeforeDeleteModal },

  data: () => ({
    selectedMember: null,
    isDeleteModalVisible: false,
    headersWithoutExpirationDate: [
      { text: "Email", value: "email" },
      { text: "Usuwanie", value: "delete" },
    ],
    deletedMembersIds: [],
    isSelectDateModalVisible: false,
    currentDate: null,
    updatedMembersDates: [],
  }),
  computed: {
    membersList() {
      let membersResponse = this.members;
      if (this.members) {
        membersResponse = this.members.filter(
          (m) => !this.deletedMembersIds.includes(m.id)
        );
      }

      return membersResponse;
    },
  },
  watch: {
    members() {
      this.deletedMembersIds = [];
    },
  },
  methods: {
    closeSelectDateModal() {
      this.isSelectDateModalVisible = false;
      this.currentDate = moment().format("YYYY-MM-DD");
    },

    formatDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-MM-DD HH:mm");
        return formattedDate;
      }
      return "";
    },
    async confirmDelete() {
      const { specialOfferId, id } = this.selectedMember;
      try {
        await axios.delete(`specialOffers/${specialOfferId}/members/${id}`);
        this.$emit("memberDeleted");
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "success",
          message: "Użytkownik został pomyślnie usunięty z oferty",
        });
        this.deletedMembersIds.push(id);
      } catch (e) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Nie udało się usunąć użytkownika z oferty specjalnej",
        });
      }
      this.closeDeleteModal();
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .backgroundRed,
::v-deep .backgroundRed:hover {
  background: rgb(236, 201, 201) !important;
}
</style>
