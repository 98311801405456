import axios from "../axios";
import * as Sentry from "@sentry/vue";
const snackbar = {
  namespaced: true,

  state: () => ({
    package: null,
    createOrder: false,
    tickets: [],
    ticketUpdated: false,
    userTicket: null,
    userTicketBeginners: null,
    courseTicketsLinks: [],
    courseTicketsLinksUpdated: false,
    courseTicketsBeginnersLinks: [],
  }),

  mutations: {
    SET_PACKAGE(state, load) {
      state.package = load;
    },
    CREATE_ORDER(state, success) {
      state.createOrder = success;
    },
    SET_TICKETS(state, tickets) {
      state.tickets = tickets;
    },
    SET_TICKET_UDPATED(state, status) {
      state.ticketUpdated = status;
    },
    SET_USER_TICKET(state, ticket) {
      state.userTicket = ticket;
    },
    SET_USER_TICKET_BEGINNERS(state, ticket) {
      state.userTicketBeginners = ticket;
    },
    SET_COURSE_TICKETS_LINKS(state, links) {
      state.courseTicketsLinks = links;
    },
    SET_TICKET_LINK_UPDATED(state, status) {
      state.courseTicketsLinksUpdated = status;
    },
    SET_COURSE_TICKETS_BEGINNERS_LINKS(state, links) {
      state.courseTicketsBeginnersLinks = links;
    },
  },

  actions: {
    async handleClickedLink({ commit }, id) {
      try {
        const url = `courseTicketsLinks/${id}/clicked`;
        await axios.put(url);
        commit("SET_TICKET_LINK_UPDATED", true);
      } catch (error) {
        commit("SET_TICKET_LINK_UPDATED", false);
        console.log(error);
      }
    },
    async fetchCourseTicketsLinks({ commit }, data) {
      try {
        const type = data && data.type ? data.type : "STANDARD";
        const url = `courseTicketsLinks`;
        const response = await axios.get(url, { params: { type } });
        if (type === "STANDARD") {
          commit("SET_COURSE_TICKETS_LINKS", response.data);
        }
        if (type === "BEGINNERS") {
          commit("SET_COURSE_TICKETS_BEGINNERS_LINKS", response.data);
        }
      } catch (error) {
        console.log(error);
        if (data.type === "STANDARD") {
          commit("SET_COURSE_TICKETS_LINKS", []);
        }
        if (data.type === "BEGINNERS") {
          commit("SET_COURSE_TICKETS_BEGINNERS_LINKS", []);
        }
      }
    },
    async updateCourseTicketsLinks({ commit }, data) {
      try {
        const { id, link, name, type } = data;
        const url = `courseTicketsLinks/${id}`;
        await axios.put(url, { link, name, type });
        commit("SET_TICKET_LINK_UPDATED", true);
      } catch (error) {
        commit("SET_TICKET_LINK_UPDATED", false);
        console.log(error);
      }
    },
    async createCourseTicketsLinks({ commit }, data) {
      try {
        const { link, name, type } = data;
        const url = `courseTicketsLinks`;
        const response = await axios.post(url, { link, name, type });
        commit("SET_TICKET_LINK_UPDATED", true);
        return response.data.id;
      } catch (error) {
        commit("SET_TICKET_LINK_UPDATED", false);
        console.log(error);
      }
    },

    async deleteCourseTicketsLinks({ commit }, id) {
      try {
        const url = `courseTicketsLinks/${id}`;
        await axios.delete(url);
        commit("SET_TICKET_LINK_UPDATED", true);
      } catch (error) {
        commit("SET_TICKET_LINK_UPDATED", false);
        console.log(error);
      }
    },
    async fetchListOfTickets({ commit }, data) {
      try {
        const type = data && data.type ? data.type : "STANDARD";
        const url = `courseTickets/users`;
        const params = { type };
        const response = await axios.get(url, { params });
        commit("SET_TICKETS", response.data);
      } catch (error) {
        console.log(error);
        commit("SET_TICKETS", []);
      }
    },
    async fetchPackage({ commit }, id) {
      try {
        const response = await axios.get(`courseTickets/${id}`);
        commit("SET_PACKAGE", response.data);
      } catch (err) {
        commit("SET_PACKAGE", null);
        console.log(err);
      }
    },

    async createOrder({ commit }, data) {
      const {
        phone,
        name,
        email,
        discountCode,
        packageId,
        newsletter,
        companyName,
        nip,
        address,
        invoiceForCompany,
      } = data;
      return axios
        .post(`courseTickets/orders/${packageId}`, {
          name,
          email,
          phone,
          email_confirmation: email,
          userDiscountCode: discountCode,
          newsletter,
          address,
          companyName,
          nip,
          invoiceForCompany,
        })
        .then(function(response) {
          const { data } = response;
          commit("CREATE_ORDER", true);
          return data;
        })
        .catch((error) => {
          console.log(error);
          commit("CREATE_ORDER", false);
          Sentry.captureException(error);
        });
    },

    async addValueToTicket({ commit }, data) {
      try {
        const { email, type } = data;
        const url = `courseTickets/users/action`;
        await axios.put(url, { action: "ADD", email, type });
        commit("SET_TICKET_UDPATED", true);
      } catch (error) {
        commit("SET_TICKET_UDPATED", false);
        console.log(error);
      }
    },
    async minusValueToTicket({ commit }, data) {
      try {
        const { email, type } = data;
        const url = `courseTickets/users/action`;
        await axios.put(url, { action: "SUBTRACT", email, type });
        commit("SET_TICKET_UDPATED", true);
      } catch (error) {
        commit("SET_TICKET_UDPATED", false);
        console.log(error);
      }
    },
    async changeExpirationTimeForTicket({ commit, dispatch }, data) {
      try {
        const { date, email, type } = data;
        const url = `courseTickets/users/expirationTime`;
        await axios.put(url, { date, email, type });
        commit("SET_TICKET_UDPATED", true);
        await dispatch("fetchListOfTickets", { type });
      } catch (error) {
        commit("SET_TICKET_UDPATED", false);
        console.log(error);
      }
    },
    async getUserTicketDetails({ commit }, data) {
      try {
        const type = data && data.type ? data.type : "STANDARD";
        const params = {
          type,
        };
        const url = `courseTickets/my`;
        const response = await axios.get(url, { params });
        console.log(response.data);
        if (type === "STANDARD") {
          commit("SET_USER_TICKET", response.data);
        }
        if (type === "BEGINNERS") {
          commit("SET_USER_TICKET_BEGINNERS", response.data);
        }
      } catch (error) {
        if (data.type === "STANDARD") {
          commit("SET_USER_TICKET", null);
        }
        if (data.type === "BEGINNERS") {
          commit("SET_USER_TICKET_BEGINNERS", null);
        }

        console.log(error);
      }
    },
  },

  getters: {
    getPackage: (state) => state.package,
    getTickets: (state) => state.tickets,
    getUserTicket: (state) => state.userTicket,
    getUserTicketBeginners: (state) => state.userTicketBeginners,
    getCoursesTicketsLinks: (state) => state.courseTicketsLinks,
    getCoursesTicketsBeginnersLinks: (state) =>
      state.courseTicketsBeginnersLinks,
  },
};

export default snackbar;
