<template>
  <div class="container">
    <div
      class="card-desc"
      :class="[
        withoutBorder ? 'without-border' : '',
        withoutTopMargin ? 'without-top-margin' : '',
      ]"
    >
      <slot name="content"> </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "title",
    "subtitle",
    "brownStyles",
    "withoutBorder",
    "withoutTopMargin",
  ],
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}
.card-desc {
  width: 70vw;
  margin-bottom: 40px;
  padding: 40px 70px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px;
  display: block;
  text-align: center;
  font-size: 18px;
  max-width: 960px !important;
}
.without-border {
  box-shadow: none;
  border-radius: 0;
}
.without-top-margin {
  margin-top: 0 !important;
}
.title-one {
  font-size: 54px;
  font-weight: 700;
  background-color: #000000de;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px !important;
}
.under-title {
  font-size: 22px;
  font-weight: 500;
}
p {
  text-align: left;
  font-weight: 300;
}
.title-two {
  font-size: rem;
  font-weight: 400;
  line-height: 3rem;
  position: relative;
}
.title-two::after {
  content: "";
  width: 8px;
  height: 8px;
  background: -webkit-linear-gradient(
    90deg,
    rgba(179, 106, 103, 1) 0%,
    rgba(223, 44, 38, 1) 100%
  );
  position: absolute;
  border-radius: 30px;
  top: 35%;
  right: 100%;
  margin: 0 10px;
}
@media screen and (max-width: 1170px) {
  .coursesContainter {
    display: flex;
    flex-direction: column;
    flex-gap: 20px;
  }
}

@media screen and (max-width: 1050px) {
  .card-desc {
    width: 80vw;
  }
}
@media screen and (max-width: 950px) {
  .card-desc {
    width: 90vw;
  }
}
@media screen and (max-width: 820px) {
  .title-one {
    font-size: 44px;
  }
  .under-title {
    font-size: 18px;
  }
}
@media screen and (max-width: 840px) {
  .courses {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4rem;
    margin-top: 0px;
  }
}

@media screen and (max-width: 500px) {
  .header img {
    height: 300px;
    width: fit-content;
    object-fit: cover;
  }
  .card-desc {
    width: 100vw;
    padding: 40px 30px;
  }
  .under-title {
    font-size: 15px;
  }
  p {
    font-size: 0.9rem;
  }
  .title-two {
    font-size: 1.1rem;
  }
  .title-one {
    font-size: 36px;
    display: block;
    display: -webkit-box;
  }
}
</style>
