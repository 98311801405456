<template>
  <div class="search__container">
    <p class="search__title"></p>
    <input
      v-model="text"
      class="search__input"
      type="text"
      placeholder="Wpisz nazwę kursu"
    />
  </div>
</template>

<script>
export default {
  props: ["initialValue"],
  emits: ["handleSearch"],
  mounted() {},
  watch: {
    text(content) {
      this.handleSearchInput(content);
    },
  },
  methods: {
    handleSearchInput(content) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.$emit("handleSearch", content);
      }, 600);
    },
  },
  data() {
    return {
      text: "",
      timeoutId: "",
    };
  },
};
</script>

<style scoped>
.search__container {
  margin-bottom: 3rem;
}
.search__input {
  width: 100%;
  min-width: 300px;

  background-color: transparent;
  transition: transform 250ms ease-in-out;
  font-size: 14px;
  line-height: 18px;
  color: #575756;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
  border-radius: 50px;
  border: 1px solid #575756;
  transition: all 250ms ease-in-out;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  padding: 12px 1.3rem 12px 0;
  outline: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #575756;
  border-radius: 0;
  background-position: 100% center;
}
@media (max-width: 700px) {
  .search__input {
    min-width: 0px;
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .search__input {
    min-width: 0px;
    font-size: 10px;
  }
}
</style>
