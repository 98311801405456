<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"> Dodaj zestaw </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="number"
                    rules="required"
                    v-model.number="price"
                    label="Cena"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="number"
                    v-model.number="discount"
                    label="Wartość zniżki"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="discountCode"
                    label="Kod zniżkowy"
                    color="indigo"
                    outlined
                    dense
                  /> </v-col
                ><v-col cols="12" sm="12">
                  <v-checkbox
                    v-model="checkbox"
                    :label="`Zajęcia online`"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="12" v-if="discountError">
                  <p class="error--text">Zniżka nie może być większa od ceny</p>
                </v-col>
                <v-col cols="12" sm="12" v-if="zoomError">
                  <p class="error--text">
                    Wymagane pola: Zoom ID oraz Data zajęć
                  </p>
                </v-col>
                <div v-if="checkbox" style="width: 100%">
                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="zoomId"
                      label="Zoom ID"
                      color="indigo"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="date"
                      label="Data zajęć"
                      color="indigo"
                      outlined
                      dense
                    />
                  </v-col>
                </div>

                <v-col v-if="itemsError" sm="9">
                  <p class="error--text">Dodaj przynajmniej jeden link</p>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    label="Wklej link"
                    v-model="input"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-btn v-on:click="addItem()" color="green">dodaj</v-btn>
                </v-col>

                <v-col sm="12" v-for="(item, index) in items" :key="item">
                  <v-card>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon
                        style="cursor: pointer"
                        v-on:click="removeItem(index)"
                      >
                        <font-awesome-icon color="red" icon="times" />
                      </v-list-item-icon>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addPackage"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";

export default {
  name: "AddPAckageModal",
  props: ["isVisible", "packageData"],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
  },

  data: () => ({
    packageId: null,
    price: 0,
    discount: 0,
    discountCode: "",
    input: "",
    checkbox: false,
    zoomId: "",
    date: "",
    items: [],
    itemsError: false,
    discountError: false,
    updated: false,
    zoomError: false,
  }),
  updated() {
    if (this.packageData) {
      const { id, price, discount, discountCode, zoomId, date, packageItems } =
        this.packageData;
      this.packageId = id;
      this.price = price ? price : 0;
      this.discount = discount ? discount : 0;
      this.discountCode = discountCode ? discountCode : "";
      this.zoomId = zoomId ? zoomId : "";
      this.date = date ? date : "";
      this.items = packageItems ? packageItems.map((i) => i.link) : [];
      this.checkbox = zoomId || date ? true : false;
      this.updated = true;
    }
  },
  methods: {
    addItem() {
      if (this.input != "") {
        this.items.push(this.input);
        this.input = "";
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    closeModal() {
      this.price = 0;
      this.discount = 0;
      this.discountCode = "";
      this.zoomId = "";
      this.date = "";
      this.items = [];
      this.updated = false;
      this.checkbox = false;
      this.$emit("closeModal");
    },
    async addPackage() {
      if (this.items.length === 0) {
        this.itemsError = true;
        setTimeout(() => {
          this.itemsError = false;
        }, 4000);
      } else if (this.discount >= this.price) {
        this.discountError = true;
        setTimeout(() => {
          this.discountError = false;
        }, 4000);
      } else if (this.checkbox && (!this.zoomId || !this.date)) {
        this.zoomError = true;
        setTimeout(() => {
          this.zoomError = false;
        }, 4000);
      } else {
        let object = {
          price: this.price,
          items: this.items,
        };
        if (this.discount !== 0 && this.discountCode !== "") {
          object.discount = this.discount;
          object.discountCode = this.discountCode;
        } else {
          object.discount = null;
          object.discountCode = null;
        }
        if (this.checkbox) {
          object.zoomId = this.zoomId;
          object.date = this.date;
        }
        if (this.updated) {
          await this.$store
            .dispatch("packages/updatePackage", {
              packageId: this.packageId,
              data: object,
            })
            .then(() => {
              this.closeModal();
            });
        } else {
          await this.$store
            .dispatch("packages/addPackage", { data: object })
            .then(() => {
              this.closeModal();
            });
        }
      }
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.error--text {
  font-size: 20px;
}
</style>
