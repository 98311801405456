import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vee-validate";
import "plyr/dist/plyr.css";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
import VueCarousel from "vue-carousel";
import VueMeta from "vue-meta";
import VueAnalytics from "vue-analytics";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VueCarousel);
Vue.use(VueMeta);
Vue.use(VueAnalytics, {
  id: "UA-218811717-1",
  router,
});

Vue.use(VueQuillEditor, {
  placeholder: "Dodaj treść...",
  modules: {
    toolbar: {
      container: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ['link'],
        [{ 'align': [] }],
        [  'image'],
      ],
    },
  },
});

Vue.config.productionTip = false;
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
