<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Dostępne oferty specjalne
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak ofert specjalnych
        </v-alert>
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        <p style="text-align: center">
          {{ item.endDate ? formatDate(item.endDate) : "Brak" }}
        </p>
        <v-btn @click="editEndDate(item)" class="mt-4"> Edytuj </v-btn>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <p style="text-align: center">{{ item.active ? "Tak" : "Nie" }}</p>
        <v-btn
          style="font-size: 12px; width: 100px"
          @click="markStatusAsActiveOrInactive(item)"
        >
          {{ item.active ? "Dezaktywuj" : "Aktywuj" }}
        </v-btn>
      </template>

      <template v-slot:[`item.members`]="{ item }">
        <div class="d-flex flex-column">
          <v-btn @click="showMembers(item)">
            Pokaż ({{ item.members.length }})
          </v-btn>

          <v-btn @click="showAddEmailModal(item.id)" class="mt-4">
            Dodaj
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editOffer(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              @click="(isDeleteModalVisible = true), (packageData = item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              @click="copyOffer(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="copy" />
            </v-btn>
          </template>
          <span>Kopiuj</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p
            style="max-height: 200px; min-width: 300px; overflow: scroll"
            :id="item.id"
            v-html="htmlToFormattedText(item.description)"
          />
        </template>
      </template>
    </v-data-table>
    <AddSpecialOfferModal
      :isVisible="isModalVisible"
      :offerItemData="offerData"
      @closeModal="closeModal()"
      @updated="refreshOffers()"
    />
    <SpecialOfferMembersModal
      :isVisible="isOfferMembersModalVisible"
      :members="offerMembers"
      @closeModal="closeOfferMembersModal()"
      @memberDeleted="refreshOffers()"
      :withoutExpirationDate="true"
    />
    <AddEmailModal
      :label="'Dodaj użytkownika do oferty specjalnej'"
      @confirm="addMemberToSpecialOffer"
      @closeModal="closeAddEmailModal()"
      :isVisible="addEmailModalVisible"
    />
    <select-date-modal
      :isVisible="isSelectDateModalVisible"
      :currentDate="currentDate"
      @closeModal="closeSelectDateModal()"
      @dateSelected="handleEditDate"
    />

    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirmDelete(packageData.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddSpecialOfferModal from "../components/modals/AddSpecialOfferModal";
import ManagementNav from "../components/ManagementNav.vue";
import SpecialOfferMembersModal from "../components/modals/SpecialOfferMembersModal";
import AddEmailModal from "../components/modals/AddEmailModal.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import moment from "moment";
import SelectDateModal from "../components/modals/SelectDateModal.vue";
export default {
  name: "SpecialOffersManagement",
  components: {
    AddSpecialOfferModal,
    ManagementNav,
    SpecialOfferMembersModal,
    AddEmailModal,
    BeforeDeleteModal,
    SelectDateModal,
  },
  async created() {
    await this.$store.dispatch("specialOffers/fetchSpecialOfferWithNotActive");
    await this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
      includeItemsWithSubcategory: true,
    });
    await this.$store.dispatch(
      "timePackages/fetchAllTimePackageItemsForBeginners",
      {
        includeItemsWithSubcategory: true,
        forBeginners: true,
      }
    );
  },
  data: () => ({
    headers: [
      { text: "Aktywny?", value: "active" },
      { text: "Nazwa oferty", value: "name" },
      { text: "Data automatycznego zakończenia", value: "endDate" },
      { text: "Link do płatności", value: "paymentLink" },
      { text: "Link do kursów", value: "coursesLink" },
      { text: "Lista uczestników", value: "members" },
      { text: "Opis oferty", value: "description" },
      { text: "Cena", value: "price" },

      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isOfferMembersModalVisible: false,
    offerData: null,
    offerMembers: [],
    currentDate: null,
    isSelectDateModalVisible: false,
    offersIdToEdit: "",
    addEmailModalVisible: false,
    isDeleteModalVisible: false,
  }),
  computed: {
    items() {
      return this.$store.getters["specialOffers/getSpecialOffers"];
    },
  },
  methods: {
    async handleEditDate(e, date) {
      await this.$store.dispatch("specialOffers/editSpecialOfferEndDate", {
        id: this.offersIdToEdit,
        endDate: moment(date).endOf("day").toDate(),
      });
      this.refreshOffers();
      this.closeSelectDateModal();
    },

    closeSelectDateModal() {
      this.isSelectDateModalVisible = false;
      this.currentDate = moment().format("YYYY-MM-DD");
      this.offersIdToEdit = "";
    },
    editEndDate(offer) {
      const endDate = offer.endDate;
      this.isSelectDateModalVisible = true;
      this.currentDate = endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.offersIdToEdit = offer.id;
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD");
      }
      return "Brak";
    },
    confirmDelete(id) {
      this.deletePackage(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageData = null;
    },
    showAddEmailModal(id) {
      this.offersIdToEdit = id;
      this.addEmailModalVisible = true;
    },
    async addMemberToSpecialOffer(email) {
      const response = await this.$store.dispatch(
        "specialOffers/adminAddUserToSpecialOffer",
        {
          id: this.offersIdToEdit,
          email,
        }
      );
      if (response) {
        this.closeAddEmailModal();
      }
    },

    async markStatusAsActiveOrInactive(item) {
      if (!item.active) {
        await this.$store.dispatch("specialOffers/markSpecialOfferAsActive", {
          id: item.id,
        });
      } else {
        await this.$store.dispatch(
          "specialOffers/markSpecialOfferAsNotActive",
          { id: item.id }
        );
      }
    },

    async refreshOffers() {
      await this.$store.dispatch(
        "specialOffers/fetchSpecialOfferWithNotActive"
      );
    },
    copyOffer(data) {
      this.offerData = data;
      this.offerData.id = null;
      this.offerData.copy = true;
    },
    showMembers(item) {
      this.offerMembers = item.members;
      this.isOfferMembersModalVisible = true;
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    editOffer(data) {
      this.offerData = data;
    },
    async deletePackage(id) {
      await this.$store.dispatch("specialOffers/deleteSpecialOffer", {
        id,
      });
    },
    closeModal() {
      this.isModalVisible = false;
      this.offerData = null;
    },
    closeOfferMembersModal() {
      this.isOfferMembersModalVisible = false;
    },
    closeAddEmailModal() {
      this.addEmailModalVisible = false;
      this.offersIdToEdit = "";
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
}
</style>
