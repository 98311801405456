<template>
  <v-row justify="center">
    <v-dialog
      max-width="500px"
      :value="isVisible"
      @keydown.esc="handleCloseModal"
      :retain-focus="false"
    >
      <v-card>
        <ValidationObserver ref="obs" mode="eager">
          <v-card-title>
            <span class="headline"> Czy na pewno usunąć? </span>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="handleCloseModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn color="success" text v-on:click="confirmDeletion">
              <font-awesome-icon icon="check" class="mr-1" />
              Usuń
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  name: "BeforeDeleteModal",
  props: ["isVisible"],
  emits: ["closeModal", "confirmDeletion"],
  components: {
    ValidationObserver,
  },

  data: () => ({}),

  updated() {},

  methods: {
    handleCloseModal() {
      this.$emit("closeModal");
    },

    confirmDeletion() {
      this.$emit("confirmDeletion");
      this.$emit("closeModal");
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
