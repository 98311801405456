<template>
  <div class="pack">
    <div class="card">
      <!-- <h4>DAGA JOGA</h4> -->
      <div class="title">
        <h2>{{ giftCard.name }}</h2>
      </div>
      <div class="price">
        <span v-if="!giftCard.priceBeforeDiscount">
          <p class="price-amount">
            <span>{{
              giftCard.price.substring(0, giftCard.price.length - 3)
            }}</span
            ><span
              v-if="
                giftCard.price.split('.')[1] &&
                giftCard.price.split('.')[1] !== '00'
              "
              >.{{ giftCard.price.split(".")[1] }}</span
            >
            <span> zł</span>
          </p>
        </span>
        <span v-else>
          <p class="price-amount">
            <span v-if="giftCard.price.split('.')[1] == '00'">{{
              giftCard.price.substring(0, giftCard.price.length - 3)
            }}</span>
            <span v-else>{{ giftCard.price }}</span>
            <span> zł</span>
          </p>
          <div class="price-amount">
            <s>
              <span style="">{{
                giftCard.priceBeforeDiscount.substring(
                  0,
                  giftCard.priceBeforeDiscount.length - 3
                )
              }}</span
              ><span
                v-if="
                  giftCard.priceBeforeDiscount.split('.')[1] &&
                  giftCard.priceBeforeDiscount.split('.')[1] !== '00'
                "
                >.{{ giftCard.priceBeforeDiscount.split(".")[1] }}</span
              >
              <span> zł</span>
            </s>
          </div>
        </span>
      </div>
      <div class="saving" v-if="basicPrice">
        <p
          :style="
            getNumberOfMonthsFromPackage(giftCard) == 1
              ? 'visibility: hidden'
              : ''
          "
        >
          oszczędzasz
          {{
            String(
              (
                Number(basicPrice) * getNumberOfMonthsFromPackage(giftCard) -
                giftCard.price
              ).toFixed(0)
            ).replaceAll("-", "")
          }}
          zł
        </p>
      </div>

      <div @click="moveToGiftCardPayment(giftCard)" class="buy-button buyNow">
        <img src="https://i.imgur.com/y1jQ3w9.png" loading="lazy" alt="" />
        <h1>Kup teraz</h1>
      </div>
    </div>
    <!-- <ul>
      <h3>Pakiet zawiera:</h3>
      <li
        v-for="item in timePackagesItemsNames"
        :key="item"
        :class="{ firstPackage: firstElement, lastPackage: lastElement }"
      >
       <div> {{ item }}</div>
      </li>
    </ul> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "GiftCard",
  data: () => ({
    month: "",
  }),
  props: {
    giftCard: Object,
    basicPrice: String,
  },
  methods: {
    moveToGiftCardPayment(giftCard) {
      this.$router.push({
        name: "GiftCardPayment",
        query: { id: giftCard.id },
      });
    },
    correctTyping() {
      if (this.giftCard.numberOfDays / 31 === 1) {
        return this.giftCard.numberOfDays / 31 + " miesiąc";
      } else if (
        this.giftCard.numberOfDays / 31 > 1 &&
        this.giftCard.numberOfDays / 31 < 5
      ) {
        return this.giftCard.numberOfDays / 31 + " miesiące";
      } else {
        return this.giftCard.numberOfDays / 31 + " miesięcy";
      }
    },

    getNumberOfMonthsFromPackage(item) {
      const numberOfDays = item.numberOfDays;
      const now = moment();
      const addedDays = moment().add(numberOfDays, "days");
      return addedDays.diff(now, "months");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");

.pack {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  @media (max-width: 425px) {
    flex-wrap: wrap;
  }
}

.card {
  border: 1px solid #bbb;
  padding: 1.5rem 2rem 1rem;
  border-radius: 10px;
  text-align: center;
  margin: 0 3.5rem;
  width: 240px;
  height: 330px;

  .title {
    padding-bottom: 2.5rem;
    font-size: 1.5rem;
  }

  .price {
    padding-bottom: 0rem;
  }
  .price-amount {
    font-size: 2rem;
    font-weight: 600;
  }

  .saving {
    padding-bottom: 1rem;
    font-weight: 500;
  }

  h1 {
    font-size: 2.3rem;
  }

  .buyNow {
    background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
    border-radius: 10px;
    padding: 0.5rem 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    h1 {
      color: #fff;
      font-size: 1.05rem;
      font-weight: 400;
    }

    img {
      padding-right: 0.6rem;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-weight: 400;
    }
  }
  margin-bottom: 1rem !important;
}

@media screen and (max-width: 900px) {
  .title {
    font-size: 22px !important;
    padding-bottom: 5px !important;
  }
  .price-amount {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 600px) {
  .card {
    margin: 0;
  }
  .title {
    font-size: 18px !important;
    padding-bottom: 5px !important;
  }
  .price-amount {
    font-size: 23px !important;
  }
}

@media (max-width: 700px) {
  .buy-button {
    width: max-content;
    margin: 0 auto;
  }
}

@media screen and (max-width: 250px) {
  .title {
    font-size: 15px !important;
    padding-bottom: 5px !important;
  }
  .price-amount {
    font-size: 20px !important;
  }
}
</style>
