<template>
  <div class="footer-container">
    <div>
      <div class="footer">
        <div class="contact">
          <img
            class="comment-icon mb-3"
            src="../assets/comment-dots-regular.svg"
            alt="
          praktyka dla początkujących,
          joga stanie na rękach,
          joga,
          praktyka wzmacniająca,
          szkoła online,
          praktyka,
          prana vyavama,
          subskrypcja DagaJoga
          ćwiczenia online,
          pranajama oddech,
          joga zdrowy
          "
            loading="lazy"
          />
          <p class="bolder mb-8" style="font-size: 18px">
            Skontaktuj się ze mną:
          </p>

          <div class="contact-ways">
            <div class="contact-way">
              <img src="../assets/mail.svg" @click="redirectToMail" />
              <p @click="redirectToMail">daga@dagajoga.pl</p>
            </div>
            <div class="contact-way">
              <img src="../assets/insta.svg" @click="redirectToInsta" />
              <p @click="redirectToInsta">Dagajoga</p>
            </div>
            <div class="contact-way">
              <img src="../assets/fb.svg" @click="redirectToFb" />
              <p @click="redirectToFb">Dagajoga</p>
            </div>
          </div>
          <p style="margin-top: 1rem" class="mb-4">
            Chcesz być na bieżąco z aktualnym grafikiem zajęć, warsztatami i
            kursami? Zostaw swój adres e-mail:
          </p>
          <button
            type="submit"
            class="subscription-button"
            @click="moveToNewsletter"
          >
            Zapisz się do Newslettera
          </button>
        </div>

        <div class="sysmo">
          <p class="bolder">
            Podoba Ci się strona? Potrzebujesz skorzystać z usług programistów?
          </p>
          <div class="sysmo-info">
            <a href="https://sysmo.pl/" target="_blank">
              <img
                src="../assets/sysmo-icon-v2.png"
                alt="Strona stworzona przez Sysmo.pl - Software house zajmujący się aplikacjami webowymi i platformami do sprzedaży kursów online"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <p class="copyright">
      © Copyright {{ currentYear }}
      <a href="https://dagajoga.pl/">Daga Joga.</a> All Rights Reserved.
    </p>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Footer",
  methods: {
    redirectToMail() {
      window.location.href = "mailto:daga@dagajoga.pl";
    },
    redirectToFb() {
      window.open("https://www.facebook.com/Dagajoga", "_blank");
    },
    redirectToInsta() {
      window.open("https://www.instagram.com/dagajoga/", "_blank");
    },
    moveToNewsletter() {
      this.$router.push({ name: "Newsletter" });
    },
  },

  computed: {
    currentYear() {
      return moment().get("year");
    },
  },
};
</script>

<style type="text/scss" scoped>
.footer-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  width: 100vw;
  align-items: center;
  background: #000;
}
.footer {
  width: 100%;
  display: flex;
  background: #000;
  color: #fff;
  padding: 1rem 2rem 2rem 2rem;
  font-size: 17px;
  justify-content: space-between;
  font-weight: 400;
  align-items: center;
  max-width: 1441px;
}

.bolder {
  font-weight: 700;
}
.copyright {
  padding: 1rem 0;
  margin-bottom: 0;
  font-size: 12px;
  background: #000;
  color: #fff;
}
.copyright a {
  color: #fff;
  text-decoration: none;
}

.comment-icon {
  width: 50px;
  height: 50px;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 45%;
  justify-content: center;
  margin-top: 1rem;
}

.sysmo {
  text-align: center;
  max-width: 25%;
  padding-top: 3rem;
}
.sysmo-info {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 1.5rem;
  justify-content: center;
}
.sysmo-info a {
  color: #b28a8a;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.sysmo-info img {
  width: 192px;
  height: 47px;
}
.subscription-button {
  color: #fff;
  background-color: #b28a8a;
  border-radius: 9999px;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125em;
  padding: calc(0.667em + 2px) calc(1.333em + 2px);
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;
  box-sizing: border-box;
  margin-top: 1.5rem;
}
.contact-ways {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #b28a8a;
}
.contact-way {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
}
.contact-way > img {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.contact-way > p {
  font-size: 13px;
  cursor: pointer;
}

@media screen and (max-width: 970px) {
  .sysmo {
    max-width: 35%;
  }
}
@media screen and (max-width: 700px) {
  .sysmo {
    max-width: 40%;
    margin: 40px 0;
  }
  .contact {
    max-width: 70%;
  }
  .footer {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .sysmo {
    max-width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .footer {
    font-size: 15px;
  }
  .sysmo {
    max-width: 70%;
  }
  .subscription-button {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 400px) {
  .sysmo {
    max-width: 100%;
  }
  .contact {
    max-width: 100%;
  }
}
@media screen and (max-width: 250px) {
  .footer {
    font-size: 13px;
  }
  .subscription-button {
    font-size: 0.7em;
  }
  a {
    font-size: 13px;
  }
}
</style>
