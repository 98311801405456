<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Dostępne warsztaty
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon icon="search" slot="prepend-inner" class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak warsztatów
        </v-alert>
      </template>
      <template v-slot:[`item.startTime`]="{ item }">
        <span v-if="item.startTime"
          >{{ formatDate(item.startTime) }}
          <v-btn icon color="green" @click="editDate(item)">
            <font-awesome-icon icon="edit" />
          </v-btn>
        </span>
        <span v-else>Brak</span>
      </template>
      <template v-slot:[`item.members`]="{ item }">
        <v-btn @click="showMembers(item)"> Pokaż </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editPackage(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              @click="(isDeleteModalVisible = true), (packageData = item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              @click="copyPackage(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="copy" />
            </v-btn>
          </template>
          <span>Kopiuj</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p :id="item.id" v-html="htmlToFormattedText(item.description)" />
        </template>
      </template>
    </v-data-table>
    <AddTimePackageModal
      :isVisible="isModalVisible"
      :packageData="packageData"
      @closeModal="closeModal()"
      :withDiscount="true"
      :isWorkshops="true"
    />
    <SelectDateModal
      :isVisible="isSelectDateModalVisible"
      :currentDate="currentDate"
      @closeModal="closeSelectDateModal()"
      @dateSelected="dateSelected"
    />
    <TimePackageOrdersModal
      :isVisible="isTimePackageMembersModalVisible"
      :orders="orders"
      @closeModal="closeTimePackageOrderModal()"
    />
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirmDelete(packageData.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddTimePackageModal from "../components/modals/AddTimePackageModal";
import ManagementNav from "../components/ManagementNav.vue";
import TimePackageOrdersModal from "../components/modals/TimePackageOrdersModal";
import moment from "moment";
import SelectDateModal from "../components/modals/SelectDateModal";
import axios from "../axios";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
export default {
  name: "WorkshopsManagement",
  components: {
    AddTimePackageModal,
    ManagementNav,
    TimePackageOrdersModal,
    SelectDateModal,
    BeforeDeleteModal,
  },
  created() {
    this.$store.dispatch("timePackages/fetchAllTimePackages", {
      type: "all_workshops",
    });
  },
  data: () => ({
    headers: [
      { text: "Nazwa warsztatu", value: "name" },
      { text: "Link do płatności", value: "link" },
      { text: "Data rozpoczęcia", value: "startTime" },
      { text: "Lista uczestników", value: "members" },
      { text: "Opis warsztatu", value: "description" },
      { text: "Zniżka", value: "discount" },
      { text: "Kod zniżkowy", value: "discountCode" },
      { text: "Cena", value: "price" },
      { text: "Ilość dni", value: "numberOfDays" },
      { text: "Maksymalna liczba uczestników", value: "maxNumberOfMembers" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    isTimePackageMembersModalVisible: false,
    packageData: null,
    orders: [],
    currentDate: null,
    isSelectDateModalVisible: false,
    workshopsIdToEdit: "",
    isDeleteModalVisible: false,
  }),
  computed: {
    items() {
      return this.$store.getters["timePackages/getAllTimePackages"];
    },
  },
  methods: {
    confirmDelete(id) {
      this.deletePackage(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageData = null;
    },
    editDate(item) {
      this.currentDate = item.startTime;
      this.workshopsIdToEdit = item.id;
      this.isSelectDateModalVisible = true;
    },
    formatDate(date) {
      return moment(date).format("YYYY-DD-MM HH:mm");
    },
    copyPackage(data) {
      this.packageData = data;
      this.packageData.id = null;
      this.packageData.copy = true;
    },
    showMembers(timePackage) {
      this.orders = timePackage.activeOrders;
      this.isTimePackageMembersModalVisible = true;
    },
    htmlToFormattedText(text) {
      const regex = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    editPackage(data) {
      this.packageData = data;
    },
    deletePackage(id) {
      this.$store.dispatch("timePackages/deletePackage", {
        id,
        type: "all_workshops",
      });
    },
    closeModal() {
      this.isModalVisible = false;
      this.packageData = null;
    },
    closeTimePackageOrderModal() {
      this.isTimePackageMembersModalVisible = false;
    },
    closeSelectDateModal() {
      this.isSelectDateModalVisible = false;
    },
    async dateSelected(e, date) {
      this.isSelectDateModalVisible = false;
      await axios.put(
        `management/timePackages/${this.workshopsIdToEdit}/date`,
        {
          startTime: date,
        }
      );
      this.$store.dispatch("timePackages/fetchAllTimePackages", {
        type: "all_workshops",
      });
    },
  },
};
</script>

<style></style>
