<template>
  <div class="product-card">
    <div class="product-card__image" v-if="product">
      <div
        v-if="
          product.shop_products_images &&
          product.shop_products_images.length > 0
        "
      >
        <img
          v-for="image in product.shop_products_images"
          :key="image.id"
          :id="`${image.id}_image`"
          src=""
          alt="Product image"
          class="product-image"
        />
      </div>
      <img
        v-else
        :id="`${product.id}_image`"
        src="../../assets/product-no-photo.jpeg"
        alt="Product image"
      />
    </div>
    <div class="product-card-details" v-if="!loading && product">
      <h2 class="product-card-title">{{ product.name }}</h2>
      <h3 class="product-card-subtitle">{{ product.subtitle }}</h3>
      <product-price :price="product.price" margin="0 0 1rem 0" />
      <div class="product-card-buttons">
        <div class="quantity-selector">
          <div class="quantity-selector-minus" @click="minusQuantity">
            <span class="prevent-select">-</span>
          </div>
          <p class="quantity-selector-value prevent-select">
            {{ selectedQuantity }}
          </p>
          <div class="quantity-selector-minus" @click="addQuantity">
            <span class="prevent-select">+</span>
          </div>
        </div>

        <rounded-color-button
          @clicked="addProductToBucket(product)"
          :label="'Dodaj do koszyka'"
        />
      </div>

      <div class="product-description">
        <p v-html="product.description"></p>
        <p></p>
      </div>
    </div>

    <!-- WERSJA ZE SLIDEREM -->
    <!-- <div class="product-description">
      <p v-html="product.description"></p>
      <p></p>
    </div> -->
  </div>
</template>

<script>
import { SERVER_URL } from "../../axios";
import RoundedColorButton from "../../components/buttons/RoundedColorButton.vue";
import ProductPrice from "../../components/shop/ProductPrice.vue";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "ShopProducts",
  data: () => ({
    loading: false,
    selectedQuantity: 1,
    selectedImage: "",
  }),
  components: { ProductPrice, RoundedColorButton },
  props: {
    path: String,
  },

  async mounted() {
    const productId = this.$route.params.id;
    await this.$store.dispatch("shopProducts/fetchProductDetails", {
      id: productId,
    });

    this.getImage();
    this.getImagesPreview();
  },
  async beforeUnmount() {
    await this.$store.dispatch("shopProducts/setSelectedProductToNull");
  },

  computed: {
    product() {
      const product =
        this.$store.getters["shopProducts/getSelectedShopProduct"];
      const productImages = product ? product.shop_products_images : [];
      if (productImages && productImages.length > 0) {
        const reversedImages = productImages.sort(
          (a, b) => Number(b.isMain) - Number(a.isMain)
        );
        product.shop_products_images = reversedImages;
      }
      return product;
    },
  },
  methods: {
    addProductToBucket(product) {
      this.$store.dispatch("shopProducts/addProductToBucket", {
        product,
        amount: this.selectedQuantity,
      });
    },
    getImage() {
      if (this.product) {
        // WERSJA ZE SLIDEREM
        // const element = document.getElementById(`${this.product.id}_image`);
        // if (element) {
        //   if (
        //     this.product &&
        //     this.product.shop_products_images &&
        //     Array.isArray(this.product.shop_products_images)
        //   ) {
        //     const mainImage = this.product.shop_products_images.find(
        //       (image) => image.isMain
        //     );
        //     const firstImage = this.product.shop_products_images[0];

        //     if ((element && mainImage) || firstImage) {
        //       const splittedPath = mainImage
        //         ? mainImage.path.split("/")
        //         : firstImage.path.split("/");
        //       const fileName = splittedPath[splittedPath.length - 1];
        //       element.src = `${SERVER_URL}shopProducts/${fileName}`;
        //       this.selectedImage = mainImage ? mainImage : firstImage;
        //     }
        //   }
        // }

        const images = this.product.shop_products_images;
        images.forEach((image) => {
          const element = document.getElementById(`${image.id}_image`);
          if (element) {
            const splittedPath = image.path.split("/");
            const fileName = splittedPath[splittedPath.length - 1];
            element.src = `${SERVER_URL}shopProducts/${fileName}`;
          }
        });
      }
    },

    setImageByImageId(imageId) {
      const element = document.getElementById(`${this.product.id}_image`);
      if (element && imageId) {
        if (
          this.product &&
          this.product.shop_products_images &&
          Array.isArray(this.product.shop_products_images)
        ) {
          const foundImage = this.product.shop_products_images.find(
            (image) => image.id === imageId
          );

          if (foundImage) {
            const splittedPath = foundImage.path.split("/");
            const fileName = splittedPath[splittedPath.length - 1];
            element.src = `${SERVER_URL}shopProducts/${fileName}`;
            this.selectedImage = foundImage;
          }
        }
      }
    },

    getImagesPreview() {
      this.product.shop_products_images.forEach((image) => {
        const element = document.getElementById(`${image.id}_small_image`);
        if (element) {
          const splittedPath = image.path.split("/");
          const fileName = splittedPath[splittedPath.length - 1];
          element.src = `${SERVER_URL}shopProducts/${fileName}`;
        }
      });
    },

    addQuantity() {
      this.selectedQuantity = this.selectedQuantity + 1;
    },
    minusQuantity() {
      if (this.selectedQuantity > 1) {
        this.selectedQuantity = this.selectedQuantity - 1;
      }
    },

    prevImage() {
      const currentImageIndex = this.product.shop_products_images.findIndex(
        (image) => image.id === this.selectedImage.id
      );
      if (this.product.shop_products_images[currentImageIndex - 1]) {
        this.selectedImage =
          this.product.shop_products_images[currentImageIndex - 1];
      } else {
        this.selectedImage =
          this.product.shop_products_images[
            this.product.shop_products_images.length - 1
          ];
      }
      this.setImageByImageId(this.selectedImage.id);
    },
    nextImage() {
      const currentImageIndex = this.product.shop_products_images.findIndex(
        (image) => image.id === this.selectedImage.id
      );
      if (this.product.shop_products_images[currentImageIndex + 1]) {
        this.selectedImage =
          this.product.shop_products_images[currentImageIndex + 1];
      } else {
        this.selectedImage = this.product.shop_products_images[0];
      }
      this.setImageByImageId(this.selectedImage.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  gap: 2rem;
  margin: 4rem 1rem 0 1rem;
}
.product-image {
  margin-bottom: 10px;
  max-height: 500px;
  object-fit: cover;
}

.image-selector {
  position: absolute;
  left: -6rem;
  top: 0;
  display: flex;
  flex-direction: column;
  max-height: 100px;
}
.image-selector img {
  cursor: pointer;
}
.slider-prev {
  position: absolute;
  top: 50%;
  left: 1rem;
  font-weight: lighter;
  color: #fff;
}
.slider-next {
  position: absolute;
  top: 50%;
  right: 1rem;
  font-weight: lighter;
  color: #f0f0f0;
}
.product-card__image {
  flex: 1 0 30%;
  position: relative;
}

.product-card__image img {
  display: block;
  width: 100%;
  height: auto;
}

.product-card-details {
  flex: 1 0 20%;
  margin-left: 3rem;
}

.product-card-title {
  font-size: 22px;
}

.product-card-subtitle {
  font-size: 14px;
  margin: 0;
  opacity: 0.5;
  font-weight: 300;
}

.product-card__price {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px;
}

.product-card-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.change-quantity-buttons,
.change-quantity-buttons:hover,
.quantity-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  border: 1px solid rgb(212, 209, 209);
  border-radius: 50px;
  padding: 0 1rem;
  height: 35px;
  margin-right: 1rem;
}
.quantity-selector-value {
  margin: 0;
  min-width: 30px;
  display: flex;
  justify-content: center;
}

.quantity-selector-plus,
.quantity-selector-minus {
  font-size: 25px;
  font-weight: lighter;
  color: #000;
  cursor: pointer;
}

.quantity-selector-plus:hover,
.quantity-selector-minus:hover {
  font-size: 25px;
  color: #b28a8a;
}
.product-description {
  display: flex;
  margin-top: 3rem;
  // wersja ze sliderem
  // margin-left: 6rem;
}
// .product-description p:first-child {
//   max-width: 45%;
// }

@media (max-width: 768px) {
  .product-card-details {
    flex: 1 0 100%;
    padding-left: 0;
    margin-top: 20px;
  }
  .product-description p:first-child {
    max-width: 100%;
  }
  .product-description,
  .product-card-details {
    margin-left: 0;
  }
  .product-card {
    margin-top: 0;
  }
  .quantity-selector {
    gap: 0.75rem;
    margin-right: 0;
  }
}
</style>
