<template>
  <div class="container">
    <shop-product
      :product="product"
      v-for="product in products"
      :key="product.id"
    />
  </div>
</template>

<script>
import ShopProduct from "../../components/shop/ShopProduct.vue";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "ShopProductsList",
  data: () => ({}),
  components: { ShopProduct },
  props: {
    path: String,
  },

  async mounted() {
    await this.$store.dispatch("shopProducts/fetchAllProducts", {
      onPage: this.shopProductsPagination.onPage,
      pageNumber: this.shopProductsPagination.pageNumber,
    });
  },

  computed: {
    products() {
      return this.$store.getters["shopProducts/getShopProducts"];
    },
    shopProductsPagination() {
      return this.$store.getters["shopProducts/getShopProductsPagination"];
    },
    product() {
      return this.$store.getters["shopProducts/getSelectedShopProduct"];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .container {
    justify-content: space-evenly;
  }
}
</style>
