<template>
  <div class="container">
    <div class="header-image-container">
      <img
        src="../assets/dagajoga-poczatek.jpeg"
        alt="Dagajoga Początek"
        style="height: 343px; width: 100%; object-fit: cover"
      />
      <h1>DagaJoga Początek</h1>
    </div>
    <div class="school-online">
      <path-navigation
        :paths="[
          {
            name: 'Szkoła online - DagaJoga Początek',
            to: '/subskrypcja-podstawy',
          },
        ]"
      />

      <div class="video-with-description">
        <div class="video-main">
          <video-player
            :videoId="'https://www.youtube.com/watch?v=pDl9DXwjFeI'"
          />
        </div>
        <div class="video-description">
          <h2 class="text-center">Zapowiedź platformy dla początkujących</h2>
        </div>
      </div>

      <section class="whatGivesSubscription mt-12">
        <p>
          Aby utrzymać motywacje w praktykowaniu, ważne aby materiał był
          zrozumiały, wykonalny ale też nie nudny. Dlatego szkoła online
          DagaJoga Początek zbudowana jest na zasadzie „od podstaw do
          zaawansowanych”
        </p>

        <h2>Co daje subskrypcja?</h2>
        <p>
          Wykupując subskrypcję, otrzymujesz pełen dostęp do wszystkich praktyk
          dla grupy początkującej, praktyki z korektami, analizy asan, oddechu i
          kontemplacji
        </p>
        <p>
          Każdego miesiąca jest dodawana jedna nowa praktyka: na początku
          miesiąca, Praktyki są pełnowartościowe, z jasnym przekazem. Sekwencje
          zawierają modyfikacje dzięki czemu możesz dostosować je do swojego
          poziomu i samopoczucia, oraz do monitorowania swoich postępów.
        </p>
        <p>
          Praktyki są ułożone z dbałością o szczegóły, oparte o wiedzę
          anatomiczną, równoważą też one układ współczuły oraz przywspółczulny,
          dzięki czemu dają sto procent rezultatów na poziomie ciała oraz
          umysłu.
        </p>

        <p>
          Na początek proponuję zapoznać się z technikami oddechowymi (oddech do
          żeber, Kapalabhati) oraz zacząć praktyki w strumieniu korekty (są
          łatwiejsze). Jeżeli poczujesz, że chcesz spróbować czegoś bardziej
          rozbudowanego, to zacznij od analizy chaturanga dandasana, uttanasa i
          Ardho mukha svanasana. Najlepsze efekty uzyskasz praktykując 2, 3 razy
          w tygodniu. 🙏🏻
        </p>
      </section>
      <section class="whyItsWorth">
        <h2>Dlaczego warto się zapisać</h2>
        <h3>Wiedza</h3>
        <p>
          Uzyskasz niezbędne minimum teoretyczne do praktyki własnej, aby
          budować zdrowy, bezpieczny warsztat.
        </p>
        <h3>Połączenie styli</h3>
        <p>
          Praktyka jogi połączona jest z nowoczesnymi metodami ćwiczeń
          funkcjonalnych i ćwiczeń wzmacniających.
        </p>

        <h3>Równowaga</h3>
        <p>
          Praktyka fizyczna prowadzona jest z naciskiem na poprawny oddech.
          Wplecione są też techniki oczyszczające oraz oddechowe, aby
          zrównoważyć układ przywspółczulny i współczulny.
        </p>

        <h3>Wybór</h3>
        <p>Samodzielnie wybierasz kiedy, i jaki temat chcesz praktykować</p>

        <h3>Strumieniowa Praktyka</h3>
        <p>
          Na każdym wideo demonstruje układy, motywując i wyjaśniając technikę.
        </p>

        <h3>Wiedza przekuta w praktykę</h3>
        <p>
          Mam bogate doświadczenie. Spędziłam wiele godzin na macie,
          merytorycznych warsztatach oraz kursach. Opanowanie poznanych technik,
          po połączeniu z podstawami anatomii pozwala mi tworzyć niepowtarzalne
          autorskie sekwencje.
        </p>
      </section>
    </div>
    <section class="subscription">
      <div class="text-box">
        <h2>
          W celu zyskania dostępu do materiałów, zakup lub aktywuj subskrypcje
        </h2>
        <p class="ml-4 mr-4">
          Platformy nie łączą się - kupując pakiet dla początkujących, nie masz
          dostępu do platformy ogólnej i odwrotnie.
        </p>
      </div>
      <div class="card-container">
        <Package
          :pack="timePackage"
          :firstElement="index === 0"
          :lastElement="index === timePackages.length - 1"
          v-for="(timePackage, index) in timePackages"
          :key="timePackage.id"
          :timePackagesItemsNames="timePackagesItemsNames"
          :basicPrice="timePackages[0] ? timePackages[0].price : null"
        />
      </div>
      <h3>lub</h3>
      <div class="active">
        <p @click="moveToGiftCardPage">Aktywuj bon podarunkowy</p>
      </div>

      <div
        v-if="timePackageItems && timePackageItems.length !== 0"
        class="header"
      ></div>
      <div class="" v-if="!hasActiveSubscription">
        <div class="active">
          <p @click="redirect">Aktywuj subskrypcję</p>
        </div>
        <div v-if="!loggedIn" class="active">
          <p @click="redirectLogin(0)">Zaloguj się</p>
        </div>
      </div>
    </section>

    <div class="courses-container">
      <div class="exercises-container">
        <user-schedule :type="'FOR-BEGINNERS'" />
        <admin-schedule :type="'FOR-BEGINNERS'" />
        <div class="dropdown">
          <div class="search-buttons">
            <button @click="changeVisibility" class="drop-btn">
              <div class="button-content">
                <img
                  class="logo"
                  src="https://i.imgur.com/GtLfs3P.png"
                  alt=""
                  loading="lazy"
                />
                Kategorie
                <img
                  class="arrow"
                  src="https://i.imgur.com/XMUrL2W.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </button>
            <search-input @handleSearch="handleSearch" />
          </div>
          <ul class="list dropdown-content" v-if="categoriesVisible">
            <li
              class="primary-button"
              @click="fetchTimePackageItems"
              :class="{ selectedCurrent: !currentCategory }"
            >
              Wszystkie
            </li>
            <li
              class="primary-button"
              v-if="loggedIn"
              @click="fetchLastWatchedTimePackageItems"
              :class="{ selectedCurrent: currentCategory === 'lastWatched' }"
            >
              Ostatnio praktykowane
            </li>
            <li
              class="primary-button"
              @click="fetchFavoriteTimePackageItems"
              :class="{ selectedCurrent: currentCategory === 'favourites' }"
              v-if="loggedIn"
            >
              Ulubione
            </li>
            <Category
              :categoryItem="categoryItem"
              v-for="(categoryItem, index) in categories.slice().reverse()"
              :key="categoryItem.id"
              :ref="`nav-item-${index}`"
              @selected="setCurrentCategory(categoryItem)"
              :class="{ selectedCurrent: currentCategory === categoryItem.id }"
              class="primary-button"
              :forBeginners="true"
            >
            </Category>
          </ul>
        </div>
        <div class="course-container-center">
          <div class="coursesContainter">
            <div ref="courses"></div>
            <div>
              <div
                class="courses"
                id="courses"
                v-if="
                  (timePackageItems &&
                    timePackageItems.length !== 0 &&
                    !timePackageItems[0].lastWatchedCreatedAt) ||
                  (!currentCategory &&
                    allMovieSubCategories &&
                    allMovieSubCategories.length > 0)
                "
              >
                <Course
                  :timePackageItem="timePackageItem"
                  :newCourses="index < 4"
                  :homePractiseCourses="index >= 4"
                  v-for="(
                    timePackageItem, index
                  ) in timePackageItemsAndSubcategories.slice().reverse()"
                  :key="`${timePackageItem.id}+${index}`"
                  :withStars="loggedIn && timePackageItem.link != null"
                  :currentCategory="currentCategory"
                  :id="`course_${timePackageItem.id}`"
                  :isSubcategory="!timePackageItem.link"
                >
                </Course>
              </div>

              <div
                class="courses"
                id="courses"
                v-if="
                  timePackageItems &&
                  timePackageItems.length !== 0 &&
                  timePackageItems[0].lastWatchedCreatedAt
                "
              >
                <Course
                  :timePackageItem="timePackageItem"
                  :newCourses="index < 4"
                  :homePractiseCourses="index >= 4"
                  v-for="(timePackageItem, index) in timePackageItems.slice()"
                  :key="`${timePackageItem.id}+${index}`"
                  :withStars="loggedIn && timePackageItem.link != null"
                  :currentCategory="currentCategory"
                  :id="`course_${timePackageItem.id}`"
                >
                </Course>
              </div>
            </div>
            <div
              class="courses no-courses-container"
              v-if="
                (!timePackageItems || timePackageItems.length === 0) &&
                !currentCategory &&
                (!allMovieSubCategories || allMovieSubCategories.length === 0)
              "
            >
              Brak kursów
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Category from "../components/Category.vue";
import Package from "../components/Package.vue";
import PathNavigation from "../components/PathNavigation.vue";
// import MovieSubcategory from "../components/MovieSubcategory.vue";
import Course from "../components/Course.vue";
import VideoPlayer from "../components/VideoPlayer.vue";
import SearchInput from "../components/SearchInput.vue";
import UserSchedule from "../components/UserSchedule.vue";
import AdminSchedule from "../components/AdminSchedule.vue";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "CoursesForBeginners",
  data: () => ({
    loggedIn: false,
    isShown: false,
    currentCategory: null,
    categoriesVisible: true,
    currentSubcategories: [],
  }),
  components: {
    Category,
    Package,
    PathNavigation,
    // MovieSubcategory,
    Course,
    VideoPlayer,
    SearchInput,
    UserSchedule,
    AdminSchedule,
  },
  props: {
    path: String,
  },

  mounted() {
    this.$store.dispatch(
      "movieSubCategories/setSelectedMovieSubCategoryToNull"
    );
    this.setCurrentCategory(null, this.selectedCategoryId);
    setTimeout(() => {
      const scrollToCourses = window.localStorage.getItem("scrollView");
      const savedCourseId = window.localStorage.getItem("selectedCourse");
      if (scrollToCourses && savedCourseId) {
        this.scrollToCourses(savedCourseId);
        window.localStorage.removeItem("scrollView");
        window.localStorage.removeItem("savedCourseId");
      }
    }, 50);
  },

  watch: {
    selectedCategoryId(id) {
      this.addParamsToLocation({ c: id });
      this.currentCategory = id;
    },
  },

  async created() {
    this.$store.dispatch(`auth/fetchUserProfile`);
    this.$store.dispatch(`movieCategories/fetchAllForUser`, {
      forBeginners: true,
    });

    this.fetchTimePackageItems();
    if (this.selectedCategoryId) {
      if (this.selectedCategoryId === "lastWatched") {
        this.currentSubcategories = [];
        this.currentCategory = "lastWatched";
        this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
          lastWatched: true,
          forBeginners: true,
        });
      } else if (this.selectedCategoryId === "favourites") {
        this.currentSubcategories = [];
        this.currentCategory = "favourites";
        this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
          favorites: true,
          forBeginners: true,
        });
      } else {
        this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
          categoryId: this.selectedCategoryId,
          forBeginners: true,
        });
      }
    } else {
      this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
        forBeginners: true,
      });
    }
    await this.$store.dispatch(`auth/checkUserActiveSubscriptionsForBeginners`);
    if (this.hasActiveSubscription) {
      await this.$store.dispatch(
        `timePackages/fetchActiveSubscriptionsForBeginners`
      );
    } else {
      await this.$store.dispatch(
        `timePackages/setActiveSubscriptionsForBeginnersToNull`
      );
    }
    const token = window.localStorage.getItem("userToken");
    if (token) {
      this.loggedIn = true;
    }
    await this.$store.dispatch(`auth/checkUserActiveSubscriptionsForBeginners`);
    if (this.hasActiveSubscription) {
      await this.$store.dispatch(
        `timePackages/fetchActiveSubscriptionsForBeginners`
      );
    } else {
      await this.$store.dispatch(
        `timePackages/setActiveSubscriptionsForBeginnersToNull`
      );
    }
    this.$store.dispatch("timePackages/fetchAllTimePackages", {
      type: "FOR-BEGINNERS",
    });
    this.$store.dispatch(
      "timePackages/fetchAllTimePackagesItemsForBeginnersNames"
    );
  },

  computed: {
    selectedCategoryId() {
      return this.$store.getters["timePackages/getSelectedCategoryId"];
    },
    allMovieSubCategories() {
      return this.$store.getters["movieSubCategories/getMovieSubCategories"];
    },
    token() {
      return this.$store.getters["auth/getToken"];
    },
    timePackageItems() {
      return this.$store.getters["timePackages/getTimePackageItems"];
    },
    timePackageItemsAndSubcategories() {
      if (!this.currentCategory) {
        return [
          ...this.$store.getters["timePackages/getTimePackageItems"],
          ...this.allMovieSubCategories,
        ];
      } else {
        return [...this.$store.getters["timePackages/getTimePackageItems"]];
      }
    },
    activeSubscriptions() {
      return this.$store.getters[
        "timePackages/getActiveSubscriptionsForBeginners"
      ];
    },
    hasActiveSubscription() {
      return this.$store.getters["auth/getHasActiveSubscriptionForBeginners"];
    },
    profile() {
      return this.$store.getters["auth/getProfile"];
    },
    categories() {
      return this.$store.getters["movieCategories/getMovieCategories"];
    },
    timePackages() {
      return this.$store.getters["timePackages/getAllTimePackages"];
    },
    timePackagesItemsNames() {
      return this.$store.getters[
        "timePackages/getAllTimePackagesItemsForBeginnersNames"
      ];
    },
  },
  methods: {
    async handleSearch(content) {
      this.$store.dispatch("timePackages/setSelectedCategoryId", null);
      this.addParamsToLocation({});
      await this.$store.dispatch(
        "movieSubCategories/fetchAllSubcategoriesForUser",
        { search: content, forBeginners: true }
      );

      await this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
        search: content,
        forBeginners: true,
      });
    },

    scrollToCourses(course) {
      const el = document.querySelector(`#${course}`);
      if (el) {
        setTimeout(() => {
          el.scrollIntoView({ block: "center" });
        }, 10);
      }
    },

    setCurrentCategory(categoryItem, categoryId) {
      this.currentSubcategories = [];
      if (categoryId) {
        this.currentCategory = categoryId;
      } else if (categoryItem) {
        this.currentCategory = categoryItem.id;
        this.currentSubcategories = categoryItem.movie_sub_categories;
      }

      if (this.currentCategory) {
        this.$store.dispatch(
          "timePackages/setSelectedCategoryId",
          this.currentCategory
        );
      }
    },

    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(params[key] ? params[key] : "")
              );
            })
            .join("&")
      );
    },
    async fetchTimePackageItems() {
      this.$store.dispatch("timePackages/setSelectedCategoryId", null);
      this.addParamsToLocation({});
      await this.$store.dispatch(
        "movieSubCategories/fetchAllSubcategoriesForUser",
        {
          forBeginners: true,
        }
      );
      this.currentCategory = null;
      this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
        forBeginners: true,
      });
    },
    fetchFavoriteTimePackageItems() {
      this.setCurrentCategory(null, "favourites");
      this.addParamsToLocation({ c: "favourites" });
      this.currentSubcategories = [];
      this.currentCategory = "favourites";
      this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
        favorites: true,
        forBeginners: true,
      });
    },
    fetchLastWatchedTimePackageItems() {
      this.setCurrentCategory(null, "lastWatched");
      this.addParamsToLocation({ c: "lastWatched" });
      this.currentSubcategories = [];
      this.currentCategory = "lastWatched";
      this.$store.dispatch("timePackages/fetchAllTimePackageItems", {
        lastWatched: true,
        forBeginners: true,
      });
    },
    redirectLogin(signup) {
      this.$router.push({
        name: "Auth",
        params: { name: "Courses", signup: signup },
      });
    },
    getExpirationDate(date) {
      return moment(date).locale("pl").format("DD MMMM YYYY");
    },
    redirect() {
      this.$router.push({
        name: "TimeSubscriptionsForBeginners",
        params: { name: "CoursesForBeginners" },
      });
    },
    changeVisibility() {
      this.categoriesVisible = !this.categoriesVisible;
    },
    moveToGiftCardPage() {
      this.$router.push({ name: "GiftCardEnterCode" });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-courses-container {
  font-size: 2rem;
  color: rgb(124, 120, 120);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
.redirect-to-subscriptions {
  color: #000;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 600;
  width: fit-content;
}
a {
  text-decoration: none;
}

.container {
  height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
}

.container {
  margin: 0;
  max-width: 2560px;
}

.school-online {
  padding-left: 7%;
  @media (max-width: 1024px) {
    padding-left: 3.5%;
  }
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
  .path {
    a {
      color: #000;
      text-decoration: none;
      font-weight: 500;
    }
    @media (max-width: 425px) {
      margin-top: -2rem;
      padding-bottom: 20px !important;
    }
  }

  section {
    padding: 0 0 1rem;
  }

  .whatGivesSubscription {
    p {
      font-size: 1.05rem;
      width: 78%;
      @media (max-width: 425px) {
        font-size: 0.95rem;
        width: 100%;
        text-align: justify;
      }
    }
  }

  .whatGivesSubscription h2,
  .whyItsWorth h2 {
    text-align: left;
  }

  .whyItsWorth {
    h3 {
      font-size: 1.4rem;
      @media (max-width: 768px) {
        font-size: 1.25rem;
      }
      @media (max-width: 425px) {
        font-size: 1.2rem;
      }
    }
    p {
      font-size: 1.05rem;
      width: 78%;
      @media (max-width: 425px) {
        font-size: 0.95rem;
        width: 100%;
        text-align: justify;
      }
    }
  }
}

h2 {
  font-size: 2.4rem;
  padding-bottom: 1.5rem;
  @media (max-width: 768px) {
    font-size: 2.1rem;
  }
  @media (max-width: 425px) {
    font-size: 1.7rem;
    text-align: center;
    padding-bottom: 1rem;
  }
}

p {
  color: #000;
}

.subscription {
  .card-container {
    display: flex;
    justify-content: center;
    @media (max-width: 1330px) {
      flex-wrap: wrap;
    }
  }

  .text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 7rem;
    @media (max-width: 768px) {
      padding-bottom: 3.5rem;
    }
    h2 {
      width: 50%;
      @media (max-width: 768px) {
        width: 90%;
        text-align: left;
      }
    }
  }
  h3 {
    text-align: center;
    padding: 2rem 0;
  }
  .active {
    text-align: center;
    margin-bottom: 1rem;
    p {
      color: #fff;
      background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
      border-radius: 8px;
      padding: 0.55rem 0.85rem;
      font-size: 1.1rem;
      width: 300px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
.courses-container {
  display: flex;
  justify-content: center;
}

.exercises-container {
  max-width: 1680px;
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  .drop-btn {
    background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
    border-radius: 8px;
    padding: 0.5rem 1.2rem;
    color: #fff;
    margin-bottom: 2rem;
    margin-left: 0.5rem;
    cursor: pointer;
    .button-content {
      display: flex;
      align-items: center;
      .logo {
        margin-right: 9px;
      }
      .arrow {
        margin-left: 18px;
      }
    }
  }
}

.courses {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 4rem;
}

.course-container-center {
  display: flex;
}

.subscription-date {
  right: 1rem;
  top: -1rem;
  font-size: 13px;
}

// .btn-gradient {
//   margin-top: 50px;
//   padding: 15px 25px;
//   border-radius: 30px;
//   cursor: pointer;
//   font-size: 1.2rem;
// }
// .header {
//   width: 100%;
//   text-align: center;
//   position: relative;
//   margin-top: 2rem;
// }
/* ADDITIONAL DESCRIPTION*/
.card-desc {
  width: 70vw;
  margin-top: 5rem;
  margin-bottom: 40px;
  padding: 40px 70px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px;
  display: block;
  text-align: center;
}
.title-one {
  font-size: 54px;
  font-weight: 700;
  background: -webkit-linear-gradient(
    90deg,
    rgba(179, 106, 103, 1) 0%,
    rgba(223, 44, 38, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.under-title {
  font-size: 20px;
  font-weight: 500;
}

.title-two {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 3rem;
  position: relative;
}
.title-two::after {
  content: "";
  width: 8px;
  height: 8px;
  background: -webkit-linear-gradient(
    90deg,
    rgba(179, 106, 103, 1) 0%,
    rgba(223, 44, 38, 1) 100%
  );
  position: absolute;
  border-radius: 30px;
  top: 35%;
  right: 100%;
  margin: 0 10px;
}
.btn-courses {
  width: fit-content;
}
.atag {
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-of-courses {
  margin-bottom: 50px;
}
.login-btn {
  font-size: 1rem;
}
.position {
  position: absolute;
  padding: 30px;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #1d1616d8;
}
.video-with-description {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.video-main {
  height: 100%;
  width: 360px;
}
.video-description {
  font-size: 32px;
  font-weight: bold;
  max-width: 560px;
}

@media screen and (max-width: 1170px) {
  .coursesContainter {
    display: flex;
    flex-direction: column;
    flex-gap: 20px;
  }
}

@media screen and (max-width: 1050px) {
  .card-desc {
    width: 80vw;
  }
}
@media screen and (max-width: 950px) {
  .card-desc {
    width: 90vw;
  }
}
@media screen and (max-width: 820px) {
  .title-one {
    font-size: 44px;
  }
  .under-title {
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  .no-courses {
    text-align: center;
    margin: 0 5%;
  }
  .header img {
    height: 300px;
    width: fit-content;
    object-fit: cover;
  }
  .card-desc {
    width: 100vw;
    padding: 40px 30px;
  }
  .under-title {
    font-size: 15px;
  }
  p {
    font-size: 0.9rem;
  }
  .title-two {
    font-size: 1.1rem;
  }
  .title-one {
    font-size: 36px;
    display: block;
    display: -webkit-box;
  }
}

.list {
  margin: 0 0 1rem 0;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 4rem;

  li {
    cursor: pointer;
    background: linear-gradient(270deg, #c4a2a2 0%, #b28a8a 90.06%);
    border-bottom: none;
    padding: 10px 18px;
    text-align: center;
    margin: 0 0.5rem;
    font-size: 0.95rem;
    color: #eee;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
  li::before {
    content: "";
  }
  li {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.selectedCurrent {
  font-weight: bold;
  background: rgb(86, 58, 58) !important;
}
.search-buttons {
  display: flex;
  align-items: center;
  gap: 4rem;
}
.dropdown,
.search-buttons {
  width: 1300px;
}
@media (max-width: 1362px) {
  .search-buttons {
    gap: 0.5rem !important;
    margin: 0 auto;
    max-width: 980px;
  }
  .drop-btn {
    margin-left: 0px !important;
  }
  .courses {
    justify-content: center;
  }
  .dropdown {
    width: 980px;
    margin: 0 auto;
  }
  .list li {
    margin-left: 0px;
  }
}

@media (max-width: 1030px) {
  .search-buttons {
    max-width: 650px;
  }
  .dropdown {
    width: 650px;
  }
}

@media (max-width: 700px) {
  .search-buttons {
    max-width: 323px;
  }
  .dropdown {
    width: 323px;
  }
}

@media screen and (max-width: 400px) {
  .search-buttons {
    max-width: 283px;
  }
  .dropdown {
    max-width: 283px;
  }
}
</style>
