// eslint-disable-next-line no-unused-vars
import {
  required,
  email,
  max,
  min_value,
  numeric,
  oneOf,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "To pole nie może być puste!",
});

extend("max", {
  ...max,
  message: "To pole może zawierać maksymalnie {lenght} znaków!",
});

extend("email", {
  ...email,
  message: "Podany adres e-mail jest niepoprawny!",
});

extend("numeric", {
  ...numeric,
  message: "To pole może zawierać jedynie liczbę!",
});

extend("phone", {
  validate(value) {
    if (value.length === 9) return true;
  },
  message: "Błędny numer telefonu",
});

extend("min_value", {
  ...min_value,
  message: "Minimalna wartość dla tego pola to: {min}!",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Hasła muszą być identyczne!",
});

extend("oneOf", {
  ...oneOf,
});

extend("NIP", {
  validate(value) {
    value = value.replace(/ -/g, "");
    let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    let controlNumber = parseInt(value.substring(9, 10));
    let weightCount = weight.length;
    for (let i = 0; i < weightCount; i++) {
      sum += parseInt(value.substr(i, 1)) * weight[i];
    }
    return sum % 11 === controlNumber;
  },
  message: "Błędny numer NIP",
});

extend("link", {
  validate(value) {
    let url;
    try {
      url = new URL(value);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  },
  message: "Niepoprawny link"
})
