<template>
  <v-row justify="center">
    <v-dialog
      max-width="700px"
      :value="isVisible"
      persistent
      @keydown.esc="closeModal"
    >
      <v-card>
        <ValidationObserver ref="obs" v-slot="{ invalid }" mode="eager">
          <v-card-title>
            <span class="headline"
              >{{
                !isWorkshops
                  ? `${updated ? "Edytuj" : "Dodaj"} subskrypcję czasową`
                  : `${updated ? "Edytuj" : "Dodaj"}  warsztaty`
              }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="number"
                    rules="required"
                    v-model.number="price"
                    label="Cena"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="number"
                    v-model.number="numberOfDays"
                    label="Liczba dni subskrypcji"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <div class="ml-1 mb-12 mt-8" v-if="withDiscount">
                <p>
                  <b>Zniżki</b>
                  <v-btn
                    @click="addNextDiscount"
                    class="ml-2"
                    fab
                    x-small
                    color="green"
                  >
                    <font-awesome-icon icon="plus" />
                  </v-btn>
                </p>
              </div>

              <v-row v-if="withDiscount" class="mb-12">
                <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                  <v-row
                    v-for="(discount, index) in discounts"
                    :key="`codeValue${index}`"
                  >
                    <v-col
                      cols="12"
                      sm="5"
                      style="padding-top: 0; padding-bottom: 0"
                    >
                      <VTextFieldWithValidation
                        type="number"
                        v-model.number="discount.value"
                        label="Wartość zniżki w procentach"
                        color="indigo"
                        outlined
                        dense
                        rules="min_value:0.01"
                        @newValueAfterChange="
                          handleNewDiscountValue($event, index)
                        "
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="5"
                      style="padding-top: 0; padding-bottom: 0"
                    >
                      <VTextFieldWithValidation
                        v-model="discount.code"
                        label="Kod zniżkowy"
                        color="indigo"
                        outlined
                        dense
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="2"
                      style="padding-top: 0; padding-bottom: 0"
                    >
                      <v-btn
                        @click="removeDiscount(discount, index)"
                        class="ml-2"
                        fab
                        x-small
                        color="red"
                      >
                        <font-awesome-icon icon="minus" />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="isWorkshops"
                  cols="12"
                  sm="4"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    v-model="maxNumberOfMembers"
                    label="Liczba uczestników"
                    color="indigo"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12" sm="12" v-if="discountError">
                  <small class="error--text"
                    >Zniżka nie może być większa od ceny</small
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextFieldWithValidation
                    type="text"
                    v-model="name"
                    label="Nazwa pakietu"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  style="padding-top: 0; padding-bottom: 0"
                >
                  <VTextAreaWithValidation
                    v-model="description"
                    label="Opis pakietu"
                    color="indigo"
                    outlined
                    dense
                    maxLength="255"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text v-on:click="closeModal">
              <font-awesome-icon icon="times" class="mr-1" />
              Anuluj
            </v-btn>
            <v-btn
              color="success"
              text
              :disabled="invalid"
              v-on:click="addPackage"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              Zapisz zmiany
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation.vue";

export default {
  name: "AddTimePackageModal",
  props: [
    "isVisible",
    "packageData",
    "withDiscount",
    "isWorkshops",
    "timePackageType",
    "isExtendedPackage",
  ],

  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VTextAreaWithValidation,
  },

  watch: {
    packageData() {
      if (this.packageData) {
        this.packageData.discountCodes.forEach((code) => {
          this.discounts.push({
            code: code.code,
            value: code.value,
            id: code.id,
          });
        });
      }
    },
  },
  data: () => ({
    packageId: null,
    price: 0,
    input: "",
    checkbox: false,
    items: [],
    itemsError: false,
    discountError: false,
    updated: false,
    numberOfDays: 0,
    name: "",
    description: "",
    discountCode: "",
    maxNumberOfMembers: 0,
    discount: 0,
    type: "",
    discounts: [],
    discountsToRemove: [],
  }),
  updated() {
    if (this.packageData) {
      const {
        id,
        price,
        numberOfDays,
        packageItems,
        name,
        description,
        discount,
        maxNumberOfMembers,
        discountCode,
        copy,
        type,
      } = this.packageData;
      this.packageId = id;
      this.price = price ? price : 0;
      this.items = packageItems ? packageItems.map((i) => i.link) : [];
      this.updated = true;
      this.numberOfDays = numberOfDays ? numberOfDays : 0;
      this.name = name;
      this.description = description;
      this.discountCode = discountCode;
      this.discount = discount;
      this.maxNumberOfMembers = maxNumberOfMembers;
      if (copy) {
        this.updated = null;
      }
      this.type = type;
    }
  },
  methods: {
    handleNewDiscountValue(value, index) {
      if (value && Number(value) > 1) {
        this.discounts[index].value = 0.99;
      }
    },
    addNextDiscount() {
      this.discounts.push({
        code: "",
        value: 0.0,
      });
    },
    removeDiscount(discount, index) {
      if (discount.id) {
        this.discountsToRemove.push(discount);
      }
      this.discounts.splice(index, 1);
    },
    closeModal() {
      this.price = 0;
      this.updated = false;
      this.description = "";
      this.numberOfDays = 0;
      this.name = "";
      this.discount = 0;
      this.discountCode = "";
      this.maxNumberOfMembers = 0;
      this.discounts = [];
      this.discountsToRemove = [];
      this.$emit("closeModal");
    },

    async handleRemoveDiscountCodes() {
      if (this.discountsToRemove && this.discountsToRemove.length > 0) {
        for await (const discount of this.discountsToRemove) {
          await this.$store.dispatch("discountCode/deleteDiscountCode", {
            id: discount.id,
          });
        }
      }
    },

    async handleAddAndUpdateDiscountCodes(timePackageId) {
      if (this.discounts) {
        for await (const discount of this.discounts) {
          const { id, code, value } = discount;
          if (!id) {
            await this.$store.dispatch("discountCode/addDiscountCode", {
              data: {
                timePackageId,
                code: code,
                value,
              },
            });
          } else {
            await this.$store.dispatch("discountCode/updateDiscountCode", {
              discountCodeId: id,
              data: {
                timePackageId,
                code: code,
                value,
              },
            });
          }
        }
      }
    },
    async addPackage() {
      const codesArray = this.discounts.map(function (item) {
        return item.code;
      });
      let moreThanOneSameCodes = codesArray.some(function (item, idx) {
        return codesArray.indexOf(item) != idx;
      });
      if (moreThanOneSameCodes) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message: "Wszystkie kody zniżkowe muszą być unikalne",
        });
        return;
      }

      let object = {
        price: this.price,
        name: this.name,
        description: this.description,
        numberOfDays: this.numberOfDays ? this.numberOfDays : 0,
        maxNumberOfMembers:
          this.maxNumberOfMembers === 0 ? 20 : this.maxNumberOfMembers,
      };
      if (this.isWorkshops) {
        if (this.updated) {
          object.type = this.packageData.type
            ? this.packageData.type
            : "WORKSHOPS";
        } else {
          object.type = "WORKSHOPS";
        }
      }
      if (this.discount >= this.price) {
        this.discountError = true;
        setTimeout(() => {
          this.discountError = false;
        }, 2000);
      } else {
        if (this.discount !== 0 && this.discountCode !== "") {
          object.discount = this.discount;
          object.discountCode = this.discountCode;
        } else {
          object.discount = null;
          object.discountCode = null;
        }
        if (!this.isExtendedPackage) {
          if (this.updated) {
            await this.handleRemoveDiscountCodes();
            await this.handleAddAndUpdateDiscountCodes(this.packageData.id);
            await this.$store
              .dispatch("timePackages/updatePackage", {
                packageId: this.packageId,
                data: object,
                type: this.timePackageType
                  ? this.timePackageType
                  : this.isWorkshops
                  ? "all_workshops"
                  : null,
              })
              .then(() => {
                this.closeModal();
              });
          } else {
            await this.$store
              .dispatch("timePackages/addPackage", {
                data: object,
                type: this.timePackageType
                  ? this.timePackageType
                  : this.isWorkshops
                  ? "all_workshops"
                  : null,
              })
              .then(async (addedPackage) => {
                await this.handleAddAndUpdateDiscountCodes(addedPackage.id);
                await this.$store.dispatch("timePackages/fetchAllTimePackages");
                this.closeModal();
              });
          }
        } else {
          if (this.updated) {
            await this.$store
              .dispatch("timePackages/updatePackageExtended", {
                packageId: this.packageId,
                data: object,
              })
              .then(() => {
                this.closeModal();
              });
          } else {
            await this.$store
              .dispatch("timePackages/addPackageExtended", {
                data: object,
              })
              .then(() => {
                this.closeModal();
              });
          }
        }
      }
      this.$emit("timePackageUpdated");
    },
  },
};
</script>
<style type="scss" scoped>
.outlined-form-section {
  position: relative;

  border-color: rgba(0, 0, 0, 0.38);
  border-width: 2px;
}

.outlined-form-section-title {
  top: -15px;
  left: 10px;
  position: absolute;

  padding: 0.2rem 0.5rem;

  background-color: white;

  font-size: 12px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
