<template>
  <v-container class="container">
    <h1>Dokonaj płatności</h1>
    <v-container class="pa-0">
      <ValidationObserver v-slot="{ invalid, handleSubmit }">
        <v-form @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
          <v-checkbox
            dark
            color="white"
            v-model="invoiceForCompany"
            :label="`Faktura na firmę`"
            class="mb-4"
            :disabled="loading || transaction.show"
          ></v-checkbox>
          <VTextFieldWithValidation
            rules="email|required"
            label="E-mail"
            type="email"
            color="white"
            dark
            :disabled="loading || transaction.show"
            v-model.trim="email"
            autocomplete="none"
          />
          <VTextFieldWithValidation
            rules="email|required"
            label="Potwierdź e-mail"
            type="email"
            color="white"
            dark
            :disabled="loading || transaction.show"
            v-model.trim="confirmEmail"
            autocomplete="none"
          />

          <VTextFieldWithValidation
            rules="email|RegexEmail"
            label="Email osoby obdarowywanej (opcjonalnie)"
            type="email"
            color="white"
            dark
            :disabled="loading || transaction.show"
            v-model="giftRecipientEmail"
            autocomplete="none"
            v-if="selectedGiftCard"
          />

          <VTextFieldWithValidation
            :rules="!invoiceForCompany ? 'required' : ''"
            v-if="!invoiceForCompany"
            :disabled="loading || transaction.show"
            label="Imię i nazwisko"
            type="text"
            color="white"
            dark
            v-model="firstLastname"
          />

          <VTextFieldWithValidation
            v-if="selectedOrder"
            rules="numeric|phone"
            label="Numer telefonu"
            color="white"
            dark
            :disabled="loading || transaction.show"
            v-model="phone"
          />

          <VTextFieldWithValidation
            :rules="invoiceForCompany ? 'required' : ''"
            v-if="invoiceForCompany"
            label="Nazwa firmy"
            color="white"
            dark
            :disabled="loading || transaction.show"
            v-model="companyName"
          />
          <VTextFieldWithValidation
            v-if="invoiceForCompany"
            rules="numeric|NIP"
            label="Numer NIP"
            color="white"
            dark
            :disabled="loading || transaction.show"
            v-model="companyNIP"
          />
          <VTextAreaWithValidation
            :rules="invoiceForCompany ? 'required' : ''"
            :label="'Ad\u200Bres'"
            color="white"
            dark
            outlined
            dense
            :disabled="loading || transaction.show"
            v-model="address"
            :height="80"
            v-if="invoiceForCompany"
          />
          <VTextFieldWithValidation
            v-if="!isCoursesTicket"
            label="Kod rabatowy"
            color="white"
            dark
            :disabled="loading || transaction.show"
            v-model="discountCode"
          />

          <v-checkbox
            v-if="
              profile.refLinksAmount &&
              selectedPackage &&
              !selectedGiftCard &&
              !selectedOrder
            "
            dark
            color="white"
            v-model="useRefLinksAmount"
            :label="`Chcę wykorzystać rabat z poleceń Platformy`"
            :disabled="loading || transaction.show"
          ></v-checkbox>

          <div v-if="selectedOrder" class="mb-8">
            <v-checkbox
              dark
              color="white"
              v-model="markAllCheckboxes"
              :label="`Zaznacz wszystkie obowiązkowe`"
              :disabled="loading || transaction.show"
              @change="handleMarkAllCheckboxes"
            ></v-checkbox>
            <v-checkbox
              dark
              color="white"
              v-model="checkbox1"
              :label="`* Oświadczam, iż jestem osobą pełnoletnią posiadającą pełną zdolność do czynności prawnych i składania oświadczeń woli.`"
              :disabled="loading || transaction.show"
            ></v-checkbox>

            <v-checkbox
              dark
              color="white"
              v-model="checkbox2"
              :label="`* Oświadczam, że jestem świadoma/y swojego stanu zdrowia i nie mam żadnych przeciwskazań zdrowotnych do bezpiecznego dla zdrowia udziału w zajęciach organizowanych przez Daga Joga. Przystępuję do zajęć na własną odpowiedzialność, a Organizator – Daga Joga – nie ponosi odpowiedzialności za odniesione przeze mnie kontuzje i uszczerbki na zdrowiu, jak również ewentualny negatywny wpływ mojego udziału w zajęciach na stan mojego zdrowia.`"
              :disabled="loading || transaction.show"
            ></v-checkbox>

            <v-checkbox
              dark
              color="white"
              v-model="checkbox3"
              :label="`* Oświadczam, że nie jestem w ciąży.`"
              :disabled="loading || transaction.show"
            ></v-checkbox>

            <v-checkbox
              dark
              color="white"
              v-model="newsletter"
              :label="`Zapisz się do newslettera`"
              :disabled="loading || transaction.show"
            ></v-checkbox>
            <i
              >* W przypadku posiadania przez osobę zamierzającą uczestniczyć w
              zajęciach organizowanych przez Daga Joga jakichkolwiek stanów po
              przebytych urazach lub schorzeniach kostno-stawowych, układu
              mięśniowego lub ścięgien oraz ośrodkowego układu nerwowego, przed
              złożeniem Oświadczenia należy poinformować Organizatora na adres
              e-mail
              <a class="checkbox-email" href="mailto:daga@dagajoga.pl"
                >daga@dagajoga.pl</a
              >. Organizator ma prawo odmówić Uczestnikowi udziału w zajęciach
              bez podania przyczyny.
            </i>
          </div>

          <div class="submit-content">
            <h4 v-if="transaction.data.p24_amount">
              Całkowity koszt:
              {{ (transaction.data.p24_amount / 100).toFixed(2) }} zł
            </h4>
            <h4 v-if="!transaction.data.p24_amount && selectedPackage">
              Całkowity koszt:
              {{ selectedPackage.price }}
              zł
            </h4>
            <v-btn
              type="submit"
              class="mt-5 mb-3 text--white white--text"
              color="grey darken-2"
              :disabled="invalid || transaction.show"
              :loading="loading"
            >
              Zatwierdź dane
            </v-btn>
            <br />
            <small>
              <u
                class="mt-5 reset"
                color="grey darken-2"
                v-show="!invalid || !transaction.show"
                @click="resetForm"
              >
                Zresetuj formularzzz
              </u>
            </small>
          </div>
        </v-form>

        <form
          :action="PAYMENT_URL"
          method="post"
          id="paymentForm"
          class="justify-center d-flex align-center"
        >
          <input
            type="hidden"
            name="p24_time_limit"
            :value="
              transaction.data.p24_expiration_time
                ? transaction.data.p24_expiration_time
                : 60
            "
          />
          <input
            type="hidden"
            name="p24_session_id"
            :value="transaction.data.p24_session_id"
          />
          <input
            type="hidden"
            name="p24_merchant_id"
            :value="transaction.data.p24_merchant_id"
          />
          <input
            type="hidden"
            name="p24_pos_id"
            :value="transaction.data.p24_pos_id"
          />
          <input
            type="hidden"
            name="p24_amount"
            :value="transaction.data.p24_amount"
          />
          <input
            type="hidden"
            name="p24_currency"
            :value="transaction.data.p24_currency"
          />
          <input
            type="hidden"
            name="p24_description"
            :value="transaction.data.p24_description"
          />
          <input
            type="hidden"
            name="p24_country"
            :value="transaction.data.p24_country"
          />
          <input
            type="hidden"
            name="p24_email"
            :value="transaction.data.p24_email"
          />
          <input
            type="hidden"
            name="p24_url_return"
            :value="transaction.data.p24_url_return"
          />
          <input
            type="hidden"
            name="p24_url_status"
            :value="transaction.data.p24_url_status"
          />
          <input
            type="hidden"
            name="p24_api_version"
            :value="transaction.data.p24_api_version"
          />
          <input
            type="hidden"
            name="p24_sign"
            :value="transaction.data.p24_sign"
          />
          <v-btn
            v-if="transaction.show"
            type="button"
            class="mt-5 mb-5 submit-button margin-this"
            :loading="loading"
            @click="handleStartedTimePackagePayment"
          >
            Dokonaj płatności
          </v-btn>
        </form>
      </ValidationObserver>

      <div class="agreements">
        <p>
          Jeśli chcesz skorzystać z płatności
          <img
            src="./pp.svg"
            alt="
            subskrypcja DagaJoga
            anatomia jogi.
            joga stanie na rękach,
            Nauli,
            joga,
            joga online,
            ćwiczenia online,
            filmy jogi,
            praktyka dla początkujących,
            szkoła online,
            praktyka wzmacniająca,
            joga zdrowy,
            joga video
            "
            loading="lazy"
          />, kliknij przycisk <span class="bold"> dokonaj płatności </span> a
          następnie przejdź do zakładki <span class="bold"> inne. </span>
        </p>
        <p>
          Składając zamówienie, wyrażasz zgodę na dostarczenie treści cyfrowych
          lub świadczenie usługi przed upływem terminu do odstąpienia od umowy
          zawartej na odległość. Potwierdzasz, że tym samym tracisz prawo do
          odstąpienia od umowy.
        </p>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import VTextFieldWithValidation from "../inputs/VTextFieldWithValidation";
import { ValidationObserver } from "vee-validate";
import { PAYMENT_URL } from "../../axios.js";
import VTextAreaWithValidation from "../inputs/VTextAreaWithValidation";
export default {
  name: "PaymentForm",

  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    VTextAreaWithValidation,
  },
  props: {
    selectedPackage: Object,
    profile: Object,
    selectedGiftCard: Object,
    selectedOrder: Object,
    isCoursesTicket: Boolean,
    isExtendedSubscription: Boolean,
  },

  data() {
    return {
      email: "",
      confirmEmail: "",
      firstLastname: "",
      discountCode: "",
      companyName: "",
      companyNIP: "",
      loading: false,
      PAYMENT_URL,
      newsletter: true,
      invoiceForCompany: false,
      address: "",
      giftRecipientEmail: "",
      phone: "",
      markAllCheckboxes: false,
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      useRefLinksAmount: false,
    };
  },
  watch: {
    invoiceForCompany() {
      if (!this.companyNIP) {
        this.companyNIP = " ";
      }
    },
  },
  computed: {
    transaction: function () {
      return this.$store.getters["payment/getTransInfo"];
    },
    isPackage24h() {
      return this.$route.query.recording;
    },
  },
  mounted() {
    this.$store.commit("payment/SET_INIT_VALUES");
    if (this.profile) {
      this.email = this.profile.email;
      this.confirmEmail = this.profile.email;
      if (this.profile.name) this.firstLastname = this.profile.name;
      if (this.profile.company) this.companyName = this.profile.company;
      if (this.profile.nip) this.companyNIP = this.profile.nip;
      if (this.profile.address) this.address = this.profile.address;
    }
  },
  methods: {
    async handleStartedTimePackagePayment() {
      try {
        if (
          this.selectedPackage &&
          !this.selectedGiftCard &&
          !this.selectedOrder &&
          this.transaction.data.timePackageOrderId
        ) {
          await this.$store.dispatch(
            "payment/handleStartedTimePackageTransaction",
            this.transaction.data.timePackageOrderId
          );
        }

        // Ręczne wysłanie formularza po zakończeniu operacji
        document.getElementById("paymentForm").submit();
      } catch (error) {
        this.$store.dispatch("snackbar/showSnackbar", {
          color: "red",
          message:
            "Nie udało się rozpocząć płatności. Skontaktuj się z administratorem",
        });
      }
    },
    handleMarkAllCheckboxes() {
      if (this.markAllCheckboxes) {
        this.checkbox1 = true;
        this.checkbox2 = true;
        this.checkbox3 = true;
      } else {
        this.checkbox1 = false;
        this.checkbox2 = false;
        this.checkbox3 = false;
      }
    },
    onSubmit() {
      if (
        this.selectedOrder &&
        (!this.checkbox1 || !this.checkbox2 || !this.checkbox3)
      ) {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Wymagane potwierdzenie wszystkich obowiązkowych oświadczeń",
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        return;
      }
      if (
        (this.companyName && this.companyNIP && this.invoiceForCompany) ||
        (this.firstLastname && !this.invoiceForCompany)
      ) {
        this.loading = true;
        if (
          this.selectedPackage &&
          !this.selectedGiftCard &&
          !this.selectedOrder
        ) {
          let object = {
            email: this.email,
            discountCode: this.discountCode.toLowerCase().trim(),
            name: this.firstLastname,
            timePackageId: this.selectedPackage.id,
            newsletter: this.newsletter,
            confirmEmail: this.confirmEmail,
            companyName: this.companyName,
            nip: this.companyNIP,
            address: this.address,
            invoiceForCompany: this.invoiceForCompany,
            isExtendedSubscription: this.isExtendedSubscription,
            useRefLinksAmount: this.useRefLinksAmount,
          };
          this.$store
            .dispatch("payment/createTimePackageOrder", object)
            .then(() => {
              this.loading = false;
            });
        } else if (this.selectedGiftCard) {
          let object = {
            email: this.email,
            discountCode: this.discountCode.toLowerCase().trim(),
            name: this.firstLastname,
            newsletter: this.newsletter,
            confirmEmail: this.confirmEmail,
            companyName: this.companyName,
            nip: this.companyNIP,
            address: this.address,
            invoiceForCompany: this.invoiceForCompany,
            giftRecipientEmail: this.giftRecipientEmail,
            giftCardId: this.selectedGiftCard.id,
          };
          this.$store
            .dispatch("payment/createUserGiftCardOrder", object)
            .then(() => {
              this.loading = false;
            });
        } else if (this.selectedOrder) {
          let object = {
            email: this.email,
            discountCode: this.discountCode.toLowerCase().trim(),
            name: this.firstLastname,
            phone: this.phone,
            packageId: this.selectedOrder.id,
            newsletter: this.newsletter,
            confirmEmail: this.confirmEmail,
            companyName: this.companyName,
            nip: this.companyNIP,
            address: this.address,
            invoiceForCompany: this.invoiceForCompany,
            recording: this.isPackage24h ? true : null,
          };

          if (this.isCoursesTicket) {
            this.$store
              .dispatch("coursesTickets/createOrder", object)
              .then((data) => {
                this.$store.dispatch(
                  "payment/startTransactionForCoursesTicket",
                  data.id
                );
                this.loading = false;
              });
          } else {
            this.$store.dispatch("payment/createOrder", object).then(() => {
              this.loading = false;
            });
          }
        }
      } else {
        this.$store.dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              'Musisz wypełnić przynajmniej jedno z następujących: "Imię i Nazwisko" bądź "Nazwa firmy" i "Numer NIP"',
          },
          { root: true }
        );
        this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
        this.resetForm();
      }
    },
    destroyed() {
      this.$store.commit("payment/SET_INIT_VALUES");
    },

    resetForm() {
      this.$store.commit("payment/SET_INIT_VALUES");
      this.email = "";
      this.confirmEmail = "";
      this.firstLastname = "";
      this.discountCode = "";
      this.companyName = "";
      this.companyNIP = "";
      this.address = "";
      this.giftRecipientEmail = "";
    },
  },
};
</script>

<style scoped>
.checkbox-email {
  text-transform: lowercase;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.reset {
  text-transform: uppercase;
  cursor: pointer;
}
.custom-placeholer-color input::placeholder {
  color: red !important;
  opacity: 1;
}
.container {
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 40px 70px;
}
/*HEADER */
h1 {
  margin-bottom: 30px;
}

/* CHECKBOX*/
.checkbox {
  padding: 5px;
}
div /deep/ .v-text-field {
  width: 80%;
}

/* CONFIRMATION SECTION */
.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

h3 {
  font-size: 0.9rem;
  font-weight: 600;
}

/* FORM RESET */
a {
  text-transform: uppercase;
  border-bottom: 1px solid white;
}
.a-tag {
  color: #fff;
  font-size: 0.7rem;
}

/* AGREEMENTS SECTION */
.agreements {
  width: 90%;
  margin: auto;
}
p {
  font-size: 0.7rem;
}
/* PAYPAL IMG */
img {
  width: 17%;
  margin: 0 5px;
  vertical-align: -25%;
}

/* BOLDING SPAN */
.bold {
  text-transform: uppercase;
  font-weight: 900;
}
.submit-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* MEDIA QUERIES ----------------------------------- */
@media screen and (max-width: 1100px) {
  div /deep/ .v-text-field {
    width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding: 20px 40px;
  }
  div /deep/ .v-text-field {
    width: 100%;
  }
  p {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 700px) {
  div /deep/ .v-text-field {
    width: 80%;
  }
  p {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 400px) {
  div /deep/ .v-text-field {
    width: 100%;
  }
}
</style>
