import axios from "../axios";

const theoryPracticeWorkshops = {
  namespaced: true,

  state: () => ({
    theoryPracticeWorkshops: [],
    theoryPracticeWorkshop: null,
    theoryPracticeWorkshopDeleted: null,
    theoryPracticeWorkshopsItems: [],
    theoryPracticeWorkshopsItem: null,
    theoryPracticeWorkshopItemDeleted: null,
    theoryPracticeWorkshopIsPaid: false,
    newMemberAdded: false,
    userTariffWorkshops: [],
    members: [],
    duplicateWorkshop: null,
  }),

  mutations: {
    SET_THEORY_PRACTICE_WORKSHOPS(state, theoryPracticeWorkshops) {
      state.theoryPracticeWorkshops = theoryPracticeWorkshops;
    },
    SET_THEORY_PRACTICE_WORKSHOP(state, theoryPracticeWorkshop) {
      state.theoryPracticeWorkshop = theoryPracticeWorkshop;
    },
    SET_THEORY_PRACTICE_WORKSHOP_DELETED(state, status) {
      state.theoryPracticeWorkshopDeleted = status;
    },
    SET_THEORY_PRACTICE_WORKSHOPS_ITEMS(state, theoryPracticeWorkshopsItems) {
      state.theoryPracticeWorkshopsItems = theoryPracticeWorkshopsItems;
    },
    SET_THEORY_PRACTICE_WORKSHOP_ITEM(state, theoryPracticeWorkshopItem) {
      state.theoryPracticeWorkshopItem = theoryPracticeWorkshopItem;
    },
    SET_THEORY_PRACTICE_WORKSHOP_ITEM_DELETED(state, status) {
      state.theoryPracticeWorkshopItemDeleted = status;
    },
    SET_THEORY_PRACTICE_WORKSHOP_IS_PAID(state, status) {
      state.theoryPracticeWorkshopIsPaid = status;
    },
    SET_NEW_MEMBER_ADDED(state, status) {
      state.newMemberAdded = status;
    },
    SET_MEMBERS(state, members) {
      state.members = members;
    },
    SET_DUPLICATE_WORKSHOP(state, workshop) {
      state.duplicateWorkshop = workshop;
    },
  },

  actions: {
    async duplicateWorkshop({ commit }, data) {
      try {
        const response = await axios.post(
          `theoryPracticeWorkshops/${data.id}/duplicate`
        );
        commit("SET_DUPLICATE_WORKSHOP", response.data);
        return response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchTheoryPracticeWorkshopMembers({ commit }, id) {
      try {
        const response = await axios.get(
          `theoryPracticeWorkshops/${id}/members`
        );
        commit("SET_MEMBERS", response.data);
      } catch (err) {
        commit("SET_MEMBERS", []);
        console.log(err);
      }
    },

    async fetchTheoryPracticeWorkshop({ commit }, id) {
      try {
        const response = await axios.get(`theoryPracticeWorkshops/${id}`);
        commit("SET_THEORY_PRACTICE_WORKSHOP", response.data);
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP", null);
        console.log(err);
      }
    },

    async checkUserPaidForWorkshop({ commit, dispatch }, id) {
      try {
        const response = await axios.get(
          `theoryPracticeWorkshops/${id}/checkUserPaidForWorkshop`
        );

        if (response && response.data) {
          commit("SET_THEORY_PRACTICE_WORKSHOP_IS_PAID", true);
          return true;
        } else {
          commit("SET_THEORY_PRACTICE_WORKSHOP_IS_PAID", false);
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Musisz być zalogowany oraz posiadać wykupiony dostęp do warsztatów",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });

          return false;
        }
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_IS_PAID", false);
        console.log(err);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Musisz być zalogowany oraz posiadać wykupiony dostęp do warsztatów",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return false;
      }
    },

    async fetchTheoryPracticeWorkshopWithNotActive({ commit }, payload) {
      try {
        let params = {};
        if (payload) {
          const { type } = payload;
          if (type) {
            params.type = type;
          }
        }

        const response = await axios.get(
          `theoryPracticeWorkshops/withNotActive`,
          { params }
        );
        commit("SET_THEORY_PRACTICE_WORKSHOPS", response.data);
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOPS", null);
        console.log(err);
      }
    },

    async createTheoryPracticeWorkshop({ commit }, object) {
      const { data } = object;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.post(`theoryPracticeWorkshops`, formData);
        commit("SET_THEORY_PRACTICE_WORKSHOP", response.data);
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP", null);
        console.log(err);
      }
    },

    async editTheoryPracticeWorkshop({ commit }, object) {
      const { data, id } = object;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.put(
          `theoryPracticeWorkshops/${id}`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP", response.data);
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP", null);
        console.log(err);
      }
    },

    async deleteTheoryPracticeWorkshop({ commit, dispatch }, data) {
      try {
        await axios.delete(`theoryPracticeWorkshops/${data.id}`);
        commit("SET_THEORY_PRACTICE_WORKSHOP_DELETED", true);
        await dispatch("fetchTheoryPracticeWorkshopWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_DELETED", false);
        console.log(err);
      }
    },

    async editTheoryPracticeWorkshopEndDate({ commit }, object) {
      const { endDate, id } = object;

      try {
        const response = await axios.put(
          `theoryPracticeWorkshops/${id}/endDate`,
          {
            endDate,
          }
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP", response.data);
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP", null);
        console.log(err);
      }
    },

    async deleteTheoryPracticeWorkshopItem({ commit, dispatch }, data) {
      try {
        await axios.delete(
          `theoryPracticeWorkshops/${data.theoryPracticeWorkshopId}/items/${data.id}`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_ITEM_DELETED", true);
        await dispatch("fetchAllWorkshopsItems", { type: data.type });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_ITEM_DELETED", false);
        console.log(err);
      }
    },

    async markTheoryPracticeWorkshopAsActive({ commit, dispatch }, data) {
      try {
        const response = await axios.put(
          `theoryPracticeWorkshops/${data.id}/active`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP", response.data);
        await dispatch("fetchTheoryPracticeWorkshopWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP", null);
        console.log(err);
      }
    },

    async markTheoryPracticeWorkshopAsNotActive({ commit, dispatch }, data) {
      try {
        const response = await axios.put(
          `theoryPracticeWorkshops/${data.id}/notActive`
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP", response.data);
        await dispatch("fetchTheoryPracticeWorkshopWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP", null);
        console.log(err);
      }
    },

    async fetchWorkshopsItemsByWorkshopId({ commit }, id) {
      try {
        const response = await axios.get(`theoryPracticeWorkshops/${id}/items`);
        commit("SET_THEORY_PRACTICE_WORKSHOPS_ITEMS", response.data);
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOPS_ITEMS", null);
        console.log(err);
      }
    },

    async fetchAllWorkshopsItems({ commit }, payload) {
      try {
        let params = {};
        if (payload) {
          const { type } = payload;
          if (type) {
            params.type = type;
          }
        }
        const response = await axios.get(`theoryPracticeWorkshops/items`, {
          params,
        });
        commit("SET_THEORY_PRACTICE_WORKSHOPS_ITEMS", response.data);
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOPS_ITEMS", null);
        console.log(err);
      }
    },

    async addTheoryPracticeWorkshopItem({ commit, dispatch }, object) {
      const { data, type } = object;
      const theoryPracticeWorkshopId = data.theoryPracticeWorkshopId;
      delete data.theoryPracticeWorkshopId;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.post(
          `theoryPracticeWorkshops/${theoryPracticeWorkshopId}/items`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_ITEM", response.data);
        await dispatch("fetchAllWorkshopsItems", { type });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_ITEM", null);
        console.log(err);
      }
    },

    async updateTheoryPracticeWorkshopItem({ commit, dispatch }, object) {
      const { data, id, type } = object;
      const theoryPracticeWorkshopId = data.theoryPracticeWorkshopId;
      //delete data.theoryPracticeWorkshopId
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      try {
        const response = await axios.put(
          `theoryPracticeWorkshops/${theoryPracticeWorkshopId}/items/${id}`,
          formData
        );
        commit("SET_THEORY_PRACTICE_WORKSHOP_ITEM", response.data);
        await dispatch("fetchAllWorkshopsItems", { type });
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_ITEM", null);
        console.log(err);
      }
    },

    async fetchTheoryPracticeWorkshopItem({ commit }, id) {
      try {
        const response = await axios.get(`theoryPracticeWorkshops/items/${id}`);
        commit("SET_THEORY_PRACTICE_WORKSHOP_ITEM", response.data);
        return response.data;
      } catch (err) {
        commit("SET_THEORY_PRACTICE_WORKSHOP_ITEM", null);
        console.log(err);
      }
    },

    async adminAddUserToTheoryPracticeWorkshop({ commit, dispatch }, data) {
      const { id, email, type } = data;

      try {
        await axios.post(`theoryPracticeWorkshops/${id}/members/add`, {
          email,
        });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Użytkownik został dodany do warsztatów!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        commit("SET_NEW_MEMBER_ADDED", true);
        await dispatch("fetchTheoryPracticeWorkshopWithNotActive", { type });
        return true;
      } catch (error) {
        let errorMessage = "Spróbuj ponownie";
        if (
          error.response.data &&
          error.response.data.message &&
          error.response.data.message === "User must be registered"
        ) {
          errorMessage =
            "Użytkownik musi się najpierw zarejestrować, aby można było dodać go do warsztatów";
        }
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorMessage,
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        console.log(error);
        commit("SET_NEW_MEMBER_ADDED", false);
        return false;
      }
    },

    async getOwnTheoryPracticeWorkshops({ commit }) {
      try {
        const url = `theoryPracticeWorkshops/my`;
        const response = await axios.get(url);
        commit("SET_THEORY_PRACTICE_WORKSHOPS", response.data);
      } catch (error) {
        commit("SET_THEORY_PRACTICE_WORKSHOPS", []);
        console.log(error);
      }
    },
  },

  getters: {
    getTheoryPracticeWorkshops: (state) => state.theoryPracticeWorkshops,
    getTheoryPracticeWorkshop: (state) => state.theoryPracticeWorkshop,
    getTheoryPracticeWorkshopsItems: (state) =>
      state.theoryPracticeWorkshopsItems,
    getTheoryPracticeWorkshopItem: (state) => state.theoryPracticeWorkshopItem,
    getUserTariffWorkshops: (state) => state.userTariffWorkshops,
    getTheoryPracticeWorkshopMembers: (state) => state.members,
  },
};
export default theoryPracticeWorkshops;
