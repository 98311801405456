<template>
  <div class="product">
    <div class="product-image-container" @click="moveToProductCart">
      <img
        :id="`${product.id}_image`"
        src="../../assets/product-no-photo.jpeg"
        alt=""
        @error="setDefaultPhoto"
      />
      <!-- <font-awesome-icon :icon="'heart'" class="heart-icon" /> -->
    </div>

    <div class="description">
      <div>
        <h3 class="product-name" @click="moveToProductCart">
          {{ product.name }}
        </h3>
        <p class="product-subtitle">{{ product.subtitle }}</p>
        <product-price :price="product.price" />
      </div>
      <div v-if="product.stockAmount > 0">
        <rounded-color-button
          :width="'150px'"
          :label="'Dodaj do koszyka'"
          @clicked="addProductToBucket(product)"
        />
      </div>
      <div v-else class="no-product-info">
        <p>Produkt chwilowo</p>
        <p>niedostępny</p>
      </div>
    </div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../axios";
import RoundedColorButton from "../buttons/RoundedColorButton.vue";
import ProductPrice from "./ProductPrice.vue";
export default {
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "ShopProduct",
  data: () => ({}),
  components: { ProductPrice, RoundedColorButton },
  props: {
    product: Object,
  },

  methods: {
    addProductToBucket(product) {
      this.$store.dispatch("shopProducts/addProductToBucket", {
        product,
        amount: 1,
      });
    },

    getImage() {
      if (this.product) {
        const element = document.getElementById(`${this.product.id}_image`);
        if (element) {
          if (
            this.product &&
            this.product.shop_products_images &&
            Array.isArray(this.product.shop_products_images)
          ) {
            const mainImage = this.product.shop_products_images.find(
              (image) => image.isMain
            );
            const firstImage = this.product.shop_products_images[0];

            if ((element && mainImage) || firstImage) {
              const splittedPath = mainImage
                ? mainImage.path.split("/")
                : firstImage.path.split("/");
              const fileName = splittedPath[splittedPath.length - 1];
              element.src = `${SERVER_URL}shopProducts/${fileName}`;
            }
          }
        }
      }
    },

    setDefaultPhoto() {
      const element = document.getElementById(`mainImage_${this.product.id}`);
      if (element) {
        element.src = `../../assets/product-no-photo.jpeg`;
      }
    },

    async moveToProductCart() {
      await this.$store.dispatch("shopProducts/fetchProductDetails", {
        id: this.product.id,
      });
      this.$router.push({
        name: "ShopProductCart",
        params: { id: this.product.id },
      });
    },
  },

  async mounted() {
    this.getImage();
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.no-product-info {
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
}
.product {
  width: calc(33.33% - 30px);
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 10px 0;
  min-width: 320px;
}
.product-image-container {
  position: relative;
  height: 60%;
  height: 240px;
}
.product-image-container img {
  position: relative;
  height: 100%;
  max-height: 240px;
  object-fit: cover;
}
.product-image-container img {
  width: 100%;
  cursor: pointer;
}

.product-name {
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.product p {
  margin: 0;
}

.product .price {
  font-weight: bold;
  margin-top: 5px;
}
.heart-icon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.product-subtitle {
  font-size: 14px;
  opacity: 0.5;
}
.description {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .product {
  }
}
</style>
