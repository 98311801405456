import axios from "../axios";

const boughtProducts= {
  namespaced: true,

  state: () => ({
    boughtProducts:[],
    }),
    mutations: {
        SET_BOUGHT_PRODUCTS(state, boughtProducts){
        state.boughtProducts=boughtProducts;
      }
    },
    actions: {
        fetchAll: async({ commit }) => {
          try { 
            const response = await axios.get(`management/bought-products`) 
            commit("SET_BOUGHT_PRODUCTS", response.data); 
          } catch (error) {
            console.log(error);
          } 
        },
        
    },
    getters: {
        getBoughtProducts: (state) => state.boughtProducts
    },
}
export default boughtProducts;