import axios from "../axios";

const userSubscriptions= {
  namespaced: true,

  state: () => ({
    userSubscriptions:[],
    }),
    mutations: {
      SET_USER_SUBSCRIPTIONS(state, userSubscriptions){
        state.userSubscriptions=userSubscriptions;
      }
    },
    actions: {
        fetchAllByUserId: async({ commit }, id) => {
          try { 
            const response = await axios.get(`management/user/${id}/products`) 
            commit("SET_USER_SUBSCRIPTIONS", response.data); 
          } catch (error) {
            console.log(error);
          } 
        },
        
        fetchAllForUser: async({ commit }) => {
          try { 
            const response = await axios.get(`products`) 
            commit("SET_USER_SUBSCRIPTIONS", response.data); 
          } catch (error) {
            console.log(error);
          } 
        }
    },
    getters: {
      getUserSubscriptions: (state) => state.userSubscriptions
    },
}
export default userSubscriptions;