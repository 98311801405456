import axios from "../axios";

const sequencingWorkshops = {
  namespaced: true,

  state: () => ({
    sequencingWorkshops: [],
    sequencingWorkshop: null,
    sequencingWorkshopDeleted: null,
    sequencingWorkshopsItems: [],
    sequencingWorkshopsItem: null,
    sequencingWorkshopItemDeleted: null,
    sequencingWorkshopIsPaid: false,
    newMemberAdded: false,
    userTariffWorkshops: [],
    sequencingWorkshopIsNotExpired: false,
  }),

  mutations: {
    SET_SEQUENCING_WORKSHOPS(state, sequencingWorkshops) {
      state.sequencingWorkshops = sequencingWorkshops;
    },
    SET_USER_TARIFF_WORKSHOPS(state, workshops) {
      state.userTariffWorkshops = workshops;
    },
    SET_SEQUENCING_WORKSHOP(state, sequencingWorkshop) {
      state.sequencingWorkshop = sequencingWorkshop;
    },
    SET_SEQUENCING_WORKSHOP_DELETED(state, status) {
      state.sequencingWorkshopDeleted = status;
    },
    SET_SEQUENCING_WORKSHOPS_ITEMS(state, sequencingWorkshopsItems) {
      state.sequencingWorkshopsItems = sequencingWorkshopsItems;
    },
    SET_SEQUENCING_WORKSHOP_ITEM(state, sequencingWorkshopItem) {
      state.sequencingWorkshopItem = sequencingWorkshopItem;
    },
    SET_SEQUENCING_WORKSHOP_ITEM_DELETED(state, status) {
      state.sequencingWorkshopItemDeleted = status;
    },
    SET_SEQUENCING_WORKSHOP_IS_PAID(state, status) {
      state.sequencingWorkshopIsPaid = status;
    },
    SET_SEQUENCING_WORKSHOP_IS_NOT_EXPIRED(state, status) {
      state.sequencingWorkshopIsNotExpired = status;
    },
    SET_NEW_MEMBER_ADDED(state, status) {
      state.newMemberAdded = status;
    },
  },

  actions: {
    async fetchSequencingWorkshop({ commit }, id) {
      try {
        const response = await axios.get(`sequencingWorkshops/${id}`);
        commit("SET_SEQUENCING_WORKSHOP", response.data);
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP", null);
        console.log(err);
      }
    },
    async fetchSequencingWorkshopWithNotActive({ commit }, payload) {
      try {
        let params = {};
        if (payload) {
          const { type } = payload;
          if (type) {
            params.type = type;
          }
        }

        const response = await axios.get(`sequencingWorkshops/withNotActive`, {
          params,
        });
        commit("SET_SEQUENCING_WORKSHOPS", response.data);
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOPS", null);
        console.log(err);
      }
    },

    async createSequencingWorkshop({ commit }, object) {
      const { data } = object;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.post(`sequencingWorkshops`, formData);
        commit("SET_SEQUENCING_WORKSHOP", response.data);
        return response.data;
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP", null);
        console.log(err);
      }
    },

    async editSequencingWorkshop({ commit }, object) {
      const { data, id } = object;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.put(`sequencingWorkshops/${id}`, formData);
        commit("SET_SEQUENCING_WORKSHOP", response.data);
        return response.data;
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP", null);
        console.log(err);
      }
    },

    async editSequencingWorkshopEndDate({ commit }, object) {
      const { endDate, id } = object;

      try {
        const response = await axios.put(`sequencingWorkshops/${id}/endDate`, {
          endDate,
        });
        commit("SET_SEQUENCING_WORKSHOP", response.data);
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP", null);
        console.log(err);
      }
    },

    async deleteSequencingWorkshop({ commit, dispatch }, data) {
      try {
        await axios.delete(`sequencingWorkshops/${data.id}`);
        commit("SET_SEQUENCING_WORKSHOP_DELETED", true);
        await dispatch("fetchSequencingWorkshopWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP_DELETED", false);
        console.log(err);
      }
    },

    async deleteSequencingWorkshopItem({ commit, dispatch }, data) {
      try {
        await axios.delete(
          `sequencingWorkshops/${data.sequencingWorkshopId}/items/${data.id}`
        );
        commit("SET_SEQUENCING_WORKSHOP_ITEM_DELETED", true);
        await dispatch("fetchAllWorkshopsItems", { type: data.type });
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP_ITEM_DELETED", false);
        console.log(err);
      }
    },

    async markSequencingWorkshopAsActive({ commit, dispatch }, data) {
      try {
        const response = await axios.put(
          `sequencingWorkshops/${data.id}/active`
        );
        commit("SET_SEQUENCING_WORKSHOP", response.data);
        await dispatch("fetchSequencingWorkshopWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP", null);
        console.log(err);
      }
    },

    async markSequencingWorkshopAsNotActive({ commit, dispatch }, data) {
      try {
        const response = await axios.put(
          `sequencingWorkshops/${data.id}/notActive`
        );
        commit("SET_SEQUENCING_WORKSHOP", response.data);
        await dispatch("fetchSequencingWorkshopWithNotActive", {
          type: data.type,
        });
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP", null);
        console.log(err);
      }
    },

    async fetchWorkshopsItemsByWorkshopId({ commit }, id) {
      try {
        const response = await axios.get(`sequencingWorkshops/${id}/items`);
        commit("SET_SEQUENCING_WORKSHOPS_ITEMS", response.data);
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOPS_ITEMS", null);
        console.log(err);
      }
    },

    async fetchAllWorkshopsItems({ commit }, payload) {
      try {
        let params = {};
        if (payload) {
          const { type } = payload;
          if (type) {
            params.type = type;
          }
        }
        const response = await axios.get(`sequencingWorkshops/items`, {
          params,
        });
        commit("SET_SEQUENCING_WORKSHOPS_ITEMS", response.data);
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOPS_ITEMS", null);
        console.log(err);
      }
    },

    async addSequencingWorkshopItem({ commit, dispatch }, object) {
      const { data, type } = object;
      const sequencingWorkshopId = data.sequencingWorkshopId;
      delete data.sequencingWorkshopId;
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      try {
        const response = await axios.post(
          `sequencingWorkshops/${sequencingWorkshopId}/items`,
          formData
        );
        commit("SET_SEQUENCING_WORKSHOP_ITEM", response.data);
        await dispatch("fetchAllWorkshopsItems", { type });
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP_ITEM", null);
        console.log(err);
      }
    },

    async updateSequencingWorkshopItem({ commit, dispatch }, object) {
      const { data, id, type } = object;
      const sequencingWorkshopId = data.sequencingWorkshopId;
      //delete data.sequencingWorkshopId
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      try {
        const response = await axios.put(
          `sequencingWorkshops/${sequencingWorkshopId}/items/${id}`,
          formData
        );
        commit("SET_SEQUENCING_WORKSHOP_ITEM", response.data);
        await dispatch("fetchAllWorkshopsItems", { type });
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP_ITEM", null);
        console.log(err);
      }
    },

    async fetchSequencingWorkshopItem({ commit }, id) {
      try {
        const response = await axios.get(`sequencingWorkshops/items/${id}`);
        commit("SET_SEQUENCING_WORKSHOP_ITEM", response.data);
        return response.data;
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP_ITEM", null);
        console.log(err);
      }
    },

    async setSequencingWorkshopItemToNull({ commit }) {
      await commit("SET_SEQUENCING_WORKSHOP_ITEM", null);
    },

    async checkUserPaidForWorkshop({ commit, dispatch }, id) {
      try {
        const response = await axios.get(
          `sequencingWorkshops/${id}/checkUserPaidForWorkshop`
        );

        if (response && response.data) {
          commit("SET_SEQUENCING_WORKSHOP_IS_PAID", true);
          return true;
        } else {
          commit("SET_SEQUENCING_WORKSHOP_IS_PAID", false);
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message:
                "Musisz być zalogowany oraz posiadać wykupiony dostęp do warsztatów",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });

          return false;
        }
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP_IS_PAID", false);
        console.log(err);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message:
              "Musisz być zalogowany oraz posiadać wykupiony dostęp do warsztatów",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return false;
      }
    },

    async checkTariffWorkshopIsNotExpired({ commit, dispatch }, id) {
      try {
        const response = await axios.get(
          `sequencingWorkshops/${id}/checkTariffWorkshopIsNotExpired`
        );

        if (response && response.data) {
          commit("SET_SEQUENCING_WORKSHOP_IS_NOT_EXPIRED", true);
          return true;
        } else {
          commit("SET_SEQUENCING_WORKSHOP_IS_NOT_EXPIRED", false);
          dispatch(
            "snackbar/setSnackbar",
            {
              color: "red",
              icon: "exclamation-triangle",
              message: "Czas dostępu do warsztatów wygasł",
            },
            { root: true }
          );
          dispatch("snackbar/toggleSnackbar", true, { root: true });

          return false;
        }
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP_IS_NOT_EXPIRED", false);
        console.log(err);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: "Czas dostępu do warsztatów wygasł",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        return false;
      }
    },

    async adminAddUserToSequencingWorkshop({ commit, dispatch }, data) {
      const { id, email, type } = data;

      try {
        await axios.post(`sequencingWorkshops/${id}/members/add`, { email });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Użytkownik został dodany do warsztatów!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        commit("SET_NEW_MEMBER_ADDED", true);
        await dispatch("fetchSequencingWorkshopWithNotActive", { type });
        return true;
      } catch (error) {
        let errorMessage = "Spróbuj ponownie";
        if (
          error.response.data &&
          error.response.data.message &&
          error.response.data.message === "User must be registered"
        ) {
          errorMessage =
            "Użytkownik musi się najpierw zarejestrować, aby można było dodać go do warsztatów";
        }
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorMessage,
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        console.log(error);
        commit("SET_NEW_MEMBER_ADDED", false);
        return false;
      }
    },

    async getOwnSequencingWorkshops({ commit }) {
      try {
        const url = `sequencingWorkshops/my?type=STANDARD`;
        const response = await axios.get(url);
        commit("SET_SEQUENCING_WORKSHOPS", response.data);
      } catch (error) {
        commit("SET_SEQUENCING_WORKSHOPS", []);
        console.log(error);
      }
    },

    async getOwnTariffWorkshops({ commit }) {
      try {
        const url = `sequencingWorkshops/my?type=PRICE_TARIFF`;
        const response = await axios.get(url);
        commit("SET_USER_TARIFF_WORKSHOPS", response.data);
      } catch (error) {
        commit("SET_USER_TARIFF_WORKSHOPS", []);
        console.log(error);
      }
    },
    async editSequencingWorkshopMemberEndDate({ commit }, data) {
      try {
        const response = await axios.put(
          `sequencingWorkshops/members/${data.memberId}/endDate`,
          {
            endDate: data.endDate,
          }
        );
        commit("SET_SEQUENCING_WORKSHOP", response.data);
      } catch (err) {
        commit("SET_SEQUENCING_WORKSHOP", null);
        console.log(err);
      }
    },
  },

  getters: {
    getSequencingWorkshops: (state) => state.sequencingWorkshops,
    getSequencingWorkshop: (state) => state.sequencingWorkshop,
    getSequencingWorkshopsItems: (state) => state.sequencingWorkshopsItems,
    getSequencingWorkshopItem: (state) => state.sequencingWorkshopItem,
    getUserTariffWorkshops: (state) => state.userTariffWorkshops,
  },
};
export default sequencingWorkshops;
