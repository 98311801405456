<template>
  <div class="mb-4" v-if="token">
    <p
      align="right"
      class="add-button"
      :style="withoutMargin ? 'margin: 0' : ''"
      @click="handleClick"
    >
      <font-awesome-icon icon="plus" class="add-icon" />
      {{ "Dodaj do mojego tygodnia" }}
    </p>

    <add-video-to-schedule-modal
      :isVisible="isDayModalVisible"
      @closeModal="isDayModalVisible = false"
      @confirm="handleAddVideoToSchedule"
    />
  </div>
</template>

<script>
import AddVideoToScheduleModal from "./modals/AddVideoToScheduleModal.vue";
export default {
  props: ["selectedVideo", "withoutMargin"],
  components: { AddVideoToScheduleModal },
  emits: ["click"],
  name: "AddVideoToSchedule",
  data() {
    return {
      isDayModalVisible: false,
    };
  },
  methods: {
    handleClick() {
      this.isDayModalVisible = true;
    },
    async handleAddVideoToSchedule(selectedDay) {
      selectedDay = selectedDay.toUpperCase();
      await this.$store.dispatch("users/addVideoToUserSchedule", {
        timePackageItemId: this.selectedVideo.id,
        type: this.selectedVideo.forBeginners ? "FOR-BEGINNERS" : "STANDARD",
        day: selectedDay,
      });
      this.$store.dispatch("snackbar/showSnackbar", {
        color: "success",
        message: "Zaktualizowano kurs w harmonogramie",
      });
    },
  },
  computed: {
    token() {
      return window.localStorage.getItem("userToken");
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button {
  color: rgb(78, 74, 74);
  font-size: 0.9rem;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: right;
  text-decoration: underline;
  cursor: pointer;
}
.add-icon {
  margin-right: 8px;
  font-size: 0.9rem;
  cursor: pointer;
}
</style>