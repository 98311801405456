<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Zestawy
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="items.length === 0"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:[`item.packageItems`]="{ item }">
        <div v-for="link in item.packageItems" :key="link.id">
          {{ link.link }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editPackage(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              v-on:click="(isDeleteModalVisible = true), (currentItem = item)"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.recordingField`]="{ item }">
        <v-btn
          color="orange"
          @click="showRecordingModal(item)"
          v-on:click="isRecordingModalVisible = true"
        >
          <font-awesome-icon icon="edit" />
        </v-btn>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <template>
          <v-checkbox v-model="item.active" @click="editPackageStatus(item)" />
        </template>
      </template>
    </v-data-table>
    <AddPackageModal
      :isVisible="isModalVisible"
      :packageData="packageData"
      @closeModal="closeModal()"
    />

    <RecordingModal
      :isVisible="isRecordingModalVisible"
      :packageData="packageData"
      @closeModal="closeRecordingModal()"
    />

    <BeforeDeleteModal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirm(currentItem.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddPackageModal from "../components/modals/AddPackageModal";
import ManagementNav from "../components/ManagementNav.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import RecordingModal from "../components/modals/RecordingModal.vue";
export default {
  components: {
    AddPackageModal,
    ManagementNav,
    BeforeDeleteModal,
    RecordingModal,
  },
  created() {
    this.$store.dispatch("packages/fetchPackages");
  },
  data: () => ({
    headers: [
      { text: "Zajęcia aktywne", value: "active", width: "50px" },
      { text: "Cena", value: "price" },
      { text: "Link do płatności", value: "link" },
      { text: "Zniżka", value: "discount" },
      { text: "Kod zniżkowy", value: "discountCode" },
      { text: "Przedmioty", value: "packageItems" },
      { text: "ZoomID", value: "zoomId" },
      { text: "Data", value: "date" },
      { text: "Nagranie", value: "recordingField" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    packageData: null,
    currentItem: null,
    isDeleteModalVisible: false,
    isRecordingModalVisible: false,
  }),
  computed: {
    items() {
      return this.$store.getters["packages/getPackages"];
    },
  },
  methods: {
    editPackageStatus(item) {
      this.$store.dispatch("packages/updatePackageStatus", {
        packageId: item.id,
        active: item.active,
      });
    },
    editPackage(data) {
      this.packageData = data;
    },
    showRecordingModal(data) {
      this.packageData = data;
    },
    deletePackage(id) {
      this.$store.dispatch("packages/deletePackage", id);
    },

    confirm(id) {
      this.deletePackage(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.packageData = null;
      this.currentItem = null;
    },

    closeModal() {
      this.isModalVisible = false;
      this.packageData = null;
      this.currentItem = null;
    },
    closeRecordingModal() {
      this.isRecordingModalVisible = false;
      this.packageData = null;
    },
  },
};
</script>

<style></style>
