<template>
  <div class="path pb-9 pt-1">
    <router-link to="/" class="mr-1">Home</router-link>
    <span v-for="path in paths" :key="path.name" class="ml-2">
      <img
        src="https://i.imgur.com/gFAhAY6.png"
        alt=""
        loading="lazy"
        class="mr-2"
      />
      <router-link :to="path.to" class="">{{ path.name }}</router-link>
    </span>
  </div>
</template>

<script>
export default {
  name: "PathNavigation",
  props: {
    paths: Array,
  },

  data() {
    return {};
  },

  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.path {
  a {
    color: #000;
    text-decoration: none;
    font-weight: 400 !important;
  }
  @media (max-width: 425px) {
    margin-top: -2rem;
    padding-bottom: 20px !important;
  }
}
</style>
