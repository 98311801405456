<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Kursy do warsztatów z sekwencjonowania
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon
          icon="search"
          slot="prepend-inner"
          class="mt-1 mr-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak kursów w warsztatach z sekwencjonowania
        </v-alert>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editWorkshop(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              @click="(isDeleteModalVisible = true), (workshopItemData = item)"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="orange"
              @click="copyWorkshop(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="copy" />
            </v-btn>
          </template>
          <span>Kopiuj</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.photo`]="{ item }">
        <template>
          <img
            class="item-image"
            :id="item.imagePath"
            @click="editWorkshop(item)"
            v-on:click="isModalVisible = true"
            alt="
              joga zdrowy,
              filmy jogi,
              techniki oddechowe,
              praktyka wzmacniająca,
              joga online,
              praktyka dla początkujących,
              szkoła online,
              prana vyavama,
              joga wzmacniająca,
              joga video,
              ćwiczenia online,
              uważna praktyka,
              anatomia jogi
              "
            loading="lazy"
          />
        </template>
      </template>
      <template v-slot:[`item.sequencingWorkshop`]="{ item }">
        <template>
          <p :id="item.sequencingWorkshop.id">
            {{ item.sequencingWorkshop.name }}
          </p>
        </template>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <template>
          <p :id="item.id" v-html="htmlToFormattedText(item.name)" />
        </template>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <template>
          <p :id="item.id" v-html="htmlToFormattedText(item.description)" />
        </template>
      </template>
    </v-data-table>
    <AddSequencingWorkshopsItemModal
      :isVisible="isModalVisible"
      :workshopItemData="workshopItemData"
      @closeModal="closeModal()"
      @updated="workshopItemUpdated()"
    />
    <before-delete-modal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirm(workshopItemData)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      @click="addWorkshopsItem()"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddSequencingWorkshopsItemModal from "../components/modals/AddSequencingWorkshopsItemModal";
import ManagementNav from "../components/ManagementNav.vue";
import { SERVER_URL } from "../axios";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";

export default {
  name: "WorkshopsItems",
  components: {
    AddSequencingWorkshopsItemModal,
    ManagementNav,
    BeforeDeleteModal,
  },

  async mounted() {
    await this.$store.dispatch("sequencingWorkshops/fetchAllWorkshopsItems");
    this.loadImages();
  },
  data: () => ({
    headers: [
      { text: "Nazwa kursu", value: "name" },
      { text: "Nazwa warsztatu", value: "sequencingWorkshop" },
      { text: "Opis", value: "description" },
      { text: "Link YouTube", value: "videoLink" },
      { text: "Zdjęcie", value: "photo" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    workshopItemData: null,
    loadingImages: false,
    isDeleteModalVisible: false,
  }),
  computed: {
    items() {
      return this.$store.getters[
        "sequencingWorkshops/getSequencingWorkshopsItems"
      ];
    },
  },
  methods: {
    confirm(item) {
      this.deleteWorkshop(item);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.workshopItemData = null;
    },
    addWorkshopsItem() {
      this.workshopItemData = null;
      this.isModalVisible = true;
    },
    copyWorkshop(data) {
      this.workshopItemData = data;
      this.workshopItemData.copy = true;
      this.workshopItemData.copiedTimeWorkshopItemId = data.id;
    },
    htmlToFormattedText(text) {
      const regex =
        /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    workshopItemUpdated() {
      setTimeout(() => {
        this.loadImages();
      }, 200);
    },
    async loadImages() {
      this.items.forEach(async (item) => {
        this.loadImage(item);
      });
    },

    async loadImage(timeWorkshopItem) {
      const element = document.getElementById(timeWorkshopItem.imagePath);
      if (element) {
        if (timeWorkshopItem.imagePath) {
          const extension = timeWorkshopItem.imagePath?.split(".")[1];
          element.src = `${SERVER_URL}sequencingWorkshopItem_${
            timeWorkshopItem.id
          }.${extension}?d=${new Date().getTime()}`;
        }
      }
    },

    editWorkshop(data) {
      this.workshopItemData = data;
    },
    deleteWorkshop(item) {
      this.$store.dispatch("sequencingWorkshops/deleteSequencingWorkshopItem", {
        id: item.id,
        sequencingWorkshopId: item.sequencingWorkshop.id,
      });
    },
    closeModal() {
      this.isModalVisible = false;
      this.workshopItemData = null;
    },
  },
};
</script>

<style scoped>
.item-image {
  width: 130px;
  height: 130px;
  border-radius: 1rem;
  margin: 1rem;
}
</style>
