<template>
  <div>
    <div
      class="bucket-container"
      v-if="bucket && bucket.products && bucket.products.length > 0"
    >
      <!-- PODSUMOWANIE PRODUKTÓW -->
      <div class="products-summary">
        <h3 class="mb-8 mt-8">
          Twój koszyk
        </h3>

        <div v-for="product in bucket.products" :key="product.id">
          <div class="product">
            <div class="image-container">
              <img
                v-if="
                  product.shop_products_images &&
                    product.shop_products_images.find((image) => image.isMain)
                "
                :id="`${product.id}_image`"
                :src="
                  getImageSrc(
                    product.shop_products_images.find((image) => image.isMain)
                  )
                "
                alt="Product image"
                class="image-preview"
              />
              <img
                v-else
                :id="`${product.id}_image`"
                src="../../assets/product-no-photo.jpeg"
                alt="Product image"
                class="image-preview"
              />
            </div>
            <div class="product-headers">
              <div>
                <h4 @click="moveToProductCart(product)">{{ product.name }}</h4>
                <p class="product-subtitle">{{ product.subtitle }}</p>
              </div>

              <product-price :price="product.price" :margin="'0'" />
              <span style="margin-left: -1rem"></span>
            </div>

            <div class="quantity-container">
              <div class="quantity-selector">
                <div
                  class="quantity-selector-minus"
                  @click="minusQuantity(product)"
                >
                  <span class="prevent-select">-</span>
                </div>
                <p class="quantity-selector-value prevent-select">
                  {{ product.amount }}
                </p>
                <div
                  class="quantity-selector-minus"
                  @click="addQuantity(product)"
                >
                  <span class="prevent-select">+</span>
                </div>
              </div>
              <p
                class="mt-4 product-delete-label"
                style="width: fit-content;"
                @click="deleteProductFromBucket(product)"
              >
                Usuń
              </p>
            </div>

            <div>
              <product-price
                :price="`${(product.amount * product.price).toFixed(2)}`"
              />
            </div>

            <!-- <p style="margin-bottom: 0;">
          <b>{{ product.name }}</b>
          {{ product.subtitle ? `(${product.subtitle})` : "" }}
        </p>
        <div
          style="margin-bottom: 0.25rem; margin-top: 0.25rem; display: flex;"
        >
          <b class="mr-4">Ilość</b>
          <div class="quantity-selector">
            <div
              class="quantity-selector-minus"
              @click="minusQuantity(product)"
            >
              <span class="prevent-select">-</span>
            </div>
            <p class="quantity-selector-value prevent-select">
              {{ product.amount }}
            </p>
            <div class="quantity-selector-minus" @click="addQuantity(product)">
              <span class="prevent-select">+</span>
            </div>
          </div>
        </div>
        <p>
          <b>Cena:</b> {{ `${(product.amount * product.price).toFixed(2)}` }} zł
        </p>
        <v-btn
          icon
          color="red"
          @click="deleteProductFromBucket(item)"
          class="delete-product-icon"
        >
          <font-awesome-icon icon="times" />
        </v-btn> -->
          </div>
          <break-line />
        </div>
      </div>

      <!-- DOSTAWA -->
      <!-- <div class="delivery">
      <h1>Sposób dostawy</h1>
      <h4 class="mb-8 mt-8">
        Wybierz sposób dostawy
      </h4>

      <div
        class="furgonetka-service"
        v-for="furgonetkaService in furgonetkaServices"
        :key="`${furgonetkaService.id}`"
      >
        <input
          type="radio"
          :value="furgonetkaService.id"
          v-model="selectedFurgonetkaServiceId"
          :id="furgonetkaService.id"
        />
        <label :for="`${furgonetkaService.id}`">
          {{ furgonetkaService.name }}
        </label>
      </div>
    </div> -->

      <!-- PODSUMOWANIE I PŁATNOŚĆ -->
      <div class="payment">
        <div class="payment-sum">
          <h3 class="mt-8">Podsumowanie</h3>
          <break-line />
          <div class="payment-cost">
            <span><b>Łącznie do zapłaty</b></span>
            <product-price :price="sumPrice" margin="0" />
          </div>
          <break-line />
        </div>
        <div class="action-buttons">
          <rounded-color-button
            style="margin: 0 auto; margin-bottom: 1rem; margin-top: 1rem"
            @clicked="startPaymentForOrder"
            :label="'Do kasy'"
          />

          <rounded-color-button
            style="margin: 0 auto;"
            @clicked="moveToProductsList"
            :label="'Kontynuuj zakupy'"
            :withoutBackground="true"
          />
        </div>
      </div>
    </div>
    <div v-else class="empty-bucket-container">
      <h3 class="mb-8 mt-8">
        Twój koszyk jest pusty
      </h3>
      <p>
        Przejdź do listy produktów i wybierz produkty, które chcesz kupić
      </p>
      <rounded-color-button
        style="margin: 0 auto; margin-top: 2rem; max-width: 320px;"
        :label="'Wybierz produkty'"
        :withoutBackground="true"
        :width="'100%'"
        @clicked="moveToProductsList"
      />
    </div>
  </div>
</template>

<script>
import { SERVER_URL } from "../../axios";
import BreakLine from "../../components/BreakLine.vue";
import RoundedColorButton from "../../components/buttons/RoundedColorButton.vue";
import ProductPrice from "../../components/shop/ProductPrice.vue";
export default {
  components: { RoundedColorButton, ProductPrice, BreakLine },
  metaInfo: {
    title: "Dagajoga subskrypcja - portal jogi online",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Dagajoga to szkoła internetowa o wszechstronnym systemie budowania praktyki własnej. Praktyka jogi połączona z nowoczesnymi metodami ćwiczeń funkcjonalnych, ćwiczeń wzmacniających.",
      },
    ],
  },
  name: "ShopProducts",
  data: () => ({
    loading: false,
    selectedFurgonetkaService: "",
    sumPrice: 0,
    selectedFurgonetkaServiceId: "",
  }),
  props: {
    path: String,
  },

  watch: {
    selectedFurgonetkaServiceId() {
      this.selectedFurgonetkaService = this.furgonetkaServices.find(
        (service) => service.id === this.selectedFurgonetkaServiceId
      );
      this.$store.dispatch("shopProducts/setFurgonetkaServiceInBucket", {
        selectedFurgonetkaService: this.selectedFurgonetkaService,
      });
      this.calculateSumPrice();
    },
    bucket() {
      this.calculateSumPrice();
    },
  },

  async mounted() {
    this.calculateSumPrice();
    //this.getImages();
  },

  computed: {
    bucket() {
      return this.$store.getters["shopProducts/getBucket"];
    },
    furgonetkaServices() {
      return this.$store.getters["shopProducts/getFurgonetkaServices"];
    },
  },
  methods: {
    moveToProductCart(product) {
      this.$router.push({
        name: "ShopProductCart",
        params: { id: product.id },
      });
    },
    moveToProductsList() {
      this.$router.push({ name: "ShopProducts" });
    },
    getImages() {
      this.bucket.products.forEach((product) => {
        const mainImage = product.shop_products_images
          ? product.shop_products_images.find((image) => image.isMain)
          : null;
        const element = document.getElementById(`${product.id}_image`);
        if (element && mainImage) {
          const splittedPath = mainImage.path.split("/");
          const fileName = splittedPath[splittedPath.length - 1];
          element.src = `${SERVER_URL}shopProducts/${fileName}`;
        }
      });
    },

    getImageSrc(image) {
      const splittedPath = image.path.split("/");
      const fileName = splittedPath[splittedPath.length - 1];
      return `${SERVER_URL}shopProducts/${fileName}`;
    },
    async deleteProductFromBucket(product) {
      await this.$store.dispatch("shopProducts/removeProductFromBucket", {
        product,
      });
    },

    startPaymentForOrder() {
      this.$router.push({ name: "ShopProductsPayment" });
    },
    calculateSumPrice() {
      if (this.bucket && this.bucket.products) {
        let sumPrice = 0;
        const products = this.bucket.products;
        let productsPrice = 0;
        products.forEach((product) => {
          const productPrice = product.amount * product.price;
          productsPrice = productsPrice + productPrice;
        });
        sumPrice = sumPrice + productsPrice;
        this.sumPrice = sumPrice.toFixed(2);
      } else {
        return "0.00";
      }
    },
    addQuantity(product) {
      this.$store.dispatch("shopProducts/addQuantityProductInBucket", {
        product,
      });
    },
    minusQuantity(product) {
      if (product.amount > 1) {
        this.$store.dispatch("shopProducts/minusQuantityProductInBucket", {
          product,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-bucket-container {
  margin: 0 auto;
  text-align: center;
}
.products-summary {
  width: 60%;
}
.quantity-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 40%;
  align-items: center;
}
.quantity-container p:last-child {
  font-size: 12px;
  text-decoration: underline;
  font-weight: 300;
  cursor: pointer;
}
.product {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.image-container {
  max-height: 75px;
  height: 75px;
}
.image-preview {
  object-fit: cover;
  height: 75px !important;
  width: 100px;
}
.product-headers {
  margin: 0 2rem;
  width: 30%;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.product-headers h4 {
  cursor: pointer;
}
.product-subtitle {
  color: rgb(0, 0, 0);
  opacity: 0.25;
  font-size: 14px;
  margin: 0;
}
.payment {
  width: 25%;
}
.payment-sum {
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.payment-cost {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
}
.delete-product-icon {
  position: absolute;
  left: -2.5rem;
  top: 25%;
  cursor: pointer;
}
.bucket-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  gap: 2rem;
  margin: 4rem 1rem 0 1rem;
}
.furgonetka-service {
  display: flex;
  gap: 0.5rem;
}
.furgonetka-service input,
.furgonetka-service label {
  margin-bottom: 1rem;
  cursor: pointer;
}

.quantity-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(212, 209, 209);
  border-radius: 50px;
  height: 25px;
  gap: 1rem;
  width: 120px;
}
.quantity-selector-value {
  margin: 0;
  min-width: 30px;
  display: flex;
  justify-content: center;
}

.quantity-selector-plus,
.quantity-selector-minus {
  font-size: 25px;
  font-weight: lighter;
  color: #000;
  cursor: pointer;
}

.quantity-selector-plus:hover,
.quantity-selector-minus:hover {
  font-size: 25px;
  color: #b28a8a;
}

@media (max-width: 1250px) {
  .quantity-container {
    margin-top: 1rem;
    width: 100px;
  }
  .product-price {
    margin-left: 1rem;
  }
  .products-summary {
    width: 100%;
  }
  .bucket-container {
    margin-top: 0;
  }

  .payment-cost,
  .payment-cost span,
  .payment-sum,
  .payment {
    width: 100%;
    margin: 0;
  }

  .action-buttons div,
  .action-buttons button {
    width: 100%;
  }
}
</style>
