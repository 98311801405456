<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-6 class="transparent-bg">
        <h1 class="text-center">
          Dziękujemy za zakup produktów.
        </h1>
        <blockquote class="blockquote text-center">
          Jeśli płatność przebiegła pomyślnie, szczegóły dotyczące Twojego
          zamówienia znajdziesz w wiadomości wysłanej na podany przez Ciebie
          adres e-mail.
        </blockquote>
        <blockquote class="blockquote text-center">
          <a class="center">
            <div class="primary-button" @click="redirect">Strona główna</div>
          </a>
        </blockquote>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: "ShopProductsPaymentSuccess",
  metaInfo: {
    title: "Dagajoga warsztaty z sekwencjonowania - portal jogi online",
  },
  mounted() {
    this.checkOrderPaymentStatus();
  },
  data() {
    return {
      inverval: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    redirect() {
      this.$router.push({ name: "Courses" });
    },

    checkOrderPaymentStatus() {
      const orderId = this.$route.query.orderId;
      if (orderId) {
        this.interval = setInterval(async () => {
          const isPaid = await this.$store.dispatch(
            "shopProducts/checkOrderIsPaid",
            {
              shopOrderId: orderId,
            }
          );
          if (isPaid) {
            clearInterval(this.interval);
            await this.$store.dispatch("shopProducts/setEmptyBucket");
          }
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.telegram {
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.transparent-bg {
  position: relative;
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}
.center {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  cursor: default;
}
a {
  text-decoration: none;
}
</style>
