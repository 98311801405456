const videos = {
    namespaced: true,
    state: () => ({
      selectedVideo: null,
    }),

    mutations: {
        SET_SELECTED_VIDEO(state, video){
            state.selectedVideo = video
        }
    },

    actions: {
        async setSelectedVideo({commit}, data){
            commit("SET_SELECTED_VIDEO", data.video)
        }
    },

    getters: {
        getSelectedVideo: state => state.selectedVideo
    }
  
}

export default videos;