<template>
  <div
    class="text-left ml-3 mb-12"
    v-if="adminSchedule && adminSchedule.length > 0"
  >
    <div>
      <p v-if="!withoutTitle" class="title">Harmonogram tygodnia Dagmary</p>
      <div id="admin-schedules">
        <schedule-item
          :scheduleDay="scheduleDay"
          :isAdminScheduleItem="true"
          v-for="scheduleDay in adminSchedule"
          :key="scheduleDay.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleItem from "./ScheduleItem.vue";
export default {
  props: ["type", "withoutTitle"],
  name: "AdminSchedule",
  components: { ScheduleItem },
  async mounted() {
    const token = window.localStorage.getItem("userToken");
    if (token) {
      await this.$store.dispatch("users/fetchAdminSchedule", {
        type: this.type || "STANDARD",
      });
    } else {
      await this.$store.dispatch("users/resetSchedules");
    }
  },
  methods: {},

  computed: {
    adminSchedule() {
      if (this.type === "FOR-BEGINNERS") {
        return this.$store.getters["users/getAdminScheduleForBeginners"];
      } else {
        return this.$store.getters["users/getAdminScheduleStandard"];
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 500;
}
#admin-schedules {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
@media (max-width: 825px) {
  #admin-schedules {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-right: 0.75rem;
  }
}
</style>