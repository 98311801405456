<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-3 class="transparent-bg">
        <h1 class="text-center">Dziękujemy za zapisanie się do newslettera</h1>
        <blockquote class="blockquote text-center">
          Od teraz możesz mieć pewność, że będziesz zawsze na bieżąco z tym, co
          się u nas dzieje.
        </blockquote>
        <blockquote class="blockquote text-center">
          <a :href="homepage">
            <button class="primary-button">Strona główna</button>
          </a>
        </blockquote>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import config from "../config";
export default {
  metaInfo: {
    title: "Dagajoga newsletter - portal jogi online",
  },
  data: function () {
    return {
      homepage: config.HOMEPAGE,
    };
  },
};
</script>

<style scoped>
.transparent-bg {
  padding: 50px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.664);
}
i {
  font-size: 120px;
}
</style>
