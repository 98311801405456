<template>
  <v-card width="100%">
    <management-nav />
    <v-card-title>
      Dostępne kategorie
      <v-spacer></v-spacer>
      <v-text-field v-model="search" label="Szukaj" single-line hide-details
        ><font-awesome-icon icon="search" slot="prepend-inner" class="mr-1 mt-1"
      /></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="!items"
      loading-text="Trwa pobieranie danych..."
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:no-data>
        <v-alert :value="items && items.length === 0">
          Brak kategorii
        </v-alert>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="green"
              @click="editMovieCategory(item)"
              v-bind="attrs"
              v-on="on"
              v-on:click="isModalVisible = true"
            >
              <font-awesome-icon icon="edit" />
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="red"
              v-bind="attrs"
              v-on="on"
              v-on:click="(isDeleteModalVisible = true), (currentItem = item)"
            >
              <font-awesome-icon icon="times" />
            </v-btn>
          </template>
          <span>Usuń</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="formatDate(item.createdAt)"
            />
          </template>
        </v-tooltip>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <p
              :id="item.id"
              v-bind="attrs"
              v-on="on"
              v-html="htmlToFormattedText(item.description)"
            />
          </template>
        </v-tooltip>
      </template>
    </v-data-table>
    <AddMovieCategoryModal
      :isVisible="isModalVisible"
      :movieCategories="movieCategories"
      @closeModal="closeModal()"
    />
    <BeforeDeleteModal
      :isVisible="isDeleteModalVisible"
      @closeModal="closeDeleteModal()"
      @confirmDeletion="confirm(currentItem.id)"
    />
    <v-btn
      fab
      dark
      large
      color="green"
      fixed
      left
      bottom
      v-on:click="isModalVisible = true"
    >
      <font-awesome-icon icon="plus" />
    </v-btn>
  </v-card>
</template>

<script>
import AddMovieCategoryModal from "../components/modals/AddMovieCategoryModal.vue";
import BeforeDeleteModal from "../components/modals/BeforeDeleteModal.vue";
import ManagementNav from "../components/ManagementNav.vue";
import moment from "moment";
export default {
  name: "MovieCategoriesManagement",
  components: {
    AddMovieCategoryModal,
    ManagementNav,
    BeforeDeleteModal,
  },
  created() {
    this.$store.dispatch("movieCategories/fetchAll");
  },
  data: () => ({
    headers: [
      { text: "Nazwa", value: "categoryName" },
      { text: "Data utworzenia", value: "createdAt" },
      { text: "Akcje", value: "actions", sortable: false },
    ],
    search: "",
    isModalVisible: false,
    giftCard: null,
    currentItem: null,
    isDeleteModalVisible: false,
  }),
  computed: {
    items() {
      return this.$store.getters["movieCategories/getMovieCategories"];
    },
  },
  methods: {
    formatDate(date) {
      if (date != null) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        return formattedDate;
      }
      return null;
    },
    htmlToFormattedText(text) {
      const regex = /((http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
      text = text.replace(regex, '<a href="$1">$1</a>');
      return text;
    },
    editGiftCard(data) {
      this.giftCard = data;
    },

    editMovieCategory(data) {
      this.movieCategories = data;
    },
    deleteMovieCategory(id) {
      this.$store.dispatch("movieCategories/deleteMovieCategory", { id });
    },

    confirm(id) {
      this.deleteMovieCategory(id);
    },
    closeDeleteModal() {
      this.isDeleteModalVisible = false;
      this.movieCategories = null;
      this.currentItem = null;
    },

    closeModal() {
      this.isModalVisible = false;
      this.movieCategories = null;
      this.currentItem = null;
    },
  },
};
</script>

<style></style>
